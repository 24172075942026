import Add from '@mui/icons-material/Add';
import Button from "@mui/material/Button";
import { useEffect, useMemo, useState } from "react";
import TagList from "features/tags/components/TagList";
import AssignTagDialog from "features/tags/components/AssignTagDialog";
import { useUpdateTasksMutation } from "features/tasks/store/task.api";

/**
 * Tags Tab
 * @param {[Tag]} tags 
 * @param {Number} taskId 
 * @param {Number} projectId 
 * @returns 
 */
const TagsTab = ({ tags, taskId, projectId }) => {

  const [selectedTags, setSelectedTags] = useState([]);
  const [openAssignTagDialog, setOpenAssignTagDialog] = useState(false);

  const [updateTasks, { isLoading: isUpdatingTask }] = useUpdateTasksMutation();

  useEffect(() => {
    tags.length ? setSelectedTags(tags) : setSelectedTags([])
  }, [tags])

  const handleOnDeleteTag = (tagId) => {
    setSelectedTags((prevTags) => {
      const remainingTags = prevTags.filter(({ id }) => id !== tagId);
      updateTasks({
        tasks: [
          {
            id: taskId,
            tags: remainingTags.map(({ id }) => id),
            project_id: projectId,
          },
        ],
      });
      return remainingTags;
    });
  };

  const handleUpdateTags = (tags) => {
    updateTasks({
      tasks: [{
        id: taskId,
        tags: tags.map((tag) => tag.id),
        project_id: projectId
      }]
    }).then(() => setOpenAssignTagDialog(false))
  }

  const filteredTags = useMemo(() => {
    return selectedTags?.filter((tag) => tag.type !== "generated") || []
  }, [selectedTags])

  return <>
    <TagList tags={selectedTags} onDelete={handleOnDeleteTag} />
    <Button size="small" startIcon={<Add />} variant="text" onClick={() => setOpenAssignTagDialog(true)}>
      Assign Tag
    </Button>
    {openAssignTagDialog &&
      <AssignTagDialog
        existingTags={filteredTags}
        handleClose={() => setOpenAssignTagDialog(false)}
        handleUpdate={handleUpdateTags}
        isUpdating={isUpdatingTask}
        open={openAssignTagDialog}
      />}
  </>

}

export default TagsTab;