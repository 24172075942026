import { TrackChanges } from '@mui/icons-material';
import { Button, List, ListItem, Paper, Stack, Typography } from '@mui/material';
import DisplayWithEditAccess from 'components/common/v3/DisplayWithEditAccess';
import { format, startOfWeek } from 'date-fns';
import { useGetWeeklyWorkPlanQuery } from 'features/targets/api/target.api';
import { PublishWeeklyWorkPlanDialog } from 'features/targets/components/PublishWeeklyWorkPlanDialog';
import { TargetDetails } from 'features/targets/components/TargetDetails';
import { useState } from 'react';

const weekStartDate = startOfWeek(new Date(), { weekStartsOn: 1 });

export const TargetsTab = ({ taskId, projectId }) => {
  const [date, setDate] = useState(weekStartDate);
  const [isPublishOpen, setIsPublishOpen] = useState(false);
  const { data: { targets = [] } = {} } = useGetWeeklyWorkPlanQuery({
    parentId: taskId,
    projectId,
    date: format(date, 'yyyy-MM-dd'),
  });

  return (
    <Stack spacing={1}>
      <Stack alignItems={'center'} direction={'row'}>
        <TrackChanges color={'action'} sx={{ p: 1.5, fontSize: '1.75rem' }} />
        <Typography sx={{ fontWeight: '600' }} variant={'h6'}>
          Targets
        </Typography>
        <DisplayWithEditAccess>
          <Button onClick={() => setIsPublishOpen(true)}>Publish</Button>
          <PublishWeeklyWorkPlanDialog
            handleClose={() => setIsPublishOpen(false)}
            open={isPublishOpen}
            projectId={projectId}
            taskId={taskId}
          />
        </DisplayWithEditAccess>
      </Stack>
      <Paper>
        <List disablePadding>
          {targets.map((target) => (
            <ListItem key={target.id}>
              <TargetDetails target={target} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Stack>
  );
};
