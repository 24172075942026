import { useEffect, useState } from 'react';
import last from 'lodash/last';

import { fetchAssetManifestEtag } from '../api/updateChecker.api';

export const UPDATE_CHECK_INTERVAL_MS = 30 * 1000;

export function useCheckForUpdates() {
  const [assetManifestEtags, setAssetManifestEtags] = useState([]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const etag = await fetchAssetManifestEtag();

      setAssetManifestEtags((etags) => {
        if (etag && etag !== last(etags)) {
          return [...etags, etag];
        }

        return etags;
      });
    }, UPDATE_CHECK_INTERVAL_MS);

    return () => clearInterval(interval);
  }, []);

  const isUpdateAvailable = assetManifestEtags.length > 1;

  return {
    isUpdateAvailable,
  };
}
