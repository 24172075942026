import { useEffect } from 'react';

export const useOnTaskClick = ({ callback, className, gantt }) => {
  useEffect(() => {
    if (!gantt?.$root) return;

    const clickHandler = (event) => {
      const target = event.target;
      const stuckPointElement = target.closest(className);

      if (stuckPointElement) {
        const taskId = stuckPointElement.dataset.id;

        if (taskId) {
          const task = gantt.getTask(taskId);
          if (task) {
            callback(task);
          }
        }
      }
    };

    gantt.$root.addEventListener('click', clickHandler);

    return () => {
      if (gantt?.$root) {
        gantt.$root.removeEventListener('click', clickHandler);
      }
    };
  }, [callback, className, gantt]);
};
