import { formatTaskToGanttTask } from '@blackhyve/utilities/gantt';
import { getChildren, hasChild } from '@blackhyve/utilities/tasks';
import { Clear, FormatIndentDecrease, FormatIndentIncrease } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Paper,
  Slide,
  Tooltip,
  Typography,
} from '@mui/material';
import TaskBulkEditColorMenu from 'components/projectOverview/gantt/components/menus/menuItems/bulkMenuItems/TaskBulkEditColorMenuItem';
import TaskBulkEditDeleteLinks from 'components/projectOverview/gantt/components/menus/menuItems/bulkMenuItems/TaskBulkEditDeleteLinks';
import TaskBulkEditResponsibleMenuItem from 'components/projectOverview/gantt/components/menus/menuItems/bulkMenuItems/TaskBulkEditResponsibleUserMenuItem';
import TaskBulkEditTradeMenuItem from 'components/projectOverview/gantt/components/menus/menuItems/bulkMenuItems/TaskBulkEditTradeMenuItem';
import { useEffect, useMemo, useState } from 'react';
import ConfirmDeleteDialog from '../../../../../common/popovers/ConfirmDeleteDialog';
import TaskBulkEditAreasMenuItem from '../../../components/menus/menuItems/bulkMenuItems/TaskBulkEditAreasMenuItem';
import TaskBulkEditCompanyMenuItem from '../../../components/menus/menuItems/bulkMenuItems/TaskBulkEditCompanyMenuItem';
import TaskBulkEditCrewMenuItem from '../../../components/menus/menuItems/bulkMenuItems/TaskBulkEditCrewMenuItem';
import TaskBulkEditDurationMenuItem from '../../../components/menus/menuItems/bulkMenuItems/TaskBulkEditDurationMenuItem';
import TaskBulkEditHoursMenuItem from '../../../components/menus/menuItems/bulkMenuItems/TaskBulkEditHoursMenuItem';
import ganttStore from '../../ganttStore';
import { parseDuration } from './durationMenu';
import { useModalProvider } from 'components/common/ModalProvider';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'features/auth';
import TaskBulkEditTagMenuItem from 'components/projectOverview/gantt/components/menus/menuItems/bulkMenuItems/TaskBulkEditTagMenuItem';
import { useUpdateTaskTagsMutation } from 'features/tasks/store/task.api';

/**
 * Bulk edit menu
 * @returns {JSX}
 */
const BulkEditMenu = ({ ganttId, projectId }) => {
  const gantt = ganttStore.getGantt(ganttId);
  const { openModal, closeModal } = useModalProvider();
  const [selected, setSelected] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const currentUser = useSelector((state) => selectCurrentUser(state));
  const [updateTaskTag] = useUpdateTaskTagsMutation();

  const handleDelete = () => {
    setConfirmDelete(false);
    openModal('loadingSpinner', { message: 'Deleting Tasks' });
    gantt.batchUpdate(() => {
      gantt.eachSelectedTask((id) => {
        if (gantt.isTaskExists(id)) {
          if (gantt?.ext?.undo) {
            gantt.ext.undo.saveState(id, 'task');
          }
          gantt.deleteTask(id);
        }
      });
    });
    closeModal();
  };

  const handleClearSelected = () => {
    gantt.batchUpdate(() => {
      selected.forEach((taskId) => {
        gantt.unselectTask(taskId);
      });

      gantt.eachTask((task) => (task.$isHighlighted = false));
      gantt.getLinks().forEach((link) => (link.$isHighlighted = false));
    });
  };

  const handleUpdateCrew = (requestParameter) => {
    gantt.batchUpdate(() => {
      selected.forEach((taskId) => {
        if (gantt?.ext?.undo) {
          gantt.ext.undo.saveState(taskId, 'task');
        }
        const task = gantt.getTask(taskId);
        task.crew_size = requestParameter.crew_size;
        gantt.updateTask(task.id);
      });
    });
  };

  const handleUpdateDuration = ({ duration: inputDurationString, durationType }) => {
    gantt.batchUpdate(() => {
      selected.forEach((taskId) => {
        if (gantt?.ext?.undo) {
          gantt.ext.undo.saveState(taskId, 'task');
        }
        const task = gantt.getTask(taskId);
        if (task.status !== 'complete' && !hasChild(taskId, gantt)) {
          const { workdays, calendarDays } = parseDuration(
            task,
            inputDurationString.toLowerCase(),
            ganttId,
            durationType === 'workdays'
          );
          task.work_days = workdays;
          task.cal_days = calendarDays;
          task.scheduled_end_date = gantt.calculateEndDate({
            start_date: task.start_date,
            duration: workdays,
            task: task,
          });
          if (
            task.autoschedule_date === 'schedule' ||
            (!hasChild(taskId, gantt) && task.status === 'todo')
          ) {
            task.end_date = task.scheduled_end_date;
          }
          task.duration = gantt.calculateDuration(task);
          gantt.updateTask(taskId);
        }
      });
      gantt.autoSchedule();
    }, false);
  };

  const handleUpdateEffortHours = (input) => {
    gantt.batchUpdate(() => {
      selected.forEach((taskId) => {
        if (gantt?.ext?.undo) {
          gantt.ext.undo.saveState(taskId, 'task');
        }
        const task = gantt.getTask(taskId);
        task.effort_hours = input.effort_hours;
        task.effort_tag = null;
        gantt.updateTask(task.id);
      });
    });
  };

  const handleUpdateLocation = (requestParameter) => {
    gantt.batchUpdate(() => {
      selected.forEach((taskId) => {
        if (gantt?.ext?.undo) {
          gantt.ext.undo.saveState(taskId, 'task');
        }
        const task = gantt.getTask(taskId);
        if (requestParameter.type == 'location') {
          task.location_id = requestParameter.location_id;
          task.zone_id = null;
          task.area_id = null;
        }
        if (requestParameter.type == 'zone') {
          task.location_id = requestParameter.location_id;
          task.zone_id = requestParameter.zone_id;
          task.area_id = null;
        }
        if (requestParameter.type == 'area') {
          task.location_id = requestParameter.location_id;
          task.zone_id = requestParameter.zone_id;
          task.area_id = requestParameter.area_id;
        }
        gantt.updateTask(task.id);
      });
    });
  };

  const handleIndentAction = (actionName) => {
    const action = getTaskActions(ganttId)[actionName];
    if (!action) return;

    gantt.batchUpdate(() => {
      // need to preserve order of items on indent/outdent,
      // remember order before changing anything:
      const indexes = {},
        siblings = {};

      gantt.eachSelectedTask((id) => {
        if (gantt?.ext?.undo) {
          gantt.ext.undo.saveState(id, 'task');
        }
        indexes[id] = gantt.getTaskIndex(id);
        siblings[id] = {
          first: null,
        };
        let currentId = id;
        while (
          gantt.isTaskExists(gantt.getPrevSibling(currentId)) &&
          gantt.isSelectedTask(gantt.getPrevSibling(currentId))
        ) {
          currentId = gantt.getPrevSibling(currentId);
        }
        siblings[id].first = currentId;
      });

      const updated = {};

      gantt.eachSelectedTask((id) => {
        if (!updated[gantt.getParent(id)]) {
          const updated_id = action(id, indexes, siblings);
          updated[updated_id] = true;
        } else {
          updated[id] = true;
        }
      });
    });
  };

  const handleSplitLocations = ({ tasks, dependencies }) => {
    gantt.batchUpdate(() => {
      const ganttTasks = tasks.map((task) => formatTaskToGanttTask(task));
      gantt.parse({ tasks: ganttTasks, links: dependencies });
      selected.forEach((taskId) => {
        gantt.getTask(taskId).$open = true;
      });
      gantt.autoSchedule();
      handleClearSelected();
    });
  };

  const handleUpdateCompanies = (companies) => {
    const tasks = selected;
    gantt.batchUpdate(() => {
      tasks?.forEach((id) => {
        if (gantt?.ext?.undo) {
          gantt.ext.undo.saveState(id, 'task');
        }
        const task = gantt.getTask(id);
        task.companies = companies;
        task.contacts = task.contacts?.length
          ? task.contacts?.filter((contact) =>
            Boolean(companies.find(({ id }) => id === contact.company_id))
          )
          : [];
        gantt.updateTask(id);
      });
    });
  };

  const handleUpdateResponsibleUser = (users) => {
    gantt.batchUpdate(() => {
      selected.forEach((taskId) => {
        if (gantt?.ext?.undo) {
          gantt.ext.undo.saveState(taskId, 'task');
        }
        const task = gantt.getTask(taskId);
        task.responsible_users = users;
        gantt.updateTask(taskId);
      });
    });
  };

  const handleUpdateTrade = (tradeIds) => {
    gantt.batchUpdate(() => {
      selected?.forEach((id) => {
        if (gantt?.ext?.undo) {
          gantt.ext.undo.saveState(id, 'task');
        }
        const task = gantt.getTask(id);
        task.trades = tradeIds;
        gantt.updateTask(id);
      });
    });
  };

  const handleUpdateColor = (color) => {
    gantt.batchUpdate(() => {
      selected?.forEach((id) => {
        if (gantt?.ext?.undo) {
          gantt.ext.undo.saveState(id, 'task');
        }
        const task = gantt.getTask(id);
        task.task_color = color;
        task.$color = color;
        gantt.updateTask(id);
      });
    });
    gantt.callEvent('onSetColorOption', ['Task']);
  };

  const handleLinkTasks = () => {
    gantt.batchUpdate(() => {
      console.log(gantt.getState());
      selected?.forEach((id, index) => {
        if (index > 0) {
          const prevTaskId = selected[index - 1];
          const task = gantt.getTask(id);
          const linkType = gantt.isChildOf(id, prevTaskId) ? 'SS' : 'FS';
          let hasLink = false;
          task.$target.forEach((linkId) => {
            const link = gantt.getLink(linkId);
            if (String(link.source) !== String(prevTaskId) || link.type !== linkType) {
              gantt.deleteLink(linkId);
            } else {
              hasLink = true;
            }
          });
          if (!hasLink) {
            gantt.addLink({ type: linkType, source: prevTaskId, target: id });
          }
        }
      });
    });
  };

  const handleDeleteLinks = () => {
    gantt.batchUpdate(() => {
      selected?.forEach((id) => {
        const task = gantt.getTask(id);
        if (task.$source.length > 0) {
          task.$source.forEach((source) => {
            if (gantt?.ext?.undo) {
              gantt.ext.undo.saveState(source, 'link');
            }
            gantt.deleteLink(source);
          });
        }
        if (task.$target.length > 0) {
          task.$target.forEach((target) => {
            if (gantt?.ext?.undo) {
              gantt.ext.undo.saveState(target, 'link');
            }
            gantt.deleteLink(target);
          });
        }
      });
    });
  };

  const handleUpdateTaskLock = () => {
    gantt.batchUpdate(() => {
      selected?.forEach((id) => {
        const task = gantt.getTask(id);
        if (
          task.status === 'complete' ||
          task?.id === -1 ||
          (task.dates_locked_by &&
            currentUser.role !== 'super_admin' &&
            currentUser.role !== 'admin' &&
            currentUser.id !== task.dates_locked_by.id)
        ) {
          return;
        }

        if (gantt?.ext?.undo) {
          gantt.ext.undo.saveState(id, 'task');
        }
        task.dates_locked_by = currentUser;
        gantt.updateTask(id);
      });
    });
  };

  const handleUpdateTaskUnlock = () => {
    gantt.batchUpdate(() => {
      selected?.forEach((id) => {
        const task = gantt.getTask(id);
        if (
          task.status === 'complete' ||
          task?.id === -1 ||
          (task.dates_locked_by &&
            currentUser.role !== 'super_admin' &&
            currentUser.role !== 'admin' &&
            currentUser.id !== task.dates_locked_by.id)
        ) {
          return;
        }
        if (gantt?.ext?.undo) {
          gantt.ext.undo.saveState(id, 'task');
        }
        task.dates_locked_by = null;
        gantt.updateTask(id);
      });
    });
  };

  const handleUpdateTags = (selectedTags) => {
    updateTaskTag({
      tasks: selected,
      tags: selectedTags?.map((tag) => tag.id) || []
    })
      .unwrap()
      .then((data) => {
        gantt.batchUpdate(() => {
          data?.forEach(({ id, tags }) => {
            if (gantt.isTaskExists(id)) {
              if (gantt?.ext?.undo) {
                gantt.ext.undo.saveState(id, 'task');
              }
              const task = gantt.getTask(id);
              task.tags = tags;
              gantt.refreshTask(id);
            }
          });
        });
      });
  }

  const taskNames = useMemo(
    () =>
      selected
        .filter((taskId) => gantt.isTaskExists(taskId))
        .map((taskId) => gantt.getTask(taskId).text),
    [gantt, selected]
  );

  useEffect(() => {
    if (gantt) {
      gantt.attachEvent(
        'onTaskMultiSelect',
        function (id, state, e) {
          setSelected(
            gantt.getSelectedTasks().filter((taskId) => !gantt.getTask(taskId)?.readonly)
          );
        },
        { id: 'gantt-bulk-edit' }
      );
    }
    return () => {
      gantt.detachEvent('gantt-bulk-edit');
    };
  }, [gantt]);

  return (
    <Slide mountOnEnter unmountOnExit direction="up" in={selected?.length > 0}>
      <Box
        bottom={'25px'}
        display={'flex'}
        left={'50%'}
        position={'absolute'}
        sx={{ translate: '-50%' }}
        zIndex={13}
      >
        <Paper
          elevation={3}
          sx={{
            color: 'white',
            bgcolor: 'secondary.main',
            borderRadius: 5,
          }}
        >
          <Grid container alignItems={'center'} flexWrap={'nowrap'}>
            <Grid
              item
              alignItems={'center'}
              borderRight={'1px solid rgba(0,0,0,.25)'}
              display={'flex'}
            >
              <Tooltip arrow title="Clear Selection">
                <IconButton size={'small'} onClick={handleClearSelected}>
                  <Clear htmlColor="#fff" />
                </IconButton>
              </Tooltip>
              <Typography sx={{ mr: 1, textWrap: 'nowrap' }}>
                {selected.length} {selected.length > 1 ? 'Tasks' : 'Task'} Selected
              </Typography>
            </Grid>
            <Grid item>
              <ButtonGroup disableElevation color={'inherit'} variant={'text'}>
                {gantt?.config?.order_branch ? (
                  <>
                    <Tooltip arrow title="Outdent">
                      <Button onClick={() => handleIndentAction('outdent')}>
                        <FormatIndentDecrease />
                      </Button>
                    </Tooltip>
                    <Tooltip arrow title="Indent">
                      <Button onClick={() => handleIndentAction('indent')}>
                        <FormatIndentIncrease />
                      </Button>
                    </Tooltip>
                  </>
                ) : null}
                {projectId && <TaskBulkEditTagMenuItem handleUpdate={handleUpdateTags} />}
                {projectId && (
                  <Tooltip arrow title={'Link selected tasks finish to start'}>
                    <Button onClick={handleLinkTasks}>Link</Button>
                  </Tooltip>
                )}
                {projectId && (
                  <TaskBulkEditDeleteLinks
                    handleDelete={handleDeleteLinks}
                    projectId={projectId}
                    type={'link'}
                  />
                )}
                <TaskBulkEditHoursMenuItem handleUpdate={handleUpdateEffortHours} />
                <TaskBulkEditDurationMenuItem handleUpdate={handleUpdateDuration} />
                <TaskBulkEditCrewMenuItem handleUpdate={handleUpdateCrew} />
                <TaskBulkEditColorMenu handleUpdate={handleUpdateColor} />
                {projectId && (
                  <TaskBulkEditAreasMenuItem
                    handleMultiUpdate={handleSplitLocations}
                    handleUpdate={handleUpdateLocation}
                    projectId={projectId}
                    taskIds={selected}
                  />
                )}
                {projectId && (
                  <TaskBulkEditCompanyMenuItem
                    handleUpdate={handleUpdateCompanies}
                    projectId={projectId}
                  />
                )}
                {projectId && (
                  <TaskBulkEditResponsibleMenuItem
                    handleUpdate={handleUpdateResponsibleUser}
                    projectId={projectId}
                  />
                )}
                <Button onClick={handleUpdateTaskLock}>Lock</Button>
                <Button onClick={handleUpdateTaskUnlock}>Unlock</Button>
                <TaskBulkEditTradeMenuItem handleUpdate={handleUpdateTrade} projectId={projectId} />
                <Button onClick={() => setConfirmDelete(true)}>Delete</Button>
              </ButtonGroup>
            </Grid>
            <ConfirmDeleteDialog
              handleClose={() => setConfirmDelete(false)}
              handleDelete={handleDelete}
              item={'task'}
              itemNames={taskNames}
              open={confirmDelete}
              plural={selected?.length > 1}
            />
          </Grid>
        </Paper>
      </Box>
    </Slide>
  );
};

export const getTaskActions = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  const taskActions = {
    undo: () => {
      gantt.ext.undo.undo();
    },
    redo: () => {
      gantt.ext.undo.redo();
    },
    indent: (id) => {
      let prevId = gantt.getPrevSibling(id);
      while (gantt.isSelectedTask(prevId)) {
        const prev = gantt.getPrevSibling(prevId);
        if (!prev) break;
        prevId = prev;
      }
      if (prevId) {
        const newParent = gantt.getTask(prevId);
        const moved = gantt.moveTask(id, getChildren(newParent.id, gantt).length, newParent.id);
        if (moved === undefined) {
          newParent.type = 'parent_task';
          newParent.$open = true;
          gantt.updateTask(id);
          gantt.updateTask(newParent.id);
        }
        return id;
      }
      return null;
    },
    outdent: (id, initialIndexes, initialSiblings) => {
      const curTask = gantt.getTask(id);
      const oldParent = curTask.parent;
      if (gantt.isTaskExists(oldParent) && oldParent != gantt.config.root_id && oldParent > 0) {
        let index = gantt.getTaskIndex(oldParent) + 1;
        let prevSibling = initialSiblings[id].first;

        if (gantt.isSelectedTask(prevSibling)) {
          index += initialIndexes[id] - initialIndexes[prevSibling];
        }
        gantt.moveTask(id, index, gantt.getParent(curTask.parent));
        if (!hasChild(oldParent, gantt)) gantt.getTask(oldParent).type = gantt.config.types.task;
        gantt.updateTask(id);
        gantt.updateTask(oldParent);
        return id;
      }
      return null;
    },
  };
  return taskActions;
};

export default BulkEditMenu;
