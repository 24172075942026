import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';

import { useCheckForUpdates } from '../hooks/useCheckForUpdates';

export function UpdateChecker() {
  const { enqueueSnackbar } = useSnackbar();
  const { isUpdateAvailable } = useCheckForUpdates();

  const handleReload = () => {
    window.location.reload();
  };

  const showUpdateAvailable = () => {
    enqueueSnackbar('A new version of BlackHyve is available!', {
      key: 'APP_UPDATE_AVAILABLE',
      action: () => (
        <Button color={'secondary'} variant={'text'} onClick={handleReload}>
          Reload Now
        </Button>
      ),
      preventDuplicate: true,
      persist: true,
    });
  };

  useEffect(() => {
    if (isUpdateAvailable) {
      showUpdateAvailable();
    }
  }, [isUpdateAvailable]);
}
