import { axiosRequestHandler } from '../../../helpers/apiHelper';
import { parse, unparse } from 'papaparse';

function convertSnakeCaseToTitleCase(headers) {
  return headers.map((header) => {
    const words = header.split('_');

    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

    const titleCaseHeader = capitalizedWords.join(' ');

    return titleCaseHeader;
  });
}

/**
 * Fetches all tasks
 * @param {String} projectId project id
 * @returns
 */
export const fetchTasksAPI = async ({ projectId, includes, queryParam }) => {
  try {
    return await axiosRequestHandler(
      `tasks?${projectId ? `project=${projectId}&` : ''}include=dependencies,area,company${
        includes ? `,${includes}` : ''
      }${queryParam}`,
      'get'
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Update task
 * @param {Task} data
 *  * @param {String} data.id
 * @returns
 */
export const updateTaskAPI = async (data) => {
  try {
    return await axiosRequestHandler(`tasks/${data.id}`, 'patch', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Create task
 * @param {Object} data
 * * @param {String} data.name
 * * @param {String} data.project_id
 * * @param {String} data.description
 * * @param {String} data.start
 * * @param {String} data.end
 * * @param {String} data.effort_hours
 * * @param {String} data.effort_tag
 * * @param {String} data.duration_string
 * * @param {String} data.duration_days
 * * @param {String} data.crew_size
 * * @param {String} data.shift
 * @returns
 */
export const createTaskAPI = async (data) => {
  try {
    return await axiosRequestHandler(`tasks`, 'post', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Delete  tasks
 * @param {*} data
 * @param {Number[]} data.tasks
 * @returns
 */
export const deleteTasksAPI = async (data) => {
  try {
    return await axiosRequestHandler(`tasks`, 'delete', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Commit task
 * @param {*} data
 * @param {*} data.id - Task id
 * @param {Date} [data.start] - Task committed start date
 * @returns
 */
export const commitTaskAPI = async (data) => {
  try {
    return await axiosRequestHandler(
      `tasks/${data.id}/commit`,
      'post',
      data?.start ? { start: data.start } : undefined
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong while committing task...' });
  }
};

/**
 * Uncommit task
 * @param {*} data
 * @param {*} data.id - Task id
 * @returns
 */
export const uncommitTaskAPI = async (data) => {
  try {
    return await axiosRequestHandler(`tasks/${data.id}/uncommit`, 'post');
  } catch (error) {
    throw new Error({
      error: true,
      message: 'Something went wrong while uncommitting task...',
    });
  }
};

/**
 * Fill task with recipe
 * @param {*} data
 * @param {Number} data.recipe_id - recipeId
 * @param {Number} data.taskId - taskId
 */
export const insertRecipeAPI = async ({ task_id, recipe_id, project_id }) => {
  try {
    return await axiosRequestHandler(`recipes/insert`, 'post', { task_id, recipe_id, project_id });
  } catch (error) {
    throw new Error({
      error: true,
      message: 'Something went wrong while splitting areas...',
    });
  }
};

/**
 * Get Task constraints
 * @param {Number} taskId
 */
export const fetchTaskConstraintsAPI = async (taskId) => {
  try {
    return await axiosRequestHandler(`tasks/${taskId}/preconditions`, 'GET');
  } catch (error) {
    throw new Error({
      error: true,
      message: 'Something went wrong getting constraints...',
    });
  }
};

/**
 * Split Locations (location, zone, area) into tasks
 * @param {*} data
 * @param {Number[]} data.tasks - task ids
 * @param {Number[]} data.areas - area ids
 */
export const splitTaskByLBS = async (data) => {
  try {
    return await axiosRequestHandler(`tasks/${data.type}`, 'post', data);
  } catch (error) {
    throw new Error({
      error: true,
      message: 'Something went wrong while splitting areas...',
    });
  }
};

/**
 * Bulk update task
 * @param {*} data
 * @param {Object[]} data.tasks - task obj
 */
export const updateTasksAPI = async (data) => {
  try {
    return await axiosRequestHandler(`tasks`, 'put', data);
  } catch (error) {
    throw new Error({
      error: true,
      message: 'Something went wrong while splitting areas...',
    });
  }
};

/**
 * Bulk upload task
 * @param {*} data
 * @param {String} data.project_id - project id
 * @param {Object[]} data.tasks - task obj
 */
export const createTasksAPI = async (data) => {
  try {
    return await axiosRequestHandler('tasks/bulk', 'post', data);
  } catch (error) {
    throw new Error({
      error: true,
      message: 'Something went wrong while uploading bulk task...',
    });
  }
};

/**
 * Fetches children tasks
 * @param {Array} projectIds project ids [121,107]
 * @param {Array} taskIds task ids [121,107]
 * @returns
 */
export const fetchChildrenTasksAPI = async ({ projectIds, taskIds }) => {
  try {
    return await axiosRequestHandler(
      `tasks?include=dependencies&project=${projectIds.join('')}&children_of=${taskIds.join('')}`,
      'get'
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Fetch project baselines
 * @param {String} projectId
 * @returns
 */
export const fetchProjectBaselinesAPI = async (projectId) => {
  try {
    return await axiosRequestHandler(`projects/${projectId}/baselines`, 'get');
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

/**
 * Create baselines
 * @param {Object} data
 * @param {Number} data.id
 * @param {String} data.name
 * @param {Number} data.projectId
 * @returns
 */

export const createBaselineAPI = async (data) => {
  try {
    return await axiosRequestHandler(`projects/${data.projectId}/baselines`, 'post', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Something went wrong..!!' });
  }
};

export const createProductionBoardTasksAPI = async (data) => {
  return await axiosRequestHandler(`lookahead_tasks`, 'post', data);
};

export const updateProductionBoardTasksAPI = async (data) => {
  return await axiosRequestHandler(`lookahead_tasks/${data.id}`, 'patch', data);
};

/** 
    Save JobWalk  API
    @param {Number} id taskId
    @param {Number} percent_complete 
    @param {Date} date
*/
export const jobwalkAPI = async (data) => {
  try {
    return await axiosRequestHandler(`tasks/${data.id}/job_walks`, 'post', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Issue with API..!!' });
  }
};

/** 
    Update fulfilled preconditions API
    @param {String} id taskId
    @param {[Number]} preconditionId 
*/
export const updatePreconditionsAPI = async (data) => {
  try {
    return await axiosRequestHandler(`tasks/${data.id}/preconditions`, 'post', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Issue with API..!!' });
  }
};

/**
 * Pause task
 * @param {Object} requestParameter
 * @param {String} requestParameter.paused_on
 * @param {Number} requestParameter.reason
 * @param {Date} requestParameter.resume_on
 * @param {String} requestParameter.type
 */
export const pauseTaskAPI = async (data) => {
  try {
    return await axiosRequestHandler(`tasks/${data.id}/pause`, 'post', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Issue with API..!!' });
  }
};

/**
 * Resume task
 * @param {Object} requestParameter
 * @param {String} requestParameter.type
 * @param {Date} requestParameter.resumed_on
 */
export const resumeTaskAPI = async (data) => {
  try {
    return await axiosRequestHandler(`tasks/${data.id}/resume`, 'post', data);
  } catch (error) {
    throw new Error({ error: true, message: 'Issue with API..!!' });
  }
};

/**
 * Get task history
 * @param {Object} requestParameter
 * @param {Number} requestParameter.id
 */
export const fetchTaskHistoryAPI = async (data) => {
  try {
    return await axiosRequestHandler(
      `tasks/${data.id}/history?with_children=${data.with_children}`,
      'get'
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Issue with API..!!' });
  }
};

/** TASK COMMENT APIS */

/**
 * Add comment to task
 * @param {Object} requestParameter
 * @param {Number} requestParameter.taskId
 * @param {String} requestParameter.comment
 */
export const addTaskCommentAPI = async (requestParameter) => {
  try {
    return await axiosRequestHandler(
      `tasks/${requestParameter.taskId}/comments`,
      'post',
      requestParameter
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Issue with API..!!' });
  }
};

/**
 * Update comment to task
 * @param {Object} requestParameter
 * @param {Number} requestParameter.taskId
 * @param {String} requestParameter.comment
 */
export const updateTaskCommentAPI = async (requestParameter) => {
  try {
    return await axiosRequestHandler(
      `tasks/${requestParameter.taskId}/comments/${requestParameter.id}`,
      'patch',
      requestParameter
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Issue with API..!!' });
  }
};

/**
 * Update comment to task
 * @param {Object} requestParameter
 * @param {Number} requestParameter.taskId
 * @param {String} requestParameter.id
 */
export const deleteTaskCommentAPI = async (requestParameter) => {
  try {
    return await axiosRequestHandler(
      `tasks/${requestParameter.taskId}/comments/${requestParameter.id}`,
      'delete'
    );
  } catch (error) {
    throw new Error({ error: true, message: 'Issue with API..!!' });
  }
};

/**
 * Add Flow AI To Tasks
 * @param {*} data
 * @param {Number} data.recipe_id
 * @param {Number} data.task_id
 * @param {Object} data.locations
 * @param {Object} data.zones
 * @param {Object} data.areas
 */
export const addFlowAITasksAPI = async (data) => {
  try {
    return await axiosRequestHandler(`recipes/flow`, 'post', data);
  } catch (error) {
    throw new Error({
      error: true,
      message: 'Something went wrong while adding flowAI tasks areas...',
    });
  }
};

/**
 * Export Gantt as Csv
 * @param {Number} projectId
 * @param {String} projectName
 */
export const exportToCsv = async (projectId, projectName) => {
  const csvAPIUrl = `projects/${projectId}/export`;
  const responseType = 'blob';

  //* Get Backend CSV file
  const backendCsv = await axiosRequestHandler(csvAPIUrl, 'post', null, responseType);

  //* Convert CSV into string so that it can be parsed by Papa Parse
  const backendCsvString = await backendCsv.response.text();
  const ganttCsv = parse(backendCsvString);

  //* Create headers that are capitilized and that use spaces instead of underscores
  const ganttCsvHeaders = ganttCsv.data[0];
  const customHeaders = convertSnakeCaseToTitleCase(ganttCsvHeaders);
  ganttCsv.data.shift(); // Remove the first row of old headers
  ganttCsv.data.unshift(customHeaders); // Replace the first row with customHeaders

  //* Find the index of the columns we want to remove
  const idIndex = ganttCsv.data[0].indexOf('Id');
  const uuidIndex = ganttCsv.data[0].indexOf('Uuid');
  const parentIdIndex = ganttCsv.data[0].indexOf('Parent Id');
  const durationStringIndex = ganttCsv.data[0].indexOf('Duration String');

  //* Remove unnecessary columns for each row
  const indexesToRemove = [idIndex, uuidIndex, parentIdIndex, durationStringIndex];
  indexesToRemove.sort((a, b) => b - a);
  ganttCsv.data.forEach((row) => {
    indexesToRemove.forEach((index) => {
      row.splice(index, 1);
    });
  });

  //* Return the CSV to a string so that it can turned into a blob and be downloaded by the user
  const ganttCsvString = unparse(ganttCsv.data);
  const downloadableCsv = new Blob([ganttCsvString], { type: 'text/csv' });

  //* Create a link element for the download
  const csvUrl = window.URL.createObjectURL(downloadableCsv);
  const link = document.createElement('a');
  link.href = csvUrl;
  link.setAttribute('download', `${projectName}`);
  document.body.appendChild(link);

  //* Trigger a click event on the link to start the download
  link.click();

  //* Clean up download link
  document.body.removeChild(link);
  window.URL.revokeObjectURL(csvUrl);
};
