import Tag from './Tag';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import Clear from '@mui/icons-material/Clear';
import { useGetTagsQuery } from '../store/tag.api';
import Autocomplete from '@mui/material/Autocomplete';
import { styled, lighten, darken } from '@mui/system';
import { useState, useMemo, useCallback } from 'react';
import {
  Button,
  IconButton,
  List,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  ListItemButton,
} from '@mui/material';
import CreateTagDialog from './CreateTagDialog';


const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  zIndex: 2,
  textTransform: 'uppercase',
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
  ...theme.applyStyles('dark', {
    backgroundColor: darken(theme.palette.primary.main, 0.8),
  }),
}));

const GroupItems = styled('ul')({
  padding: 0,
});


/**
 * Tag Dropdown
 * @param {Object} setSelectedTags
 * @param {Object} selectedTags
 * @param {Object} disableEdit
 * @returns 
 */
const TagDropdown = ({ setSelectedTags, selectedTags, disableEdit, ...dropdownProps }) => {

  return <Box display={'flex'} flexDirection={'column'} height={'100%'}>
    <TagAutocompleteDropdown selectedTags={selectedTags} setSelectedTags={setSelectedTags} {...dropdownProps} />
    <TagList disableEdit={disableEdit} selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
  </Box>
}


const TagList = ({ selectedTags, setSelectedTags, disableEdit }) => {

  return <Box dense component={List} maxHeight={'25vh'} sx={{ overflowY: 'auto', padding: '5px' }}>
    {selectedTags?.map((tag) => {
      return (
        <ListItemButton disableGutters key={tag.id} style={{ paddingLeft: 5 }}>
          <Tag color={tag.color} name={tag.name} type={tag.type} />
          {!disableEdit && tag.tagged_by !== 0 && tag.tagged_by !== "generated" && (
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => {
                  setSelectedTags(selectedTags.filter(({ id }) => id !== tag.id));
                }}
              >
                <Clear />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItemButton>
      );
    })}
  </Box>
}

export const TagAutocompleteDropdown = ({ selectedTags, setSelectedTags, ...dropdownProps }) => {

  const { data: tags = [], isLoading, isFetching } = useGetTagsQuery()

  const [openTagDialog, setOpenTagDialog] = useState(false);

  const handleCloseCreateTagDialog = () => {
    setOpenTagDialog(false)
  }

  const sortedTags = useMemo(() => {
    const sortTags = (a, b) => {
      if (a.type === "smart" && b.type !== "smart") {
        return -1;
      }
      if (b.type === "smart" && a.type !== "smart") {
        return 1;
      }
      return a.type.localeCompare(b.type);
    };

    return tags?.length ? [...tags].filter((tag) => tag.type !== "generated").sort(sortTags) : [];
  }, [tags]);


  const CustomPaperComponent = useCallback(({ children, ...props }) => {

    return <Paper {...props}>
      {children}
      <Button
        fullWidth
        color="primary"
        startIcon={<AddIcon />}
        sx={{ justifyContent: "flex-start", pl: 2 }}
        onMouseDown={(e) => {
          e.preventDefault();
          setOpenTagDialog(true)
        }}
      >
        Add New Tag
      </Button>
    </Paper>
  }, [])


  return <>
    <Autocomplete
      disableClearable
      disableCloseOnSelect
      fullWidth
      multiple
      getOptionLabel={(option) => option.name}
      groupBy={(option) => option.type}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={isLoading || isFetching}
      options={tags?.length ? sortedTags : []}
      PaperComponent={CustomPaperComponent} // Pass the state setter as a prop
      renderTags={() => null}
      value={selectedTags}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      renderInput={(params) =>
        <TextField
          {...params}
          fullWidth
          placeholder='Search Tags'
          size='small'
          variant='outlined'
        />}
      renderOption={(props, option, { selected }) => {
        return (
          <ListItemButton key={option.id} {...props}>
            <ListItemIcon>
              <Checkbox checked={selected} />
            </ListItemIcon>
            <ListItemText style={option.deleted_at ? { textDecoration: 'line-through' } : {}}>
              <Tag color={option.color} name={option.name} type={option.type} />
            </ListItemText>
          </ListItemButton>
        );
      }}
      onChange={(event, newValue) => {
        if (event.key !== 'Backspace') {
          setSelectedTags(newValue.map((option) => (option)));
        }
      }}
      {...dropdownProps}
    />
    {openTagDialog &&
      <CreateTagDialog
        handleClose={handleCloseCreateTagDialog}
        open={openTagDialog}
      />}
  </>
}





export default TagDropdown