const {
  addDays,
  addWeeks,
  eachDayOfInterval,
  isBefore,
  isAfter,
  parse,
  format,
  isSameYear,
  isWithinInterval,
  differenceInHours,
  differenceInDays,
  differenceInMinutes,
  min,
  max,
  isSameDay,
} = require('date-fns');

function parseDate(dateString) {
  if (dateString instanceof Date) {
    return dateString;
  } else if (typeof dateString === 'string') {
    if (dateString.endsWith('Z') || dateString.includes('T')) {
      return new Date(dateString);
    }
    if (dateString.includes('/')) {
      return parse(dateString, 'yyyy/MM/dd', new Date());
    }
    return parse(dateString, 'yyyy-MM-dd', new Date());
  } else {
    return new Date(dateString);
  }
}

const defaultWorkdayCalendar = {
  sunday: '0',
  monday: '1',
  tuesday: '1',
  wednesday: '1',
  thursday: '1',
  friday: '1',
  saturday: '0',
};

const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

function differenceInWorkdays(startDate, endDate, calendarObj) {
  if (isSameDay(startDate, endDate)) {
    return 0;
  }

  const workdayCalendar = calendarObj?.workdayCalendar || defaultWorkdayCalendar;

  const isWorkday = (date) => {
    const dayOfWeek = date.getDay();
    const dayName = weekdays[dayOfWeek];
    return workdayCalendar[dayName] === '1';
  };

  const holidays = (calendarObj?.holidays || [])
    .map((holiday) => parseDate(holiday))
    .filter(
      (holiday) =>
        (isSameDay(holiday, startDate) || isAfter(holiday, startDate)) &&
        (isSameDay(holiday, endDate) || isBefore(holiday, endDate)) &&
        isWorkday(holiday) // need to make sure that the holiday would otherwise be a workday
    ).length;

  const range = eachDayOfInterval({
    start: min([startDate, endDate]),
    end: max([startDate, endDate]),
  });

  const workdays = range.filter((date) => isWorkday(date)).length;

  const sign = isBefore(startDate, endDate) ? 1 : -1;
  return sign * (workdays - holidays - 1);
}

function addWorkdays(startDate, workdays, calendarObj) {
  const workdayCalendar = calendarObj?.workdayCalendar
    ? calendarObj?.workdayCalendar
    : defaultWorkdayCalendar;
  const holidays = calendarObj?.holidays?.length ? calendarObj.holidays : [];
  // return date;
  if (!(startDate instanceof Date) || isNaN(startDate)) {
    throw new Error('Invalid startDate');
  }

  // Calculate full weeks and remaining days
  const fullWeeks = Math.floor(
    workdays / weekdays.filter((day) => workdayCalendar[day] === '1').length
  );
  const remainingDays = workdays % weekdays.filter((day) => workdayCalendar[day] === '1').length;

  let date = new Date(startDate);

  // Add full weeks
  date = addWeeks(date, fullWeeks);

  // Add remaining days
  let daysAdded = 0;
  while (daysAdded < remainingDays) {
    date = addDays(date, 1);
    const dayOfWeek = weekdays[date.getDay()];

    if (workdayCalendar[dayOfWeek] === '1') {
      daysAdded += 1;
    }
  }

  // Adding holidays
  let holidayCount = 0;
  for (const holiday of holidays) {
    const parsedDate = parseDate(holiday);
    if (
      workdayCalendar[weekdays[parsedDate.getDay()]] === '1' &&
      isWithinInterval(parsedDate, { start: startDate, end: date })
    ) {
      holidayCount++;
    }
  }
  if (holidayCount > 0) {
    date = addWorkdays(date, holidayCount, { workdayCalendar, holidays });
  }

  return date;
}

function formatTime(date) {
  const now = new Date();

  const minutesDifference = differenceInMinutes(now, date);
  const hoursDifference = differenceInHours(now, date);
  const daysDifference = differenceInDays(now, date);

  if (minutesDifference < 60) {
    return `${minutesDifference} minute${minutesDifference === 1 ? '' : 's'} ago`;
  } else if (hoursDifference < 24) {
    return `${hoursDifference} hour${hoursDifference === 1 ? '' : 's'} ago`;
  } else if (daysDifference <= 7) {
    return `${daysDifference} day${daysDifference === 1 ? '' : 's'} ago`;
  } else {
    const dateFormat = isSameYear(date, now) ? 'MMMM d h:mm a' : 'MMMM d, yyyy h:mm a';
    return format(date, dateFormat);
  }
}

module.exports = {
  parseDate,
  addWorkdays,
  differenceInWorkdays,
  formatTime,
};
