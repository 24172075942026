const initTooltip = (gantt) => {
  if (gantt?.ext?.tooltips) {
    // Removing default tooltips
    gantt.ext.tooltips.detach('[' + gantt.config.task_attribute + ']:not(.gantt_task_row)');

    gantt.ext.tooltips.tooltipFor({
      selector: '.link-missing-warning',
      html: function (event, domElement) {
        return 'This task has no dependency';
      },
    });

    gantt.ext.tooltips.tooltipFor({
      selector: '.jobwalk-past-due-icon',
      html: function (event, domElement) {
        return 'This task has an open jobwalk that is past due';
      },
    });

    gantt.ext.tooltips.tooltipFor({
      selector: '.jobwalk-due-icon',
      html: function (event, domElement) {
        return 'This task has an open jobwalk';
      },
    });

    gantt.ext.tooltips.tooltipFor({
      selector: '.toggle_autoschedule_date',
      html: function (event, domElement) {
        const id = event.target.parentNode.dataset.taskId;
        const task = gantt.getTask(id);
        return `Toggle Auto Schedule Date<br/>Current: <b>${task?.autoschedule_date === 'schedule' ? 'Scheduled End' : 'Forecasted End'
          }</b>`;
      },
    });

    gantt.ext.tooltips.tooltipFor({
      selector: '.open-stuck-points',
      html: function (event, domElement) {
        const label = gantt.constants?.labels?.['stuck_point']?.label
        return `This task has an open ${label}`;
      },
    });

    //Tooltip for insert child task
    gantt.ext.tooltips.tooltipFor({
      selector: '.insert_child',
      html: function (event, domElement) {
        return 'Insert child';
      },
    });
    //Tooltip for insert task below
    gantt.ext.tooltips.tooltipFor({
      selector: '.insert_below',
      html: function (event, domElement) {
        return 'Insert below';
      },
    });

    //Tooltip for insert child task
    gantt.ext.tooltips.tooltipFor({
      selector: '.locked',
      html: function (event, domElement) {
        return 'Scheduled Dates Locked';
      },
    });

    gantt.ext.tooltips.tooltipFor({
      selector: '.column-label',
      html: function (event, domElement) {
        return domElement.getAttribute('data-label')
      }
    })
  }
};

export default initTooltip;
