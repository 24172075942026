import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import LabelIcon from '@mui/icons-material/LabelOutlined';
import SmartIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import ArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';

/**
 * Tag Component
 * @param {String} name
 * @param {String} color
 */
const Tag = ({ name, type, color, tagProps, ...props }) => {

  const icon = type === "cascading" ? <ArrowDownIcon /> : type === "smart" ? <SmartIcon /> : type === "generated" ? <AutoAwesomeIcon /> : <LabelIcon />

  return <Tooltip title={name}>
    <Chip
      icon={icon}
      label={name}
      sx={(theme) => ({
        bgcolor: color,
        color: theme.palette.getContrastText(color ? color : "#D3D3D3"),
        borderColor: color,
        '& .MuiChip-icon': {
          color: `${theme.palette.getContrastText(color ? color : "#D3D3D3")} !important`
        },
        '& .MuiChip-label': {
          marginTop: '1px'
        },
        '& .MuiChip-deleteIcon': {
          opacity: 0.8,
          color: `${theme.palette.getContrastText(color ? color : "#bdbdbd")} !important`
        },
        ...tagProps
      })}
      {...props}
    />
  </Tooltip>

}

export default Tag;