import { CompanyIcon } from '@blackhyve/common/src/icons/CompanyIcon';
import PersonIconComponent from '@blackhyve/common/src/icons/Person';
import TaskIconComponent from '@blackhyve/common/src/icons/Task';
import { parseDate } from '@blackhyve/utilities/dates';
import {
  ChevronRight as ChevronRightIcon,
  DateRange,
  DragIndicator,
  ExpandMore,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Checkbox,
  Collapse,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import AvatarComponent from 'components/common/v3/AvatarComponent';
import DisplayWithEditAccess from 'components/common/v3/DisplayWithEditAccess';
import format from 'date-fns/format';
import { useGetStepsQuery, useUpdateStepMutation } from 'features/steps/store/step.api';
import { Status } from 'features/tasks';
import { useDeleteTasksMutation } from 'features/tasks/store/task.api';
import { times } from 'lodash';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ProductionBoardCreateListDialog from './ProductionBoardCreateListDialog';
import ProductionBoardCreateTaskDialog from './ProductionBoardCreateTaskDialog';
import { MenuOptions } from './ProductionBoardMenuOptions';
import TagList from 'features/tags/components/TagList';

/**
 * ProductionBoardRow
 * @param {Object} entityObj
 * @param {String} entityObj.id
 * @param {String} entityObj.name
 * @param {String} entityObj.scheduled_start
 * @param {String} entityObj.scheduled_end
 * @param {String} to
 * @param {Object} children
 * @returns
 */
export const ProductionBoardRow = ({ task, children, dragHandler }) => {
  const { workspaceId } = useParams();
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteTasks, { isLoading: isTaskDeleteLoading }] = useDeleteTasksMutation();

  const startDate = task?.scheduled_start
    ? format(parseDate(task?.scheduled_start), 'MMM dd, yyyy')
    : '';
  const endDate = task?.scheduled_end ? format(parseDate(task?.scheduled_end), 'MMM dd, yyyy') : '';
  const forecastedStart = task?.forecasted_start
    ? format(parseDate(task?.forecasted_start), 'MMM dd, yyyy')
    : startDate;
  const forecastedEnd = task?.forecasted_end
    ? format(parseDate(task?.forecasted_end), 'MMM dd, yyyy')
    : endDate;

  const percentComplete = task?.percent_complete ? task?.percent_complete * 100 : 0;

  const handleOnEdit = () => setIsEditing(true);

  const handleCloseEditDialog = () => setIsEditing(false);

  const handleOnDelete = (id) => deleteTasks({ tasks: [id] });

  return isTaskDeleteLoading ? (
    <ProductionBoardSkeletonList noOfRows={1} />
  ) : (
    <Grid container item component={Paper} elevation={3} m={1} p={1} xs={12}>
      <Grid item xs="auto">
        <Stack alignItems={'center'} direction={'row'}>
          <DisplayWithEditAccess>
            <Box display={'flex'} {...dragHandler}>
              <DragIndicator sx={{ color: 'grey.600', p: 0.5 }} />
            </Box>
          </DisplayWithEditAccess>
          <IconButton sx={{ p: 0.5 }} onClick={() => setOpen(!open)}>
            {open ? <ExpandMore /> : <ChevronRightIcon />}
          </IconButton>
        </Stack>
      </Grid>
      <Grid container item xs alignItems={'center'}>
        <Grid container item alignItems={'center'} gap={1}>
          <Typography
            color={'primary'}
            component={RouterLink}
            sx={{ textDecoration: 'none', fontWeight: 'bold' }}
            to={`/workspace/${workspaceId}/projects/${task.project_id}/production-board/tasks/${task.id}`}
          >
            {task?.name}
          </Typography>
          <TagList tags={task?.tags} />
          <DisplayWithEditAccess>
            <Box sx={{ ml: 'auto' }}>
              <MenuOptions entityObj={task} onDelete={handleOnDelete} onEdit={handleOnEdit} />
            </Box>
          </DisplayWithEditAccess>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <FormLabel sx={{ fontSize: 15 }}> Planned Dates</FormLabel>
          <Stack alignItems="center" direction="row">
            <Typography variant="subtitle2">
              {startDate} - {endDate}
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={3} sm={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <FormLabel sx={{ fontSize: 15 }}> Forecasted Dates</FormLabel>
          <Stack alignItems="center" direction="row">
            <Typography variant="subtitle2">
              {forecastedStart} - {forecastedEnd}
            </Typography>
          </Stack>
        </Grid>

        <Grid item md={3} sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <FormLabel sx={{ fontSize: 15 }}>Percent Complete</FormLabel>
          <Stack alignItems="center" direction="row" gap={0.5}>
            <LinearProgress
              sx={{ width: '50%', height: '10px' }}
              value={percentComplete}
              variant="determinate"
            />
            <Typography color="text.secondary" variant="body2">
              {`${Math.round(percentComplete)}%`}
            </Typography>
          </Stack>
        </Grid>

        <Grid item md={3} sm={8} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <FormLabel sx={{ fontSize: 15 }}>Leaders</FormLabel>
          {task?.responsible_users?.length ? (
            <Stack alignItems="center" direction="row" gap={0.5}>
              {task?.responsible_users?.map((user) => (
                <AvatarComponent
                  name={user.name}
                  src={user?.profile_image?.original_url}
                  sx={{ height: 30, width: 30, fontSize: 16 }}
                />
              ))}
            </Stack>
          ) : (
            <Typography variant="subtitle2">NA</Typography>
          )}
        </Grid>

        <Collapse mountOnEnter unmountOnExit in={open} sx={{ width: '100%' }} timeout={'auto'}>
          <Grid container item ml={1} mt={1.5} xs={12}>
            {children}
          </Grid>
        </Collapse>
      </Grid>
      {isEditing && !task?.is_parent && (
        <ProductionBoardCreateTaskDialog
          handleClose={handleCloseEditDialog}
          open={true}
          parentId={task.parent_id}
          projectId={task.project_id}
          task={task}
        />
      )}
      {isEditing && task?.is_parent && (
        <ProductionBoardCreateListDialog
          handleClose={handleCloseEditDialog}
          open={true}
          parentId={task.parent_id}
          projectId={task.project_id}
          task={task}
        />
      )}
    </Grid>
  );
};

/**
 * Production board children row
 * @param {String} name
 * @param {String} scheduled_start
 * @param {String} scheduled_end
 * @param {String} is_parent
 * @returns
 */
export const ProductionBoardRowChildren = ({ task }) => {
  const { workspaceId } = useParams();
  const startDate = task.scheduled_start
    ? format(parseDate(task.scheduled_start), 'MMM dd, yyyy')
    : '';
  const endDate = task.scheduled_end ? format(parseDate(task.scheduled_end), 'MMM dd, yyyy') : '';

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography
          color={'primary'}
          component={RouterLink}
          sx={{ fontWeight: task.is_parent ? 'bold' : '', textDecoration: 'none' }}
          to={`/workspace/${workspaceId}/projects/${task.project_id}/production-board/tasks/${task.id}${!task.is_parent ? '/details' : ''}`}
          variant="title"
        >
          {task?.name}
        </Typography>
      </Grid>
      {startDate && endDate && (
        <Grid container item alignItems={'center'} gap={1} xs={12}>
          <IconButton size="small" sx={{ p: 0 }}>
            <DateRange fontSize="inherit" />
          </IconButton>
          <Typography color={'grey.600'} variant="subtitle2">
            {startDate} - {endDate}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

/**
 * Production board task row
 * @param {Object} task
 * @param {Number} task.id
 * @param {String} task.name
 * @param {String} task.scheduled_start
 * @param {String} task.scheduled_end
 * @param {String} areaId
 * @param {String} projectId
 * @param {Object} rowProps
 * @param {Object} onDelete
 * @param {Object} onEdit
 * @returns
 */
export const ProductionBoardTaskRow = ({ task, rowProps, dragHandler }) => {
  const { workspaceId } = useParams();
  const startDate = task?.scheduled_start
    ? format(parseDate(task?.scheduled_start), 'MMM dd, yyyy')
    : '';
  const endDate = task?.scheduled_end ? format(parseDate(task?.scheduled_end), 'MMM dd, yyyy') : '';
  const forecastedStart = task?.forecasted_start
    ? format(parseDate(task?.forecasted_start), 'MMM dd, yyyy')
    : startDate;
  const forecastedEnd = task?.forecasted_end
    ? format(parseDate(task?.forecasted_end), 'MMM dd, yyyy')
    : endDate;

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [showSteps, setShowSteps] = useState(false);

  const [deleteTasks] = useDeleteTasksMutation();

  const handleDelete = (id) => deleteTasks({ tasks: [id] });
  const handleEditDialog = () => setOpenEditDialog(true);
  const handleCloseDialog = () => setOpenEditDialog(false);

  return (
    <Grid container item sx={{ p: 1, m: 1 }} xs={12} {...rowProps}>
      <Grid item xs="auto">
        <Stack alignItems={'center'} direction={'row'}>
          <DisplayWithEditAccess>
            <Box display={'flex'} {...dragHandler}>
              <DragIndicator sx={{ color: 'grey.600', p: 0.5 }} />
            </Box>
          </DisplayWithEditAccess>
          <SvgIcon color={'action'} sx={{ p: 0.5 }}>
            <TaskIconComponent className="task-image" />
          </SvgIcon>
        </Stack>
      </Grid>
      <Grid container item xs alignItems={'center'}>
        <Grid container item alignItems={'center'} gap={1}>
          <Typography
            color={'primary'}
            component={RouterLink}
            sx={{ textDecoration: 'none' }}
            to={`/workspace/${workspaceId}/projects/${task.project_id}/production-board/tasks/${task.id}${!task.is_parent ? '/details' : ''}`}
          >
            {task?.name}
          </Typography>
          <Status sx={{ py: 0 }} value={task?.status} />
          <TagList tags={task?.tags} />
          <DisplayWithEditAccess>
            <Box sx={{ ml: 'auto' }}>
              <MenuOptions entityObj={task} onDelete={handleDelete} onEdit={handleEditDialog} />
            </Box>
          </DisplayWithEditAccess>
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <FormLabel sx={{ fontSize: 15 }}>Planned Dates</FormLabel>
          <Stack alignItems="center" direction="row">
            <Typography variant="subtitle2">
              {startDate} - {endDate}
            </Typography>
          </Stack>
        </Grid>

        <Grid item md={3} sm={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <FormLabel sx={{ fontSize: 15 }}> Forecasted Dates</FormLabel>
          <Stack alignItems="center" direction="row">
            <Typography variant="subtitle2">
              {forecastedStart} - {forecastedEnd}
            </Typography>
          </Stack>
        </Grid>

        <Grid item md={3} sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <FormLabel sx={{ fontSize: 15 }}>Crew Size</FormLabel>
          <Stack alignItems="center" direction="row">
            <IconButton size="small" sx={{ pl: 0 }}>
              <PersonIconComponent fontSize="inherit" />
            </IconButton>
            <Typography mt="auto" variant="subtitle2">
              {task?.crew_size ? task.crew_size : 0}
            </Typography>
          </Stack>
        </Grid>

        <Grid item md={3} sm={8} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <FormLabel sx={{ fontSize: 15 }}>Companies</FormLabel>
          {task?.companies?.length ? (
            <Stack alignItems="center" direction="row">
              <IconButton size="small" sx={{ pl: 0 }}>
                <CompanyIcon color="grey" fontSize="inherit" />
              </IconButton>
              <Typography variant="subtitle2">
                {task?.companies?.map((company) => company?.name).join(', ')}
              </Typography>
            </Stack>
          ) : (
            <Typography variant="subtitle2">NA</Typography>
          )}
        </Grid>

        <Grid container item alignItems={'center'} gap={1}>
          <Link
            component={'button'}
            fontSize={'.9em'}
            onClick={(event) => setShowSteps(!showSteps)}
          >
            {showSteps ? 'Hide' : 'Show'} Steps
          </Link>
          {showSteps ? <DisplayTaskSteps taskId={task.id} /> : null}
        </Grid>
      </Grid>

      {openEditDialog && (
        <ProductionBoardCreateTaskDialog
          handleClose={handleCloseDialog}
          open={openEditDialog}
          projectId={task.project_id}
          task={task}
        />
      )}
    </Grid>
  );
};

const DisplayTaskSteps = ({ taskId }) => {
  const { data: steps = [], isLoading } = useGetStepsQuery({
    entityId: taskId,
    entityType: 'tasks',
    order_by: 'created_at,desc;',
  });

  return (
    <Grid container gap={0.5}>
      {isLoading ? (
        times(5, (row) => <Skeleton height={30} key={row} variant="text" width="100%" />)
      ) : steps.length ? (
        steps?.map((step) => {
          return <Step step={step} />;
        })
      ) : (
        <Alert severity="info" sx={{ px: 1, py: 0, width: 1 }}>
          No Steps present
        </Alert>
      )}
    </Grid>
  );
};

const Step = ({ step }) => {
  const [complete, setComplete] = useState(step?.is_complete);
  const [updateStep, { isLoading: isUpdateStep }] = useUpdateStepMutation();

  const handleUpdateComplete = async () => {
    const newComplete = !complete;
    setComplete(newComplete);
  };

  useEffect(() => {
    if (step?.is_complete !== complete) {
      updateStep({
        id: step?.id,
        entityId: step?.task_id,
        entityType: 'tasks',
        is_complete: complete,
      });
    }
  }, [step?.is_complete, complete, step?.id, updateStep, step?.task_id]);

  return (
    <Grid container item justifyContent={'center'} key={step?.id} xs={12}>
      <Grid item alignItems={'flex-start'} justifyContent={'flex-start'}>
        <Checkbox checked={complete} disabled={isUpdateStep} onChange={handleUpdateComplete} />
      </Grid>
      <Grid container item xs justifyContent={'center'}>
        <Grid container item alignItems={'center'} xs={12}>
          <ReactQuill
            bounds="quill"
            className={'comment'}
            readOnly={true}
            theme={'bubble'}
            value={step?.name}
          />
        </Grid>
        {step?.companies?.length || step?.due_date ? (
          <Grid container item spacing={1} xs={12}>
            {step?.companies?.length ? (
              <Grid
                container
                item
                alignItems={'center'}
                gap={0.5}
                md={8}
                sx={{ display: { xs: 'none', sm: 'flex' } }}
              >
                <IconButton size="small" sx={{ py: 0 }}>
                  <CompanyIcon fontSize="inherit" />
                </IconButton>
                <FormLabel> Companies:</FormLabel>
                <Typography>
                  {step?.companies?.map((company) => company?.name).join(', ')}
                </Typography>
              </Grid>
            ) : null}
            {step?.due_date ? (
              <Grid container item gap={0.5} md={4} sx={{ display: { xs: 'none', sm: 'flex' } }}>
                <FormLabel> Due date:</FormLabel>
                <Typography> {step?.due_date}</Typography>
              </Grid>
            ) : null}
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export const ProductionBoardSkeletonList = ({ isChildrenList = false, noOfRows = 5 }) => {
  return times(noOfRows).map((no) => {
    return (
      <Grid
        container
        item
        component={isChildrenList ? null : Paper}
        elevation={isChildrenList ? 0 : 3}
        key={no}
        m={1}
        p={1}
        xs={12}
      >
        <Grid item xs={12}>
          <Skeleton height={30} width={'100%'} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton height={30} width={'100%'} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton height={30} width={'100%'} />
        </Grid>
      </Grid>
    );
  });
};
