import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Checkbox, FormLabel, Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import {
  useGetProjectCompaniesQuery,
  useGetWorkspaceCompanyQuery,
} from 'features/projectCompanies/api/projectCompanies.api';
import { Controller, useForm } from 'react-hook-form';

const initialState = {
  id: undefined,
  name: '',
  due_date: null,
  companies: [],
};

/**
 * Step form
 * @param {object} step
 * @param {string} taskId
 * @param {string} projectId
 * @param {object} handleClose
 * @returns
 */
export const StepForm = ({ projectId, handleClose, handleSave, step, isLoading }) => {
  const formMethods = useForm({ defaultValues: { ...initialState }, values: step });
  const { control, handleSubmit } = formMethods;

  const { data: companies = [] } = useGetProjectCompaniesQuery({ projectId });
  const { data: userCompany = { users: [] } } = useGetWorkspaceCompanyQuery({ projectId });
  const filteredCompanies = companies?.filter((obj) => obj.id !== userCompany.id);

  const onSave = (form) => {
    handleSave({ ...form, due_date: form.due_date && format(form.due_date, 'yyyy-MM-dd') });
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Grid container gap={2}>
        <Grid item xs={12}>
          <FormLabel>Name*</FormLabel>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <TextField
                autoFocus
                fullWidth
                autoComplete={'off'}
                error={Boolean(error)}
                helperText={error?.message}
                size={'small'}
                {...field}
                onFocus={(event) => {
                  event.target.select();
                }}
              />
            )}
            rules={{
              required: 'Name field is required',
            }}
          />
        </Grid>
        <Grid container item md={4} xs={12}>
          <FormLabel>Companies</FormLabel>
          <Controller
            control={control}
            name="companies"
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                disableCloseOnSelect
                fullWidth
                multiple
                isOptionEqualToValue={(option, value) => option.id === value}
                options={filteredCompanies}
                getOptionLabel={(option) =>
                  option?.name ??
                  (filteredCompanies &&
                    filteredCompanies.find((company) => company.id === option)?.name)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete={'off'}
                    error={error}
                    helperText={error?.message}
                    placeholder={'Select Company'}
                    size={'small'}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option?.id}>
                    <Checkbox checked={selected} />
                    {option?.name}
                  </li>
                )}
                onChange={(event, newValue) => {
                  field.onChange(newValue.map((company) => company?.id ?? company));
                }}
              />
            )}
          />
        </Grid>
        <Grid container item md={'auto'} xs={12}>
          <FormLabel>Due Date</FormLabel>
          <Controller
            control={control}
            name={'due_date'}
            render={({
              field: { onChange, value, ref, ...field },
              fieldState: { error, invalid },
            }) => (
              <DatePicker
                ref={ref}
                value={value}
                slotProps={{
                  textField: {
                    id: 'due_date',
                    fullWidth: true,
                    size: 'small',
                    helperText: error?.message,
                    error: invalid,
                    ...field,
                  },
                  actionBar: {
                    actions: ['today'],
                  },
                }}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid container item gap={1} xs={9}>
          <Button disabled={isLoading} size="small" onClick={handleClose}>
            Close
          </Button>
          <LoadingButton
            loading={isLoading}
            size="small"
            type={'submit'}
            variant={'contained'}
            onClick={handleSubmit(onSave)}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
