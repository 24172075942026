import axios from 'axios';

const REQUEST_TIMEOUT_MS = 5 * 1000;

export async function fetchAssetManifestEtag() {
  if (process.env.REACT_APP_RELEASE_STAGE === 'testing') return;

  try {
    const result = await axios({
      url: `/asset-manifest.json`,
      defaults: {
        timeout: REQUEST_TIMEOUT_MS,
      },
      method: 'HEAD',
    });

    return result.headers.get('etag');
  } catch (e) {
    return;
  }
}
