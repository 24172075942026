import { DragIndicator } from '@mui/icons-material';
import { Box, Checkbox, FormLabel, Grid, Paper, Tooltip, Typography } from '@mui/material';
import ConfirmDeleteDialog from 'components/common/popovers/ConfirmDeleteDialog';
import DisplayWithEditAccess, { useEditContext } from 'components/common/v3/DisplayWithEditAccess';
import { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useDeleteStepMutation, useUpdateStepMutation } from '../store/step.api';
import { StepForm } from './StepForm';
import { StepMoreOption } from './StepMoreOptions';

/**
 * Step
 * @param {object} step
 * @returns
 */
export const Step = ({ step, entityId, entityType, index }) => {
  const [complete, setComplete] = useState(step?.is_complete);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [deleteStep] = useDeleteStepMutation();
  const [updateStep, { isLoading: isUpdatingStep }] = useUpdateStepMutation();

  const handleDelete = () => {
    deleteStep({ id: step?.id, entityId: step?.taskId, entityType: 'task' });
    setIsDeleteDialogOpen(false);
  };

  const handleUpdateComplete = () => {
    const newComplete = !complete;
    setComplete(newComplete);
    updateStep({
      id: step?.id,
      entityId,
      entityType,
      is_complete: newComplete,
    });
  };

  const canEdit = useEditContext();

  useEffect(() => {
    setComplete(step?.is_complete);
  }, [step?.is_complete]);

  return (
    <Draggable
      draggableId={`step-${step?.id}`}
      index={index}
      isDragDisabled={!canEdit}
      key={`step-${step?.id}`}
    >
      {(provided, snapshot) => (
        <Grid
          {...provided.draggableProps}
          container
          square
          alignItems={'center'}
          component={Paper}
          px={2}
          py={0.5}
          ref={provided.innerRef}
          variant={'outlined'}
        >
          {isEditOpen ? (
            <StepForm
              handleClose={() => setIsEditOpen(false)}
              isLoading={isUpdatingStep}
              projectId={step.project_id}
              step={step}
              handleSave={async (updatedStep) =>
                updateStep({ ...updatedStep, entityId, entityType })
                  .unwrap()
                  .then(() => setIsEditOpen(false))
              }
            />
          ) : (
            <Grid container item>
              <Grid container item alignItems={'flex-start'} xs={12}>
                <Grid container item alignItems={'center'} xs="auto">
                  <DisplayWithEditAccess>
                    <Box display={'flex'} {...provided.dragHandleProps}>
                      <DragIndicator color={'action'} />
                    </Box>
                  </DisplayWithEditAccess>
                  <Checkbox
                    checked={complete}
                    disabled={isUpdatingStep || !canEdit}
                    onChange={handleUpdateComplete}
                  />
                </Grid>
                <Grid container item xs alignItems={'center'} m="auto">
                  <Tooltip enterDelay={'500'} enterNextDelay={'500'} title={'Double click to edit'}>
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      onDoubleClick={() => setIsEditOpen(true)}
                    >
                      {step?.name}
                    </Typography>
                  </Tooltip>
                </Grid>
                <DisplayWithEditAccess>
                  <Grid
                    item
                    alignItems={'flex-start'}
                    justifyContent={'flex-end'}
                    ml="auto"
                    xs={'auto'}
                  >
                    <StepMoreOption
                      onDelete={() => setIsDeleteDialogOpen(true)}
                      onEdit={() => setIsEditOpen(true)}
                    />
                  </Grid>
                </DisplayWithEditAccess>
              </Grid>
              {step?.companies?.length || step?.due_date ? (
                <Grid container item ml={3} spacing={1} xs={12}>
                  {step?.companies?.length ? (
                    <Grid container item gap={0.5} md={8} xs={12}>
                      <FormLabel> Companies:</FormLabel>
                      <Typography>
                        {step?.companies?.map((company) => company?.name).join(', ')}
                      </Typography>
                    </Grid>
                  ) : null}
                  {step?.due_date ? (
                    <Grid container item gap={0.5} md={4} xs={12}>
                      <FormLabel>Due date:</FormLabel>
                      <Typography>{step?.due_date}</Typography>
                    </Grid>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          )}
          {isDeleteDialogOpen && (
            <ConfirmDeleteDialog
              handleClose={() => setIsDeleteDialogOpen(false)}
              handleDelete={handleDelete}
              item={'step'}
              open={isDeleteDialogOpen}
            />
          )}
        </Grid>
      )}
    </Draggable>
  );
};
