import SettingIcon from '@blackhyve/common/src/icons/Setting';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { ColorSelect } from 'components/form/ColorSelect';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import format from 'date-fns/format';
import { StyledInlineDatePicker, StyledLabel } from 'features/drawers/utils/drawerStyle';
import {
  ActionButtons,
  DateValue,
  LabelValue,
  SectionHeading,
} from 'features/gantt/components/TaskDetailDrawer';
import { useEffect, useMemo, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import { parseISO } from 'date-fns';
import { StyledColorBox } from 'features/drawers/utils/drawerStyle';

const constraintOptions = {
  asap: 'As Soon As Possible',
  alap: 'As Late As Possible',
  snet: 'Start No Earlier Than',
  snlt: 'Start No Later Than',
  fnet: 'Finish No Earlier Than',
  fnlt: 'Finish No Later Than',
  mso: 'Must Start On',
  mfo: 'Must Finish On',
};

/**
 * General Setting Row
 * @param {String} taskId
 * @param {String} ganttId
 * @param {Object} handleSave
 * @returns
 */
const SettingRow = ({ taskId, handleSave, ganttId, disableEdit }) => {
  const gantt = ganttStore.getGantt(ganttId);
  const task = gantt.getTask(taskId);
  // const isParent = gantt.getTask(taskId).type === gantt.config.types.project;
  // const task = useSelector((state) => selectTaskById(state, taskId));
  const invalidConstraintForDates = ['asap', 'alap'];
  const initialState = useMemo(
    () => ({
      constraint_type: task?.constraint_type?.toLowerCase() || 'asap',
      constraint_date: task?.constraint_date || '',
      total_float: task?.total_float,
      free_float: task?.free_float,
      project_id: task.project_id,
      // is_scope: task?.is_scope,
      color: task?.task_color,
    }),
    [task]
  );

  const [isEditing, setIsEditing] = useState(false);
  const [showConstraintDate, setShowConstraintDate] = useState(true);
  const [formState, setFormState] = useState(initialState);

  const handleStartEditing = () => {
    // if (task.status === 'complete') {
    //   gantt.message({
    //     type: 'warning',
    //     text: 'You can not edit a complete task.',
    //   });
    //   return false;
    // }
    setIsEditing(true);
  };

  const handleCloseEditing = () => {
    setFormState(initialState);
    setIsEditing(false);
    invalidConstraintForDates.includes(initialState.constraint_type)
      ? setShowConstraintDate(false)
      : setShowConstraintDate(true);
  };

  const handleToggleScope = (event) => {
    setFormState({ ...formState, is_scope: !formState.is_scope });
  };

  const handleOnConstraintChange = (event) => {
    const updateState = { ...formState, [event.target.name]: event.target.value };
    if (invalidConstraintForDates.includes(updateState.constraint_type)) {
      updateState['constraint_date'] = null;
      setShowConstraintDate(false);
    } else {
      updateState['constraint_date'] = parseISO(format(new Date(), 'yyyy-MM-dd'));
      setShowConstraintDate(true);
    }
    setFormState(updateState);
  };

  const handleDateChange = (name, value) => {
    setFormState({ ...formState, [name]: parseISO(format(value, 'yyyy-MM-dd')) });
  };

  const handleSaveColor = (event) => {
    setFormState({ ...formState, color: event.target.value });
  };

  const handleUpdate = () => {
    handleSave({ ...formState });
    setIsEditing(false);
  };

  useEffect(() => {
    setFormState(initialState);
    invalidConstraintForDates.includes(initialState.constraint_type)
      ? setShowConstraintDate(false)
      : setShowConstraintDate(true);
  }, [initialState]);

  return (
    <>
      <SectionHeading
        disabled={disableEdit}
        handleEditing={handleStartEditing}
        isEditing={isEditing}
        title="Settings"
      >
        <SettingIcon style={{ marginRight: 5 }} />
      </SectionHeading>

      {!isEditing ? (
        <Grid container item spacing={1} xs={12}>
          {/* <Grid container item xs={12}>
            <Grid item xs={4}>
              <StyledLabel>Convert to Scope</StyledLabel>
            </Grid>
            <Grid item xs={4}>
              <StyledSwitch
                disabled
                checked={formState.is_scope}
                inputProps={{ 'aria-label': 'Switch demo' }}
                name="is_scope"
                size="small"
              />
            </Grid>
          </Grid> */}
          <Grid container item xs={8}>
            <Grid item xs={7}>
              <StyledLabel>Constraint Type</StyledLabel>
            </Grid>
            <Grid item xs={5}>
              <LabelValue value={formState?.constraint_type} />
            </Grid>
          </Grid>
          <Grid container item xs={4}>
            <Grid item xs={5}>
              <StyledLabel>Date</StyledLabel>
            </Grid>
            <Grid item xs={7}>
              <DateValue date={formState?.constraint_date} />
            </Grid>
          </Grid>
          <Grid container item xs={8}>
            <Grid item xs={7}>
              <StyledLabel>Total Float</StyledLabel>
            </Grid>
            <Grid item xs={5}>
              <LabelValue value={formState?.total_float} />
            </Grid>
          </Grid>
          <Grid container item xs={4}>
            <Grid item xs={7}>
              <StyledLabel>Free Float</StyledLabel>
            </Grid>
            <Grid item xs={5}>
              <LabelValue value={formState?.free_float} />
            </Grid>
          </Grid>
          <Grid container item xs={8}>
            <Grid item xs={7}>
              <StyledLabel>Color</StyledLabel>
            </Grid>
            <Grid item xs={5}>
              <StyledColorBox background={formState?.color} />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container item spacing={1} xs={12}>
          {/* <Grid container item xs={12}>
            <Grid item xs={4}>
              <StyledLabel>Convert to Scope</StyledLabel>
            </Grid>
            <Grid item xs={4}>
              <StyledSwitch
                checked={Boolean(formState.is_scope)}
                disabled={isParent}
                name="is_scope"
                size="small"
                onClick={handleToggleScope}
              />
            </Grid>
          </Grid> */}
          <Grid container item alignItems="center" xs={12}>
            <Grid item xs={4}>
              <StyledLabel>Constraint Type</StyledLabel>
            </Grid>
            <Grid item xs={8}>
              <FormControl size="small">
                <Select
                  fullWidth
                  disableUnderline={true}
                  input={<OutlinedInput margin="dense" />}
                  name="constraint_type"
                  value={formState.constraint_type}
                  onChange={handleOnConstraintChange}
                >
                  {Object.keys(constraintOptions).map((key) => {
                    return <MenuItem value={key}>{constraintOptions[key]}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {showConstraintDate && (
            <Grid container item alignItems="center" xs={12}>
              <Grid item xs={4}>
                <StyledLabel>Constraint Date</StyledLabel>
              </Grid>
              <Grid item xs={8}>
                <StyledInlineDatePicker
                  format="MM/dd/yyyy"
                  slotProps={{ textField: { size: 'small' } }}
                  value={formState.constraint_date}
                  variant="inline"
                  onChange={(value) => handleDateChange('constraint_date', value)}
                />
              </Grid>
            </Grid>
          )}
          <Grid container item alignItems="center" xs={12}>
            <Grid item xs={4}>
              <StyledLabel>Select Color</StyledLabel>
            </Grid>
            <Grid item xs={2}>
              <ColorSelect
                fullWidth
                margin={'dense'}
                size={'small'}
                value={formState.color}
                onChange={handleSaveColor}
              />
            </Grid>
          </Grid>
          <ActionButtons handleClose={handleCloseEditing} handleUpdate={handleUpdate} />
        </Grid>
      )}
    </>
  );
};

export default SettingRow;
