import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import Check from '@mui/icons-material/Check';
import { List, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material';
import { MenuButton } from 'assets/style-components/button';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { registerMenuState, updateMenuState } from 'slices/customViewSlice';
import ganttStore from '../../../components/projectOverview/gantt/ganttConfig/ganttStore';
import { useGetProjectsQuery } from 'features/projects/store/project.api';
import { selectCurrentUserWorkspaceId } from 'features/auth';

const defaultColor = '#757575';

const displayFunctions = {
  Default: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: false, type: 'location' },
    displayCriticalPath: false,
    displayCompany: false,
    displayTag: false
  },
  Task: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: true, type: 'task' },
    displayCriticalPath: false,
    displayCompany: false,
    displayTag: false
  },
  'Critical Path': {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: false, type: 'location' },
    displayCriticalPath: true,
    displayCompany: false,
    displayTag: false
  },
  Pace: {
    displayTaskPace: true,
    displayFloat: false,
    displayType: { visible: false, type: 'location' },
    displayCriticalPath: false,
    displayCompany: false,
    displayTag: false
  },
  Float: {
    displayTaskPace: false,
    displayFloat: true,
    displayType: { visible: false, type: 'location' },
    displayCriticalPath: false,
    displayCompany: false,
    displayTag: false
  },
  Location: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: true, type: 'location' },
    displayCriticalPath: false,
    displayCompany: false,
    displayTag: false
  },
  Zone: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: true, type: 'zone' },
    displayCriticalPath: false,
    displayCompany: false,
    displayTag: false
  },
  Area: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: true, type: 'area' },
    displayCriticalPath: false,
    displayCompany: false,
    displayTag: false
  },
  Project: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: true, type: 'project' },
    displayCriticalPath: false,
    displayCompany: false,
    displayTag: false
  },
  Company: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: true, type: 'company' },
    displayCriticalPath: false,
    displayCompany: false,
    displayTag: false
  },
  SmartTag: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: true, type: 'smartTag' },
    displayCriticalPath: false,
    displayCompany: false,
    displayTag: true
  },
  Tag: {
    displayTaskPace: false,
    displayFloat: false,
    displayType: { visible: true, type: 'tag' },
    displayCriticalPath: false,
    displayCompany: false,
    displayTag: true
  }
};

const ColorMenu = ({ ganttId, options, projectId }) => {
  const gantt = ganttStore.getGantt(ganttId);
  const selectedOption = useSelector((state) => state.customViews?.menus?.color?.currentValue);
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { data: projects = [] } = useGetProjectsQuery({ workspaceId });

  const [anchorEl, setAnchorEl] = useState(null);
  const state = useStore().getState();
  const dispatch = useDispatch();

  const setDisplayCriticalPath = (isEnabled) => {
    gantt.config.show_critical_path = isEnabled;
    gantt.render();
  };

  const setDisplayTaskPace = (isEnabled) => {
    gantt.config.highlight_task_pace = isEnabled;
    gantt.render();
  };

  const setDisplayFloat = (isEnabled) => {
    gantt.config.show_slack = isEnabled;
    gantt.render();
  };

  const setDisplayType = (isEnabled, type) => {
    if (isEnabled) {
      const getTaskColorFunction = getColorFunction({ type, gantt, projects, defaultColor });
      gantt.detachEvent('colorTasks');
      gantt.attachEvent(
        'onBeforeTaskDisplay',
        function (id, task) {
          task.$color = getTaskColorFunction(task);
          return true;
        },
        { id: 'colorTasks' }
      );
    } else {
      gantt.detachEvent('colorTasks');
      gantt.batchUpdate(() => {
        gantt.eachTask((task) => {
          task.$color = undefined;
        });
      });
    }
    gantt.render();
  };

  const setCompanyColor = (isEnabled) => {
    gantt.config.show_company_color = isEnabled;
    gantt.refreshData();
  };

  const setTagColor = (isEnabled) => {
    gantt.config.show_tag_color = isEnabled;
    gantt.refreshData();
  }

  const stateUpdateFunctions = useMemo(
    () => ({
      displayTaskPace: setDisplayTaskPace,
      displayFloat: setDisplayFloat,
      displayCriticalPath: setDisplayCriticalPath,
      displayType: setDisplayType,
      displayCompany: setCompanyColor,
      displayTag: setTagColor
    }),
    []
  );

  const handleSelectOption = (event, option) => {
    dispatch(updateMenuState({ name: 'color', value: option }));
  };

  useEffect(() => {
    if (selectedOption) {
      const displayConfig = displayFunctions[selectedOption];
      Object.entries(displayConfig).forEach(([key, value]) => {
        const functionName = stateUpdateFunctions[key];
        key === 'displayType' ? functionName(value.visible, value.type) : functionName(value);
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    dispatch(registerMenuState({ name: 'color', value: 'Default' }));
  }, [dispatch]);

  useEffect(() => {
    gantt?.attachEvent(
      'onSetColorOption',
      function (option) {
        handleSelectOption(undefined, option);
      },
      { id: 'onSetColorOption' }
    );
    return () => {
      gantt?.detachEvent('onSetColorOption');
    };
  }, [gantt]);

  return (
    <>
      <MenuButton
        endIcon={Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}
        isHighlighted={selectedOption !== 'Default'}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Color
      </MenuButton>
      {anchorEl !== null ? (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={Boolean(anchorEl)}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setAnchorEl(null)}
        >
          <List dense>
            {options.map((option) => {
              const selected = option == selectedOption;
              return (
                <ListItemButton key={option} onClick={(event) => handleSelectOption(event, option)}>
                  <ListItemIcon>{selected ? <Check /> : null}</ListItemIcon>
                  <ListItemText primary={option} />
                </ListItemButton>
              );
            })}
          </List>
        </Popover>
      ) : null}
    </>
  );
};


const getColorFunction = ({ type, gantt, projects, defaultColor }) => {
  const { areas, zones, locations } = gantt?.constants?.lbsData;
  const allTags = gantt?.constants?.tags

  const colorFunctions = {
    location: task => getColorFromEntity(locations, task?.location_id, defaultColor),
    zone: task =>
      getColorFromEntity(zones, task?.zone_id,
        getColorFromEntity(locations, task?.location_id, defaultColor)),
    area: task =>
      getColorFromEntity(areas, task?.area_id,
        getColorFromEntity(zones, task?.zone_id,
          getColorFromEntity(locations, task?.location_id, defaultColor))),
    project: task => getProjectColor(task, { projects, defaultColor }),
    task: task => task.task_color,
    company: task => getCompanyColor(task, { defaultColor }),
    tag: task => getTagColor(task, { defaultColor }),
    smartTag: task => getSmartTagColor(task, { allTags, defaultColor }),
  };

  return colorFunctions[type] || (() => defaultColor);
};


const generateGradient = (items, getColor) =>
  `linear-gradient(to right, ${items
    .map((item, index, array) => {
      const start = (index / array.length) * 100;
      const end = ((index + 1) / array.length) * 100;
      return `${getColor(item)} ${start}% ${end}%`;
    })
    .join(', ')})`;


const getColorFromEntity = (entity, id, defaultColor) =>
  entity?.[id]?.color || defaultColor;


const getProjectColor = (task, { projects, defaultColor }) => {
  const projectEntities = projects.reduce((acc, project) => {
    acc[project.id] = project;
    return acc;
  }, {});

  return projectEntities[task?.project_id]?.color || defaultColor;
}

const getCompanyColor = (task, { defaultColor }) => {
  return task?.companies?.length
    ? generateGradient(task.companies, company => company?.color || defaultColor)
    : defaultColor;
}

const getTagColor = (task, { defaultColor }) => {
  const filteredTags = task?.tags?.filter(
    (tag) => tag.type === "standard" || tag.type === "cascading");

  return filteredTags?.length
    ? generateGradient(filteredTags, tag => tag?.color || defaultColor)
    : defaultColor;
}

const getSmartTagColor = (task, { allTags, defaultColor }) => {

  if (!task?.tags?.length) {
    return defaultColor
  }

  const smartTags = [];
  const generatedTags = [];

  task?.tags.forEach(tag => {
    if (tag.type === "smart") smartTags.push(tag);
    else if (tag.type === "generated") generatedTags.push(tag);
  });

  // Searching the generated tags of the smart tag
  const generatedTagsOfSmartTags = smartTags.flatMap(smartTag =>
    allTags?.filter(
      tag => tag.smart_tag_id === smartTag.id && tag.smart_task_id === task.id
    ) || []
  );

  const mergedTags = [...generatedTagsOfSmartTags, ...generatedTags];

  // Restoring the original order based on task tags priority
  const colorTags = task.tags
    .map((originalTag) =>
      mergedTags.find((tag) => tag.id === originalTag.id || tag.smart_tag_id === originalTag.id)
    ).filter(Boolean);

  return colorTags?.length
    ? generateGradient(colorTags, tag => tag?.color || defaultColor)
    : defaultColor;

}

export default ColorMenu;
