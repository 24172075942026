import { DirectionsWalkOutlined, MoreVert, NavigateNext } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import ConfirmDeleteDialog from 'components/common/popovers/ConfirmDeleteDialog';
import { Popover } from 'components/Popover';
import { JobwalkDialog } from 'features/jobwalk/components/JobwalkDialog';
import { memo, useState } from 'react';
import { useDeleteTargetMutation } from '../api/target.api';
import GanttIcon from '@blackhyve/common/src/icons/GanttIcon';
import { Link as RouterLink, useParams } from 'react-router-dom';

const dialogs = {
  JOBWALK: 'jobwalk',
  DELETE: 'delete',
};

export const TargetMoreOptionsMenu = memo(({ target }) => {
  const { workspaceId } = useParams();
  const [deleteTarget] = useDeleteTargetMutation();

  const [openDialog, setOpenDialog] = useState();

  const onJobwalkSuccess = () => {};

  const handleCloseDialog = () => {
    setOpenDialog(undefined);
  };

  return (
    <>
      <Popover
        trigger={
          <IconButton>
            <MoreVert />
          </IconButton>
        }
      >
        <MenuList>
          <MenuItem
            component={RouterLink}
            to={`/workspace/${workspaceId}/projects/${target.task.project_id}/production-board/tasks/${target.task.id}/details`}
          >
            <ListItemIcon>
              <NavigateNext />
            </ListItemIcon>
            <ListItemText primary={'Task Details'} />
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to={`/workspace/${workspaceId}/projects/${target.task.project_id}/planning-board/gantt?task=${target.task.id}`}
          >
            <ListItemIcon>
              <GanttIcon />
            </ListItemIcon>
            <ListItemText primary={'Show On Gantt'} />
          </MenuItem>
          <MenuItem onClick={() => setOpenDialog(dialogs.JOBWALK)}>
            <ListItemIcon>
              <DirectionsWalkOutlined />
            </ListItemIcon>
            <ListItemText primary={'Jobwalk'} />
          </MenuItem>
          {/* <Divider /> */}
          {/* <MenuItem>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText primary={'Edit'} />
          </MenuItem>
          <MenuItem onClick={() => setOpenDialog(dialogs.DELETE)}>
            <ListItemIcon>
              <Delete color={'error'} />
            </ListItemIcon>
            <ListItemText
              color={'error'}
              primary={'Delete'}
              sx={{ color: (theme) => theme.palette.error.main }}
            />
          </MenuItem> */}
        </MenuList>
      </Popover>
      <JobwalkDialog
        handleClose={handleCloseDialog}
        handleUpdate={handleCloseDialog}
        open={openDialog === dialogs.JOBWALK}
        projectId={target?.task?.project_id}
        shouldAutoschedule={true}
        taskId={target?.task?.id}
      />
      <ConfirmDeleteDialog
        handleClose={handleCloseDialog}
        handleDelete={() => deleteTarget({ targetId: target.id })}
        open={openDialog === dialogs.DELETE}
      />
    </>
  );
});
