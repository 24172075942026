import { Delete, Edit, MoreVert } from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Popover } from 'components/Popover';

export const StepMoreOption = ({ onDelete, onEdit }) => {
  return (
    <Popover
      trigger={
        <IconButton>
          <MoreVert />
        </IconButton>
      }
    >
      {({ handleClose }) => (
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton
              dense
              onClick={() => {
                handleClose();
                onEdit();
              }}
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              dense
              onClick={() => {
                handleClose();
                onDelete();
              }}
            >
              <ListItemIcon style={{ color: 'red' }}>
                <Delete color={'inherit'} />
              </ListItemIcon>
              <ListItemText sx={{ color: 'red' }}>Delete</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      )}
    </Popover>
  );
};
