import { StyledDialog } from '@blackhyve/common';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { usePublishWeeklyWorkPlanMutation } from '../api/target.api';
import { format, startOfWeek } from 'date-fns';
import { PublishWeeklyWorkPlanForm } from './PublishWeeklyWorkPlanForm';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

export const PublishWeeklyWorkPlanDialog = ({
  taskId,
  projectId,
  handleClose: _handleClose,
  open,
  date: _date,
  disableChangeDate = false,
}) => {
  const [publishWwp, { isLoading: isPublishingTargets }] = usePublishWeeklyWorkPlanMutation();

  const [date, setDate] = useState(startOfThisWeek);

  const form = useForm();

  const onSubmit = async (formValues) => {
    const changedTargets = form.formState.dirtyFields.targets
      ? Object.keys(form.formState.dirtyFields.targets).map((formTargetId) => ({
          id: formTargetId.slice(7),
          percent_complete: formValues.targets[formTargetId],
        }))
      : [];

    publishWwp({
      ...formValues,
      date: format(_date || date, 'yyyy-MM-dd'),
      projectId,
      parentId: taskId,
      targets: changedTargets,
    })
      .unwrap()
      .then((result) => handleClose(false));
  };

  const handleClose = () => {
    form.reset({ value: {} });
    _handleClose();
  };

  return (
    <StyledDialog
      handleClose={handleClose}
      maxWidth={'md'}
      open={open}
      PaperProps={{ sx: { height: '100%' } }}
      title={'Publish Targets'}
      actions={
        <>
          <Button disabled={isPublishingTargets} onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            color={'primary'}
            loading={isPublishingTargets}
            variant={'contained'}
            onClick={() => form.handleSubmit(onSubmit)()}
          >
            Publish
          </LoadingButton>
        </>
      }
    >
      <PublishWeeklyWorkPlanForm
        date={_date || date}
        disableChangeDate={disableChangeDate}
        form={form}
        projectId={projectId}
        setDate={setDate}
        taskId={taskId}
        onSubmit={onSubmit}
      />
    </StyledDialog>
  );
};
