import { endOfWeek, isBefore } from 'date-fns';
import { useMemo } from 'react';

export const useDueDate = ({ scheduledEndDate, publishedOnDate }) => {
  return useMemo(() => {
    const endOfWeekDate = endOfWeek(publishedOnDate, { weekStartsOn: 1 });

    return isBefore(endOfWeekDate, scheduledEndDate) ? endOfWeekDate : scheduledEndDate;
  }, [scheduledEndDate, publishedOnDate]);
};
