import { ganttUtils } from '@blackhyve/utilities';
import gantt from '@blackhyve/utilities/gantt';
import {
  linkHasCircularDependency,
  taskHasCircularDependency,
} from '@blackhyve/utilities/gantt/hasCircularDependencies';
import { getConnectedGroup } from '@blackhyve/utilities/tasks';
import { addDays, differenceInDays, isDate, isValid, startOfDay, subDays } from 'date-fns';
import { autoUpdateLag } from 'features/gantt/helpers/autoUpdateLag';
import { dndCustomDates } from 'features/gantt/helpers/dndCustomDates';
import { maintainDisplayOrder } from 'features/gantt/helpers/maintainDisplayOrder';

import initTooltip from 'features/planningBoard/utils/tooltip';

const sevenDaysInMS = 604800000;

let isLinkAdding = false;
let linkAddCommands = [];

function isAValidDuration(calendarDays) {
  if (calendarDays > 0 && calendarDays !== null && calendarDays !== undefined) {
    return true;
  }
  return false;
}

function highlightConnectedGroup(group, gantt, clearSelected = true) {
  if (clearSelected) {
    gantt.eachTask((task) => (task.$isHighlighted = false));
    gantt.getLinks().forEach((link) => (link.$isHighlighted = false));
  }
  group?.tasks?.forEach((taskId) => (gantt.getTask(taskId).$isHighlighted = true));
  group?.links?.forEach((linkId) => (gantt.getLink(linkId).$isHighlighted = true));
  gantt.refreshData();
}

// Reverse the commands within each type group while maintaining the order of operations
function reverseCommands(commands) {
  const reversedCommands = [];
  let currentGroup = [];
  let currentType = commands[0]?.type;
  let currentEntity = commands[0]?.entity;

  commands.forEach((command) => {
    if (command.type !== currentType || command.entity !== currentEntity) {
      reversedCommands.push(...currentGroup.reverse());
      currentGroup = [];
      currentType = command.type;
      currentEntity = command.entity;
    }
    currentGroup.push(command);
  });
  reversedCommands.push(...currentGroup.reverse());

  return reversedCommands;
}

export function getCalendarDays(workdayCalendar) {
  if (workdayCalendar) {
    return [
      Object.hasOwn(workdayCalendar, 'sunday') ? parseInt(workdayCalendar?.sunday) : 0,
      Object.hasOwn(workdayCalendar, 'monday') ? parseInt(workdayCalendar?.monday) : 1,
      Object.hasOwn(workdayCalendar, 'tuesday') ? parseInt(workdayCalendar?.tuesday) : 1,
      Object.hasOwn(workdayCalendar, 'wednesday') ? parseInt(workdayCalendar?.wednesday) : 1,
      Object.hasOwn(workdayCalendar, 'thursday') ? parseInt(workdayCalendar?.thursday) : 1,
      Object.hasOwn(workdayCalendar, 'friday') ? parseInt(workdayCalendar?.friday) : 1,
      Object.hasOwn(workdayCalendar, 'saturday') ? parseInt(workdayCalendar?.saturday) : 0,
    ];
  } else {
    return [0, 1, 1, 1, 1, 1, 0];
  }
}

// Gantt isLinkAllowed does not take lag into consideration
export function isLinkAllowedWithLag(link, gantt) {
  if (!link) return false;
  if (!(link.source && link.target && link.type)) return false;
  if (link.source === link.target) return false;

  const source = gantt.getTask(link.source);
  const taskLinks = source.$source;

  for (let i = 0; i < taskLinks.length; i++) {
    const existingLink = gantt.getLink(taskLinks[i]);
    const sourceMatch = link.source == existingLink.source;
    const targetMatch = link.target == existingLink.target;
    const typeMatch = link.type == existingLink.type;
    const lagMatch = link.lag == existingLink.lag;

    if (sourceMatch && targetMatch && typeMatch && lagMatch) {
      return false;
    }
  }
  return true;
}

export const taskGanttEvents = [
  ganttUtils.taskSchedulerEvents,
  {
    onGanttReady: function () {
      const el = document.querySelector('.gantt_grid');
      if (el) {
        el.addEventListener('scroll', function () {
          document.documentElement.style.setProperty(
            '--gantt-frozen-column-scroll-left',
            el.scrollLeft + 'px'
          );
        });
      }
      // Adding calendar
      const projects = this.constants?.projects;
      if (this.config.work_time && projects?.length) {
        projects?.forEach((project) => {
          const calendarId = projects.length === 1 ? 'global' : `${project.id}-calendar`;
          const calendar = {
            id: calendarId,
            days: getCalendarDays(project.workday_calendar),
            holidays: project?.holidays,
          };
          ganttUtils.addGanttCalendar(calendar, this);
        });
      }
      // autoUpdateForecastedDates(this);
      // projectCriticalPath(this);
      dndCustomDates(this);
      autoUpdateLag(this);
      maintainDisplayOrder(this);
      // autoUpdateForecastedDates(this);
      // autoUpdateParentTaskType(this);
      // autoUpdateParentStatusPR(this);
      initTooltip(this);
      if (this.config.sort) {
        this._sort = { name: '$display_order', direction: 'asc' };
        this.sort('$display_order');
      }
      this.scrollTo(this.posFromDate(startOfDay(subDays(new Date(), 1))));
    },
    onTaskLoading: function (task) {
      // task.end_date = task.end_date
      //   ? task.end_date instanceof Date
      //     ? task.end_date
      //     : parseDate(task.end_date)
      //   : undefined;
      if (this.config.project_bar && task.parent === 0 && task.id != -1) {
        task.parent = -1;
      }
      if (!task.cal_days) {
        task.cal_days = differenceInDays(task.scheduled_end_date, task.start_date);
      }

      if (this._minDate) {
        this._minDate = new Date(
          Math.min(
            +this._minDate,
            Math.min(task.forecasted_start_date, task.start_date) - sevenDaysInMS
          )
        );
      } else {
        this._minDate = new Date(
          Math.min(task.forecasted_start_date, task.start_date) - sevenDaysInMS
        );
      }
      if (this._maxDate) {
        this._maxDate = new Date(
          Math.max(
            +this._maxDate,
            Math.max(task.forecasted_end_date || -Infinity, task.scheduled_end_date || -Infinity) +
              sevenDaysInMS
          )
        );
      } else {
        this._maxDate = new Date(
          Math.max(task.forecasted_end_date || -Infinity, task.scheduled_end_date || -Infinity) +
            sevenDaysInMS
        );
      }

      // console.log(this);
      // if (this.config.work_time) {
      //   // @TODO utilize lock_workdays to specify if calender should deviate from the projects
      //   if (task.workday_calendar) {
      //     let calendarId = this.addCalendar({
      //       id: `${task.id}-calendar`,
      //       worktime: {
      //         days: getCalendarDays(task.workday_calendar),
      //       },
      //     });
      //     task.calendar_id = calendarId;
      //   }
      // }
      return true;
    },
    onParse: function () {
      const gantt = this;
      if (gantt.ext.undo) {
        gantt.clearUndoStack();
        gantt.clearRedoStack();
        // Clear undo stack on parse
        gantt.attachEvent(
          'onBeforeUndoStack',
          function () {
            gantt.clearUndoStack();
            gantt.clearRedoStack();
            return false;
          },
          { once: true }
        );
      }
    },
    onBeforeDataRender: function () {
      if (
        isDate(this._minDate) &&
        isDate(this._maxDate) &&
        isValid(this._minDate) &&
        isValid(this._maxDate)
      ) {
        if (+this._minDate !== +this.config.start_date) {
          this.config.start_date = this._minDate || new Date();
        }
        if (+this._maxDate !== +this.config.end_date) {
          this.config.end_date = this._maxDate || new Date();
        }
      }
    },
    onBeforeTaskDisplay: function (id, task) {
      return true;
    },
    onBeforeLightbox: function (id) {
      // return false;
      return true;
    },
    onTaskCreated: function (task) {
      if (task.type === this.config.types.placeholder) {
        const count = this.getTaskCount();
        task.text = 'New Task';
        task.placeholder = true;
        task.$wbs = -1;
        task.$display_order = count;
      }
      return true;
    },
    onBeforeTaskAdd: function (id, task) {
      task.project_id = task.project_id || (this.isTaskExists(-1) && this.getTask(-1).project_id);
      if (task.project_id) {
        task.start_date =
          task.start_date ||
          this.getClosestWorkTime({
            date: this.constants?.project?.start_date
              ? this.constants?.project?.start_date
              : startOfDay(Date.now()),
            dir: 'future',
          });

        task.end_date = task.end_date || addDays(task.start_date, 1);
        // Make sure dates are there
        task.forecasted_start_date = task.forecasted_start_date || task.start_date;
        task.forecasted_end_date = task.forecasted_end_date || task.end_date;
        task.scheduled_end_date = task.scheduled_end_date || task.end_date;

        // Ensure other fields are set
        task.status = task.status || 'todo';
        task.work_days = this.calculateDuration({
          start_date: task.start_date,
          end_date: task.scheduled_end_date,
          task,
        });
        task.cal_days = differenceInDays(task.scheduled_end_date, task.start_date);

        task.companies = task.companies || [];
        task.contacts = task.contacts || [];
        task.responsible_users = task.responsible_users || [];

        task.location_id = task.location_id || null;
        task.zone_id = task.zone_id || null;
        task.area_id = task.area_id || null;

        task.autoschedule_date = task.autoschedule_date || 'schedule';
        return true;
      } else {
        return false;
      }
    },
    onBeforeTaskUpdate: function (id, task) {
      if (task.$virtual && task.$group_id) {
        return false;
      }
      // if (task.effort_tag) {
      //   const projects = store.getState().projects.entities;
      //   const { budget, labor_percentage, labor_rate } = projects[task?.project_id] || {};
      //   if (budget && labor_percentage && labor_rate) {
      //     task.effort_hours = calculateEffortHours(
      //       budget,
      //       labor_percentage,
      //       labor_rate,
      //       EFFORT_TAG_PERCENTAGE[task.effort_tag]
      //     );
      //   }
      // }

      // if (task.status === 'todo' && !this.hasChild(id)) {
      //   task.scheduled_end_date = task.end_date;
      //   task.forecasted_end_date = task.end_date;
      //   task.forecasted_start_date = task.start_date;
      // }

      return true;
    },

    onAfterTaskUpdate: function (id, task) {
      if (this._minDate) {
        this._minDate = new Date(
          Math.min(
            +this._minDate,
            Math.min(task.forecasted_start_date, task.start_date) - sevenDaysInMS
          )
        );
      } else {
        this._minDate = new Date(
          Math.min(task.forecasted_start_date, task.start_date) - sevenDaysInMS
        );
      }
      if (this._maxDate) {
        this._maxDate = new Date(
          Math.max(
            +this._maxDate,
            Math.max(task.forecasted_end_date, task.scheduled_end_date) + sevenDaysInMS
          )
        );
      } else {
        this._maxDate = new Date(
          Math.max(task.forecasted_end_date, task.scheduled_end_date) + sevenDaysInMS
        );
      }
    },

    onAfterTaskDelete: function (taskId, task) {
      this.unselectTask(taskId);
    },

    onBeforeTaskMove: function (id, parentId, tindex) {
      const task = this.getTask(id);
      if (this?._sort?.name && this?._sort?.name !== '$display_order') {
        this.message({
          type: 'error',
          text: 'Cannot move tasks when a sort is applied',
        });
        return false;
      }
      if (this?._groups?.is_active()) {
        this.message({
          type: 'error',
          text: 'Cannot move tasks when a group by is selected',
        });
        return false;
      }
      if (taskHasCircularDependency({ ...task, parent: parentId }, this)) {
        this.message({
          type: 'error',
          text: 'Cannot move task as it would cause circular dependencies',
        });
        return false;
      }
      return true;
    },

    onTaskIdChange: function (id, newId) {
      this.getTask(newId).$new = false;
      // if (this.isSelectedTask(id)) {
      // this.unselect(id);
      this.selectTask(newId);
      // }
    },

    onBeforeTaskMultiSelect: function (id, state, event) {
      if (id && state === false && !event) {
        return true;
      }

      if (!event || event?.target?.id.includes('checkbox-')) {
        return true;
      } else {
        return false;
      }
    },

    onBeforeTaskDrag: function (id, mode, e) {
      let task = this.getTask(id);
      if (task.status === 'complete' || task?.dates_locked_by) {
        return false;
      }
      if (task.status === 'active') {
        if (mode === 'move' || (mode === 'resize' && this.getState().drag_from_start === true)) {
          return false;
        }
      }
      return true;
    },
    onBeforeRowDragMove: function (id, parent, tindex) {
      if (
        (this.config?.project_bar && parent === 0) ||
        this.getTask(id).status === 'complete' ||
        this.getTask(parent)?.type === 'task'
      ) {
        return false;
      }
      return true;
    },
    onGridHeaderClick: function (name, event) {
      if (this._sort?.name === name && this._sort?.direction === 'desc') {
        this._sort = { name: '$display_order', direction: 'asc' };
        this.sort('$display_order');
        return false;
      } else {
        return true;
      }
    },
    onLinkValidation: function (link) {
      let isValid = true;
      const sourceTask = this.getTask(link.source);
      const targetTask = this.getTask(link.target);

      if (sourceTask.project_id !== targetTask.project_id) {
        isValid = false;
      } else {
        isValid = !linkHasCircularDependency(link, this);
      }
      return isValid;
    },
    onBeforeLinkDelete: function (id, link) {
      return true;
    },
    onBeforeLinkAdd: function (id, link) {
      let isValid = true;
      const sourceTask = this.getTask(link.source);
      const targetTask = this.getTask(link.target);

      if (sourceTask.project_id !== targetTask.project_id) {
        isValid = false;
      } else {
        link.project_id = sourceTask?.project_id;
        isValid = !linkHasCircularDependency(link, this);
      }

      if (!isValid) {
        this.alert({
          title: 'Not allowed',
          type: 'alert-error',
          text: 'Link creates a loop, try another path.',
        });
        return false;
      } else {
        isLinkAdding = true;
        return true;
      }
    },
    onAutoScheduleCircularLink: function () {
      this.message({
        type: 'error',
        text: 'Circular dependencies found. Autoscheduling disabled',
      });
    },
    onAfterLinkAdd: function (id, item) {
      const sourceTask = this.getTask(item.source);
      if (sourceTask.$isHighlighted) {
        const group = getConnectedGroup(sourceTask.id, this);
        highlightConnectedGroup(group, this);
      }
      const targetTask = this.getTask(item.target);
      if (targetTask.$isHighlighted) {
        const group = getConnectedGroup(targetTask.id, this);
        highlightConnectedGroup(group, this);
      }
    },
    onAfterLinkDelete: function (id, item) {
      const sourceTask = this.getTask(item.source);
      const targetTask = this.getTask(item.target);

      if (sourceTask.$isHighlighted || targetTask.$isHighlighted) {
        const group = getConnectedGroup(this.getSelectedTasks()[0], this);
        highlightConnectedGroup(group, this);
      }
    },
    onMultiSelect: function () {
      console.log(this.getSelectedTasks());
      const selectedTasks = this.getSelectedTasks();
      if (selectedTasks.length === 1) {
        if (!this.getTask(selectedTasks[0]).$isHighlighted) {
          const group = getConnectedGroup(selectedTasks[0], this);
          highlightConnectedGroup(group, this);
          this.highlightConnectedGroup = true;
        }
      } else if (this.highlightConnectedGroup === true) {
        highlightConnectedGroup(undefined, this);
        this.highlightConnectedGroup = false;
      }
    },
    onBeforeRedoStack: function (action) {
      // Reverse commands within each type to ensure correct undo/redo behavior when multiple commands affect the same task in a single action
      action.commands = reverseCommands(action.commands);
      return true;
    },
    onBeforeUndoStack: function (action) {
      // Block action if only command is updating project bar
      if (action.commands.length === 1 && action.commands[0].value.type === 'project_bar') {
        return false;
      }
      if (isLinkAdding) {
        linkAddCommands.push(...action.commands);
        return false;
      } else if (linkAddCommands.length > 0) {
        action.commands.push(...linkAddCommands);
        linkAddCommands = [];
      }
      // Reverse commands within each type to ensure correct undo/redo behavior when multiple commands affect the same task in a single action
      action.commands = reverseCommands(action.commands);
      return true;
    },
    onBeforeRedo: function (action) {
      // Block redo when sort is applied and action.commands includes move
      if (
        (this._sort.name !== '$display_order' || this?._groups?.is_active()) &&
        action.commands.some((command) => command.type === 'move')
      ) {
        this.message({
          type: 'error',
          text: 'Cannot redo move action while a sort/group is active',
        });
        this.getRedoStack().push(action);
        return false;
      } else {
        this.attachEvent(
          'onBeforeUndoStack',
          function () {
            if (gantt._block_auto_schedule === true) {
              gantt._block_auto_schedule = false;
            }
          },
          { once: true }
        );
        gantt._block_auto_schedule = true;
        return true;
      }
    },

    onBeforeUndo: function (action) {
      // Block undo when sort is applied and action.commands includes move
      if (
        (this._sort.name !== '$display_order' || this?._groups?.is_active()) &&
        action.commands.some((command) => command.type === 'move')
      ) {
        this.message({
          type: 'error',
          text: 'Cannot undo move action while a sort/group is active',
        });
        this.getUndoStack().push(action);
        return false;
      } else {
        this.attachEvent(
          'onBeforeRedoStack',
          function () {
            if (gantt._block_auto_schedule === true) {
              gantt._block_auto_schedule = false;
            }
          },
          { once: true }
        );
        gantt._block_auto_schedule = true;
        return true;
      }
    },
    onFinishAutoSchedule: function () {
      if (isLinkAdding) {
        isLinkAdding = false;
      }
    },
  },
];

export const taskGanttInlineEvents = {
  onBeforeEditStart: function ({ id, columnName, event, ...reset }) {
    const task = this.getTask(id);
    switch (columnName) {
      case 'start_date':
        if ((task.status !== 'todo' && !this.hasChild(id)) || task?.dates_locked_by) {
          return false;
        }
        break;
      case 'scheduled_end_date':
        if ((task.status !== 'todo' && !this.hasChild(id)) || task?.dates_locked_by) {
          return false;
        }
        break;
      case 'forecasted_end_date':
        return false;
      case 'forecasted_start_date': {
        return false;
      }
      case 'predecessors': {
        break;
      }
      case 'work_days': {
        if (task.status !== 'todo' || this.hasChild(id) || task?.dates_locked_by) {
          return false;
        }
        break;
      }
      case 'cal_days': {
        if (task.status !== 'todo' || this.hasChild(task.id) || task?.dates_locked_by) {
          return false;
        }
        break;
      }
      case 'constraint_date': {
        if (task.type === 'placeholder') {
          return false;
        }
        break;
      }
      default:
        return true;
    }
  },
  onBeforeSave: function (state) {
    let task = this.getTask(state.id);
    switch (state.columnName) {
      case 'start_date':
        if (task.status === 'todo' && !this.hasChild(task.id)) {
          const scheduledDuration = this.calculateDuration({
            start_date: task.start_date,
            end_date: task.scheduled_end_date,
            task: task,
          });
          task.scheduled_end_date = this.calculateEndDate({
            start_date: state.newValue,
            duration: scheduledDuration,
            task: task,
          });
        }
        break;
      case 'scheduled_end_date':
        if (task.status === 'todo' && !this.hasChild(task.id)) {
          task.end_date = state.newValue;
          task.forecasted_end_date = state.newValue;
        }
        if (
          task.status === 'active' &&
          task.autoschedule_date === 'schedule' &&
          !this.hasChild(task.id)
        ) {
          task.end_date = state.newValue;
        }
        break;
      case 'cal_days':
        if (!isAValidDuration(state.newValue)) {
          break;
        }
        // TODO: CHECK COMMITS CONFLICT ISSUE IN NEW UPDATE IT HAS GOT REMOVED
        // const newEnd = addDays(task.forecasted_start_date, state.newValue - 1);
        // const correctedEndDate = !this.isWorkTime({ date: newEnd, task: task })
        //   ? this.getClosestWorkTime({
        //       date: newEnd,
        //       task: task,
        //       dir: 'past',
        //     })
        //   : addDays(newEnd, 1);
        // task.end_date = correctedEndDate;
        task.end_date = addDays(task.forecasted_start_date, state.newValue);
        task.scheduled_end_date = task.end_date;
        task.forecasted_end_date = task.end_date;
        task.work_days = this.calculateDuration({
          start_date: task.start_date,
          end_date: task.scheduled_end_date,
          task: task,
        });
        break;
      case 'work_days':
        if (!isAValidDuration(state.newValue)) {
          break;
        }
        task.end_date = this.calculateEndDate({
          start_date: task.start_date,
          duration: state.newValue,
          task: task,
        });
        task.scheduled_end_date = task.end_date;
        task.forecasted_end_date = task.end_date;
        task.cal_days = differenceInDays(task.scheduled_end_date, task.start_date);
        break;
      default:
    }
    if (state.columnName !== 'constraint_type' || state.columnName !== 'constraint_date') {
      task.$keep_constraints = true;
    }
    return true;
  },
  onSave: function ({ id, columnName, oldValue, newValue }) {
    if (columnName === 'constraint_date' && oldValue !== newValue) {
      const task = this.getTask(id);
      task.constraint_date = newValue;
      // this.updateTask(id);
    }

    if (
      (this.autoSchedule &&
        (columnName === 'cal_days' ||
          columnName === 'work_days' ||
          columnName === 'constraint_type' ||
          columnName === 'constraint_date')) ||
      columnName === 'scheduled_end_date'
    ) {
      this.autoSchedule(id);
    }
  },
};
