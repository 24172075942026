import React, { useContext } from 'react';

export const EditContext = React.createContext(true);

export const useEditContext = () => useContext(EditContext);

/**
 * Only display if the user has edit access to the tab must have editContext
 * @returns {null|JSX.Element}
 */
export const DisplayWithEditAccess = (props) => {
  const canEdit = useContext(EditContext);
  if (canEdit) {
    return props.children;
  } else {
    return null;
  }
};

export default DisplayWithEditAccess;
