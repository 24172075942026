import { SvgIcon } from '@mui/material';

const SvgMeterIcon = (props) => (
  <SvgIcon viewBox={'0 0 16 16'} {...props}>
    <path
      d="M15.5 8.044a7.475 7.475 0 0 0-2.163-5.263.374.374 0 0 0-.074-.075A7.475 7.475 0 0 0 8 .544a7.476 7.476 0 0 0-5.263 2.162.378.378 0 0 0-.074.075A7.475 7.475 0 0 0 .5 8.044a7.45 7.45 0 0 0 2.196 5.303h.001v.001a.368.368 0 0 0 .522 0l.018-.02 1.136-1.136a.369.369 0 1 0-.521-.521l-.887.887a6.705 6.705 0 0 1-1.717-4.145h1.254a.369.369 0 1 0 0-.738H1.248a6.735 6.735 0 0 1 1.72-4.142l.884.884a.368.368 0 0 0 .521 0 .369.369 0 0 0 0-.521L3.49 3.01a6.735 6.735 0 0 1 4.142-1.72v1.255a.369.369 0 1 0 .738 0V1.292A6.735 6.735 0 0 1 12.51 3.01l-.884.885a.369.369 0 1 0 .521.521l.885-.884a6.736 6.736 0 0 1 1.72 4.142h-1.255a.369.369 0 1 0 0 .738h1.254a6.705 6.705 0 0 1-1.717 4.145l-.887-.887a.369.369 0 0 0-.521.521l1.155 1.155a.368.368 0 0 0 .521 0A7.45 7.45 0 0 0 15.5 8.044Z"
      fill={'currentColor'}
    />
    <path
      d="M9.694 3.814a.369.369 0 0 0-.48.205L8.15 6.68a1.368 1.368 0 0 0-1.373.742c-.246.483-.183 1.062.169 1.546a.6.6 0 0 0 .131.131c.286.208.606.318.924.318.52 0 .988-.288 1.224-.75.246-.484.183-1.062-.169-1.547a.6.6 0 0 0-.131-.131 1.801 1.801 0 0 0-.082-.055L9.9 4.294a.369.369 0 0 0-.206-.48ZM8.566 8.332A.633.633 0 0 1 8 8.679a.821.821 0 0 1-.472-.163c-.177-.255-.212-.53-.095-.76A.633.633 0 0 1 8 7.41c.077 0 .153.013.23.039.003 0 .005.002.007.003.008.004.017.006.025.009a.98.98 0 0 1 .21.112c.177.255.211.53.094.76Z"
      fill={'currentColor'}
    />
  </SvgIcon>
);

export default SvgMeterIcon;
