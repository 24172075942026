import {
  Avatar,
  Badge,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Assignment,
  Clear,
  Comment,
  Delete,
  Drafts,
  Markunread,
  Notifications,
  PendingActions,
} from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { selectCurrentUserRole, selectCurrentUserWorkspaceId } from 'features/auth';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { parseDate } from '@blackhyve/utilities/dates';
import StuckPointIcon from '@mui/icons-material/PanToolAlt';
import {
  useReadNotificationMutation,
  useUnreadNotificationMutation,
} from '../api/notification.api';
import { Label as LabelComponent } from 'features/labels/components/Label';

export const Notification = ({ notification, disabled }) => {
  const [isActionHovered, setIsActionHovered] = useState(false);
  const [readNotification, { isLoading: isReadLoading }] = useReadNotificationMutation();
  const [unreadNotification, { isLoading: isUnreadLoading }] = useUnreadNotificationMutation();
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const userRole = useSelector((state) => selectCurrentUserRole(state));

  const id = notification?.id;
  const audit = notification?.audit;
  const user = audit?.acting_user; // {firstName, lastName, avatar} who did the action
  const isRead = !!notification?.inbox_read_at;
  const task = audit?.task;
  const projectName = notification?.project_name;

  const notificationType = notification?.type;

  let header = '';
  let body = '';
  let toUrl = `/workspace/${workspaceId}/projects/${notification?.project_id}/planning-board/gantt?task=${task?.id}`;
  let badgeIcon = <Notifications fontSize={'inherit'} />;

  switch (notificationType) {
    case 'AssignedTask':
      header = `${user?.first_name || 'User'} added you to ${task?.name}`;
      badgeIcon = <Assignment fontSize={'inherit'} />;
      break;
    case 'UnassignedTask':
      header = `${user?.first_name || 'User'} removed you from ${task?.name}`;
      badgeIcon = <Assignment fontSize={'inherit'} />;
      toUrl = undefined;
      break;
    case 'TaskUpdated':
      header = `${user?.first_name || 'User'} updated ${task?.name}`;
      body = <ChangesList newValues={audit?.new_values} oldValues={audit?.old_values} />;
      break;
    case 'TaskStartDateChanged':
      header = `${user?.first_name || 'User'} updated ${task?.name} start date`;
      body = <ChangesList newValues={audit?.new_values} oldValues={audit?.old_values} />;
      break;
    case 'NewComment':
      header = `${user?.first_name || 'User'} commented on ${task?.name}`;
      body = <span dangerouslySetInnerHTML={{ __html: notification?.comment?.comment }} />;
      badgeIcon = <Comment fontSize={'inherit'} />;
      break;
    case 'TaskDeleted':
      header = `${user?.first_name || 'User'} deleted ${task?.name}`;
      badgeIcon = <Delete fontSize={'inherit'} />;
      toUrl = undefined;
      break;
    case 'NewStuckPoint':
      header = (
        <>
          {user?.first_name || 'User'} assigned a <LabelComponent labelKey="stuck_point" /> to you
          on {task?.name}
        </>
      );
      body = audit?.new_values?.title;
      badgeIcon = <PendingActions fontSize={'inherit'} />;
      toUrl = `/workspace/${workspaceId}/projects/${notification?.project_id}/stuck-points/${audit?.new_values?.id}`;
      break;
    case 'CompanyStuckPointNotification':
      header = (
        <>
          {user?.first_name || 'User'} assigned a <LabelComponent labelKey="stuck_point" /> to your
          company on {task?.name}
        </>
      );
      body = audit?.new_values?.title;
      badgeIcon = <PendingActions fontSize={'inherit'} />;
      toUrl = `/workspace/${workspaceId}/projects/${notification?.project_id}/stuck-points/${audit?.new_values?.id}`;
      break;
    default:
      header = 'Unknown Notification Type';
  }

  const footer = `${user?.first_name || 'Deleted User'} ${
    user?.last_name || ''
  } | ${projectName} | ${formatNotificationDate(notification.created_at)}`;

  return (
    <ListItem {...(!!toUrl && { button: true, component: Link, to: toUrl })}>
      <ListItemAvatar>
        <Badge badgeContent={badgeIcon} color={'primary'} overlap="circular">
          <Avatar alt={user?.first_name || 'Deleted User'} src={user?.profile_image?.thumb_url}>
            {user?.first_name?.[0]}
            {user?.last_name?.[0]}
          </Avatar>
        </Badge>
      </ListItemAvatar>
      <ListItemText>
        <Typography variant={'body1'}>{header}</Typography>
        <Typography variant={'body2'}>{body}</Typography>
        <Typography color={'textSecondary'} variant={'caption'}>
          {footer}
        </Typography>
      </ListItemText>
      <ListItemSecondaryAction>
        <Tooltip
          arrow
          enterDelay={500}
          placement={'top'}
          title={isRead ? 'Mark unread' : 'Mark read'}
        >
          <IconButton
            disabled={disabled}
            edge={'end'}
            size="large"
            onMouseEnter={() => setIsActionHovered(true)}
            onMouseLeave={() => setIsActionHovered(false)}
            onClick={() => {
              isRead ? unreadNotification(id) : readNotification(id);
            }}
          >
            {isRead && isActionHovered ? (
              <Markunread />
            ) : isRead && !isActionHovered ? (
              <Drafts />
            ) : (
              <Clear />
            )}
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

function formatNotificationDate(date) {
  const currentDate = new Date();
  const inputDate = new Date(date);
  const inputYear = inputDate.getFullYear();
  const currentYear = currentDate.getFullYear();

  const isToday = inputDate.toDateString() === currentDate.toDateString();

  if (isToday) {
    const formattedTime = inputDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' });
    return formattedTime;
  } else {
    const formattedDate = inputDate.toLocaleString('en-US', { month: 'short', day: 'numeric' });
    if (inputYear === currentYear) {
      return formattedDate;
    } else {
      return `${formattedDate}, ${inputYear}`;
    }
  }
}
const dateKeys = new Set(['scheduled_start', 'scheduled_end', 'forecasted_end']);
const ChangesList = ({ oldValues = {}, newValues = {} }) => {
  const changes = Object.entries(oldValues).map(([key, value]) => {
    const isDate = dateKeys.has(key);
    const fieldName = key.split('_').join(' ');
    return {
      field: fieldName[0].toUpperCase() + fieldName.slice(1),
      oldValue: isDate ? parseDate(value).toLocaleDateString() : value,
      newValue: isDate ? parseDate(newValues[key]).toLocaleDateString() : newValues[key],
    };
  });
  return changes.map(({ field, oldValue, newValue }, index) => (
    <Fragment key={index}>
      {field}: {oldValue} {'->'} {newValue} <br />
    </Fragment>
  ));
};

export const NotificationSkeleton = () => {
  return (
    <ListItem button>
      <ListItemAvatar>
        <Badge
          badgeContent={<Notifications fontSize="inherit" />}
          color="primary"
          overlap="circular"
        >
          <Avatar>
            <Skeleton height={40} variant="circular" width={40} />
          </Avatar>
        </Badge>
      </ListItemAvatar>
      <ListItemText>
        <Typography variant="body1">
          <Skeleton width="80%" />
        </Typography>
        <Typography variant="body2">
          <Skeleton width="60%" />
        </Typography>
        <Typography color="textSecondary" variant="caption">
          <Skeleton width="40%" />
        </Typography>
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton edge="end" size="large">
          <Skeleton height={24} variant="circular" width={24} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
