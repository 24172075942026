import { SvgIcon } from '@mui/material';

export const Users = (props) => {
  return (
    <SvgIcon {...props}>
      <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          clip-rule="evenodd"
          d="M12.9774 6.15001V10.05L9.59999 12L6.22259 10.05V6.15001L9.59999 4.20001L12.9774 6.15001ZM1.8 19.8L5.7 12.975H13.5L17.4 19.8H1.8ZM18.6 13.5H15.1824L18.7818 19.8H22.2L18.6 13.5ZM15 12.6L14.5044 12.3138L12.9804 11.4342L14.1774 10.743V5.87461L15 5.40001L18.1176 7.20001V10.8L15 12.6Z"
          fill="currentColor"
          fill-rule="evenodd"
        />
      </svg>
    </SvgIcon>
  );
};
