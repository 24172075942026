import { Skeleton } from '@mui/material';
import { useGetLabelQuery } from '../api/labels.api';

export const Label = ({ labelKey, plural = false }) => {
  const {
    data: { label = '', plural: pluralLabel = '' } = {},
    isLoading: isLoadingLabel,
    isFetchingLabel,
  } = useGetLabelQuery({ labelKey });

  if (isLoadingLabel) {
    return <Skeleton variant={'text'} width={'150px'} />;
  } else {
    return plural ? pluralLabel : label;
  }
};
