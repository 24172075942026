const round = (num, threshold = 0.5) => {
  let floor = Math.floor(num);
  let decimal = num - floor;
  if (decimal < threshold) {
    return floor;
  } else {
    return Math.ceil(num);
  }
};

/**
 * Calculate effort hours
 * @param {Number} budget
 * @param {Number} laborPercentage
 * @param {Number} laborRate
 * @param {Number} effortTagPercentage
 * @returns {Number} effortHours
 */
export const calculateEffortHours = (budget, laborPercentage, laborRate, effortTagPercentage) => {
  budget = parseFloat(budget);
  laborPercentage = parseFloat(laborPercentage);
  laborRate = parseFloat(laborRate);
  effortTagPercentage = parseFloat(effortTagPercentage);
  if (
    !(isNaN(budget) || isNaN(laborPercentage) || isNaN(laborRate) || isNaN(effortTagPercentage))
  ) {
    const budgetedHoursInProject = round((budget * laborPercentage) / laborRate);
    return round(budgetedHoursInProject * effortTagPercentage, 0.3);
  } else {
    throw new Error('Parameter is not number');
  }
};
