import { ConfirmDeleteDialog } from '@blackhyve/common';
import { parseDate } from '@blackhyve/utilities/dates';
import {
  CardContent,
  CardActions,
  Grid,
  Typography,
  Button,
  LinearProgress,
  Alert,
  Card,
  CardActionArea,
  CardHeader,
  Stack,
  Chip,
  FormLabel,
} from '@mui/material';
import { EditContext } from 'components/common/v3/DisplayWithEditAccess';
import { format } from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';
import { selectCurrentUser, selectCurrentUserWorkspaceId } from 'features/auth';
import { useGetCompaniesQuery } from 'features/companies/api/companies.api';
import { memo, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useCreateStuckPointMutation,
  useDeleteStuckPointMutation,
  useGetTaskActiveStuckPointsQuery,
} from '../store/stuckPoint.api';
import { AddUpdateStuckPointDialog } from './AddUpdateStuckPointDialog';
import StuckPointDialog from './StuckPointDialog';
import StuckPointResolutionForm from './StuckPointResolutionForm';
import { Add, Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Label as LabelComponent } from 'features/labels/components/Label';

/**
 * Get stuck point list
 * @param {String} taskId
 * @returns
 */
const StuckPointList = memo(({ taskId, projectId }) => {
  const {
    data: stuckPoints = [],
    isLoading,
    isFetching,
  } = useGetTaskActiveStuckPointsQuery({ taskId });

  const canEdit = useContext(EditContext);

  const [openStuckPointDialog, setOpenStuckPointDialog] = useState(false);

  const [addStuckPoint, setAddStuckPoint] = useState(false);

  const handleCloseAddStuckPointDialog = () => setAddStuckPoint(false);

  const [createStuckPoint] = useCreateStuckPointMutation();

  const handleCreate = (data) =>
    createStuckPoint({
      ...data,
      taskId,
      opened_on: format(data.opened_on, 'yyyy-MM-dd'),
    });

  return (
    <Grid container gap={1}>
      <Grid container item alignItems={'center'} gap={1} xs={12}>
        <Typography sx={{ fontWeight: 'bold' }} variant="h6">
          Active <LabelComponent labelKey="stuck_point" plural={true} />
        </Typography>

        {canEdit && (
          <Button
            size="small"
            startIcon={<Add fontSize="small" />}
            variant="contained"
            sx={{
              '& .MuiButton-startIcon': {
                marginRight: '2px',
              },
            }}
            onClick={() => setAddStuckPoint(true)}
          >
            Add
          </Button>
        )}
        <Button size="small" onClick={() => setOpenStuckPointDialog(true)}>
          {' '}
          View All
        </Button>
      </Grid>
      <Grid container spacing={2}>
        {isFetching && !isLoading && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}
        {isLoading ? (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        ) : stuckPoints?.length ? (
          stuckPoints?.map((stuckPoint) => {
            return (
              <StuckPointCard
                key={stuckPoint.id}
                projectId={projectId}
                stuckPoint={stuckPoint}
                taskId={taskId}
              />
            );
          })
        ) : (
          <Grid item xs={12}>
            <Alert icon={<Check fontSize="inherit" />} severity="success">
              No Active <LabelComponent labelKey="stuck_point" plural={true} />
            </Alert>
          </Grid>
        )}
      </Grid>
      {addStuckPoint && (
        <AddUpdateStuckPointDialog
          handleClose={handleCloseAddStuckPointDialog}
          handleSave={handleCreate}
          label="Create"
          open={addStuckPoint}
          projectId={projectId}
        />
      )}
      {openStuckPointDialog && (
        <StuckPointDialog
          handleClose={() => setOpenStuckPointDialog(false)}
          open={openStuckPointDialog}
          projectId={projectId}
          taskId={taskId}
        />
      )}
    </Grid>
  );
});

export default StuckPointList;

/**
 * Stuck Point Card
 * @param {Object} stuckPoint
 * @param {String} taskId
 * @param {String} projectId
 */
export const StuckPointCard = ({ stuckPoint, projectId, taskId }) => {
  const canEdit = useContext(EditContext);
  const navigate = useNavigate();
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const currentUser = useSelector((state) => selectCurrentUser(state));

  const [openResolveDialog, setOpenResolvedDialog] = useState(false);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);

  const { data: companies = [] } = useGetCompaniesQuery();
  const [deleteStuckPoint, { isLoading: isStuckPointBeingDeleted }] = useDeleteStuckPointMutation();

  const companyDetails = stuckPoint?.companies_to_blame
    .map((companyId) => {
      return companies.find((company) => company.id === companyId);
    })
    .filter((company) => company);

  const daysStuck = stuckPoint?.is_resolved
    ? differenceInDays(parseDate(stuckPoint?.resolved_on), parseDate(stuckPoint?.opened_on))
    : differenceInDays(new Date(), parseDate(stuckPoint?.opened_on));

  const isOwnerOfStuckPoint = currentUser.id === stuckPoint.opened_by;

  const handleDelete = () => {
    deleteStuckPoint({
      id: stuckPoint.id,
    }).then(() => {
      setDeleteConfirmationDialog(false);
    });
  };

  return (
    <Grid item md={4} xs={6}>
      <Card>
        <CardActionArea>
          <CardHeader
            subheader={
              <Stack direction="row" gap={1}>
                <Typography variant="subtitle2">Days Stuck: {daysStuck}</Typography>
                {stuckPoint.affects_pr ? (
                  <Chip color="error" label="Work is Paused" size="small" variant="outlined" />
                ) : null}
              </Stack>
            }
            title={
              <Stack direction={'row'} gap={1}>
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {stuckPoint?.title}
                </Typography>
              </Stack>
            }
          />
          <CardContent style={{ paddingTop: 0, paddingBottom: '5px' }}>
            <Grid container gap={1}>
              <Grid item xs={12}>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {stuckPoint?.description}
                </Typography>
              </Grid>
              <Grid container item gap={1} xs={12}>
                {companyDetails.length ? (
                  <Grid container item alignItems={'center'} gap={1} xs={12}>
                    <FormLabel sx={{ fontSize: 14 }}>Stuck By:</FormLabel>
                    {companyDetails.map((company) => {
                      return (
                        <Chip label={company.name} size="small" variant="subtitle2">
                          {company.name}
                        </Chip>
                      );
                    })}
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
        <CardActions disableSpacing>
          <Grid container>
            <Grid container item xs={5}>
              {canEdit && !stuckPoint.resolved_on && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => setOpenResolvedDialog(true)}
                >
                  Resolve
                </Button>
              )}
            </Grid>
            <Grid container item justifyContent="flex-end" xs={7}>
              <Button
                size="small"
                onClick={() => {
                  navigate(
                    `/workspace/${workspaceId}/projects/${projectId}/stuck-points/${stuckPoint?.id}`
                  );
                }}
              >
                View
              </Button>
              {canEdit && isOwnerOfStuckPoint && (
                <LoadingButton
                  loading={isStuckPointBeingDeleted}
                  size="small"
                  onClick={() => setDeleteConfirmationDialog(true)}
                >
                  Remove
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      {openResolveDialog && (
        <StuckPointResolutionForm
          handleClose={() => setOpenResolvedDialog(false)}
          open={openResolveDialog}
          stuckPoint={stuckPoint}
        />
      )}
      {deleteConfirmationDialog && (
        <ConfirmDeleteDialog
          handleClose={() => setDeleteConfirmationDialog(false)}
          handleDelete={handleDelete}
          item={<LabelComponent labelKey="stuck_point" />}
          open={deleteConfirmationDialog}
        />
      )}
    </Grid>
  );
};
