import { parseDate } from '@blackhyve/utilities/dates';
import { TrackChanges, Verified } from '@mui/icons-material';
import { Chip, Divider, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { Company } from 'components/icons/apiary/Company';
import { Users } from 'components/icons/apiary/Users';
import { format } from 'date-fns';
import { Status } from 'features/tasks';
import { Pace } from 'features/tasks/components/Pace';
import { ProductionRating } from 'features/tasks/components/ProductionRating';
import { Progress } from 'features/tasks/components/Progress';
import { memo } from 'react';
import { useDueDate } from '../hooks/useDueDate';

const dateFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

export const TargetDetails = memo(({ target, publishDate }) => {
  const dueDate = useDueDate({
    scheduledEndDate: parseDate(target?.task?.scheduled_end),
    publishedOnDate: publishDate || new Date(target.published_on),
  });

  return (
    <Stack alignItems={'center'} direction={'row'} spacing={{ xs: 1, sm: 2 }}>
      {/* <Box display={{ xs: 'none', md: 'block' }}> */}
      <TargetPercentComplete
        dueDate={dueDate}
        targetPercentComplete={target.target_percent_complete}
      />
      <Divider flexItem orientation={'vertical'} />
      {/* </Box> */}
      <Stack>
        <Stack direction={'column'}>
          <Stack useFlexGap alignItems={'center'} direction={'row'} flexWrap={'wrap'} spacing={1}>
            <Typography fontWeight={'bold'} width={{ xs: 1, md: 'unset' }}>
              {target?.task?.name}
            </Typography>
            {target?.task?.status && (
              <Tooltip title={'Current task status'}>
                <div>
                  <Status py={0} value={target?.task.status} />
                </div>
              </Tooltip>
            )}
            {target?.task?.percent_complete && (
              <Tooltip title={'Current task percent complete'}>
                {/* <span>{Math.round(Number(target?.task?.percent_complete) * 100)}%</span> */}
                <Progress py={0} value={target?.task?.percent_complete} />
              </Tooltip>
            )}
            {target?.task?.pace !== null && (
              <Tooltip title={'Current task pace'}>
                <span>
                  <Pace py={0} value={target?.task?.pace} />
                </span>
              </Tooltip>
            )}
            {target?.task?.pr !== null && (
              <Tooltip title={'Current task pr'}>
                <span>
                  <ProductionRating py={0} value={target?.task?.pr} />
                </span>
              </Tooltip>
            )}
            {target.published_by && (
              <Tooltip
                title={`Target published by ${target?.published_by?.first_name} ${target?.published_by?.last_name} on ${parseDate(target.published_on).toLocaleDateString()}`}
              >
                <Chip
                  color={'success'}
                  direction={'row'}
                  icon={<Verified />}
                  label={`${target?.published_by?.first_name} ${target?.published_by?.last_name}`}
                  size={'small'}
                />
              </Tooltip>
            )}
          </Stack>
          <Stack alignItems={'center'} direction={'row'} spacing={1}>
            <Tooltip title={'Parents'}>
              <Typography variant={'subtitle2'}>
                {target?.task?.parents?.map(({ name }) => name).join(' > ')}
              </Typography>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack alignItems={'center'} direction={'row'} spacing={1}>
          <Tooltip title={'Task scheduled dates'}>
            <Typography component={'span'} variant={'subtitle2'}>
              {`${parseDate(target?.task?.scheduled_start).toLocaleDateString(undefined, dateFormatOptions)} - ${parseDate(target?.task?.scheduled_end).toLocaleDateString(undefined, dateFormatOptions)}`}
            </Typography>
          </Tooltip>
        </Stack>
        <Stack alignItems={'center'} direction={'row'} spacing={1}>
          {target?.task?.companies?.length > 0 && (
            <>
              <Stack alignItems={'center'} direction={'row'} spacing={1}>
                <Company />
                {target?.task?.companies?.[0]?.name}
              </Stack>
              <Stack alignItems={'center'} direction={'row'} spacing={1}>
                <Users />
                {target?.task?.crew_size}
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
});

export const TargetDetailsSkeleton = () => {
  return (
    <Stack alignItems={'center'} direction={'row'} spacing={2}>
      <Stack alignItems={'center'}>
        <TrackChanges />
        <Skeleton variant="text" width={30} />
      </Stack>
      <Stack>
        <Stack direction={'column'}>
          <Stack alignItems={'center'} direction={'row'} spacing={1}>
            <Typography fontWeight={'bold'}>
              <Skeleton variant="text" width={100} />
            </Typography>
            <Skeleton variant="text" width={20} />
            <Skeleton variant="text" width={30} />
          </Stack>
          <Typography component={'span'} variant={'subtitle2'}>
            <Skeleton variant="text" width={150} />
          </Typography>
        </Stack>
        <Stack alignItems={'center'} direction={'row'} spacing={1}>
          <Stack alignItems={'center'} direction={'row'} spacing={1}>
            <Skeleton variant="text" width={100} />
          </Stack>
          <Stack alignItems={'center'} direction={'row'} spacing={1}>
            <Skeleton variant="text" width={30} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const TargetPercentComplete = ({ dueDate, targetPercentComplete }) => {
  return (
    <Tooltip
      title={`Target percent complete by ${dueDate.toLocaleDateString(undefined, dateFormatOptions)}`}
    >
      <Stack alignItems={'center'} maxWidth={'40px'} minWidth={'40px'}>
        <TrackChanges />
        <span>{Math.round(targetPercentComplete * 100)}%</span>
        {dueDate && <span>{format(dueDate, 'ccc')}</span>}
      </Stack>
    </Tooltip>
  );
};
