import { StyledDialog } from '@blackhyve/common';
import { Button, Typography } from '@mui/material';

const ConfirmDeleteDialog = ({ open, handleClose, handleDelete, id, item, plural }) => {
  const onClose = (event, reason) => {
    if (reason !== 'escapeKeyDown' && reason !== 'backdropClick') {
      handleClose();
    }
  };
  return (
    <StyledDialog
      dialogOverrides={{ maxWidth: 'xs' }}
      handleClose={onClose}
      open={open}
      title="Confirm Delete"
      actions={
        <>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant={'contained'}
            onClick={() => {
              handleDelete(id);
              handleClose();
            }}
          >
            Delete {item}
          </Button>
        </>
      }
    >
      <Typography variant={'h6'} fontWeight={'bold'} textAlign={'center'}>
        Are you sure you want to delete {plural ? 'these ' : 'this '}
        {item ? item : null}?
      </Typography>
      <Typography textAlign={'center'}>This action cannot be undone.</Typography>
    </StyledDialog>
  );
};

export default ConfirmDeleteDialog;
