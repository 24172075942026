import SvgBuilding from '@blackhyve/common/src/icons/Building';
import { parseDate } from '@blackhyve/utilities/dates';
import { Business, HomeRepairService, LocationCity } from '@mui/icons-material';
import { Box, Chip, Grid, Icon, Paper, TableContainer, Toolbar, Typography } from '@mui/material';
import SearchBox from 'components/search/SearchBox';
import { Table } from 'components/table/Table';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import MoreOptions from 'features/projects/components/MoreOptions';
import { Pace } from 'features/tasks/components/Pace';
import { ProductionRating } from 'features/tasks/components/ProductionRating';
import { groupBy as lodashGroupBy } from 'lodash';
import { useDeferredValue, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetProjectsQuery } from '../store/project.api';

const columns = [
  {
    field: 'icon',
    render: () => (
      <Icon fontSize={'small'} sx={{ color: 'grey.600' }}>
        <SvgBuilding height={'1em'} width={'1em'} />
      </Icon>
    ),
  },
  { field: 'name', label: 'Name', sort: true },
  { field: 'division', label: 'Division', sort: true },
  { field: 'region', label: 'Region', sort: true },
  { field: 'office', label: 'Office', sort: true },
  {
    field: 'start_date',
    label: 'Start Date',
    sort: true,
    cellProps: { align: 'right' },
    render: (project) => parseDate(project.start_date).toLocaleDateString(),
  },
  {
    field: 'est_completion_date',
    label: 'Forecasted End',
    sort: true,
    cellProps: { align: 'right' },
    render: (project) => parseDate(project.est_completion_date).toLocaleDateString(),
  },
  {
    field: 'sub_completion_date',
    label: 'Scheduled End',
    sort: true,
    cellProps: { align: 'right' },
    render: (project) => parseDate(project.sub_completion_date).toLocaleDateString(),
  },
  {
    field: 'pace',
    label: 'Pace',
    sort: true,
    cellProps: { align: 'right' },
    render: (project) => <Pace value={project.pace} />,
  },
  {
    field: 'pr',
    label: 'PR',
    sort: true,
    cellProps: { align: 'right' },
    render: (project) => <ProductionRating value={project.pr} />,
  },
  {
    field: 'moreOptions',
    cellProps: { align: 'right' },
    render: (project) => (
      <div onClick={(event) => event.stopPropagation()}>
        <MoreOptions projectId={project.id} />
      </div>
    ),
  },
];
export const ProjectsTable = (props) => {
  const [search, setSearch] = useState('');
  const deferredSearch = useDeferredValue(search);
  const [groupBy, setGroupBy] = useState(undefined);
  const deferredGroupBy = useDeferredValue(groupBy);
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { data: projects = [], isLoading, isFetching } = useGetProjectsQuery({ workspaceId });

  const handleSetGroupBy = (value) => {
    if (value === groupBy) {
      setGroupBy(undefined);
    } else {
      setGroupBy(value);
    }
  };

  const filteredList = useMemo(
    () =>
      projects?.filter((project) =>
        project.name.toLowerCase().includes(deferredSearch.toLowerCase())
      ),
    [projects, deferredSearch]
  );

  const groupedList = useMemo(
    () => (deferredGroupBy ? lodashGroupBy(filteredList, deferredGroupBy) : { null: filteredList }),
    [filteredList, deferredGroupBy]
  );

  return (
    <div>
      <Grid container item justifyContent={'center'} m={'auto'} md={6} p={3} spacing={1} xs={12}>
        <Grid item>
          <Chip
            icon={<HomeRepairService />}
            label={'Group By Division'}
            variant={groupBy === 'division' ? 'filled' : 'outlined'}
            onClick={() => handleSetGroupBy('division')}
          />
        </Grid>
        <Grid item>
          <Chip
            icon={<LocationCity />}
            label={'Group By Region'}
            variant={groupBy === 'region' ? 'filled' : 'outlined'}
            onClick={() => handleSetGroupBy('region')}
          />
        </Grid>
        <Grid item>
          <Chip
            icon={<Business />}
            label={'Group By Office'}
            variant={groupBy === 'office' ? 'filled' : 'outlined'}
            onClick={() => handleSetGroupBy('office')}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Toolbar sx={{ position: 'sticky', left: '0px' }}>
          <SearchBox
            bgcolor={'grey.200'}
            borderRadius={1}
            fullWidth={false}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </Toolbar>
        {Object.entries(groupedList)?.map(([field, value]) => (
          <Box key={field} p={3}>
            {deferredGroupBy && (
              <Typography sx={{ textTransform: 'capitalize' }} variant="h4">
                {field === 'null' ? 'None' : field}
              </Typography>
            )}
            <Table
              columns={columns}
              defaultOrderBy={false}
              isFetching={isFetching}
              isLoading={isLoading}
              rows={value}
              {...props}
            />
          </Box>
        ))}
      </TableContainer>
    </div>
  );
};
