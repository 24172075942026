import { api } from 'api';
import isEmpty from 'lodash/isEmpty';
import parseISO from 'date-fns/parseISO';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import difference from 'lodash/difference';
import { NavLink } from 'react-router-dom';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import { fieldsToIgnore } from 'components/taskFeeds/utils/taskFeedHelper';
import { TranslateAuditContent } from 'components/taskFeeds/utils/taskFeedHelper';
import TaskFeedCard, { TaskFeedSkeleton } from 'components/taskFeeds/TaskFeedCard';
import {
  ActivityFeedCardSubHeading,
  CustomTimelineItem,
  ProjectLabel,
} from '../assets/activityFeedStyle';
import { selectCurrentUserRole, selectCurrentUserWorkspaceId } from 'features/auth';
import Link from '@mui/material/Link';
import { Label as LabelComponent } from 'features/labels/components/Label';

/**
 * Activity feed timeline card
 * @param {Object} activityFeed
 * @param {Object} setOpenDrawer
 * @returns
 */
export const ActivityFeedTimelineItem = ({ activityFeed, setOpenDrawer }) => {
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { project } = api.endpoints.getProjects.useQueryState(
    { workspaceId },
    {
      selectFromResult: ({ data: projects, isFetching, isLoading }) => {
        return {
          project: projects?.find((project) => project.id === activityFeed?.project_id),
          isFetching,
          isLoading,
        };
      },
    }
  );

  const userRole = useSelector((state) => selectCurrentUserRole(state));

  const isInvalidItem =
    (isEmpty(activityFeed?.new_values) ||
      !difference(Object.keys(activityFeed?.new_values), fieldsToIgnore).length) &&
    activityFeed?.event === 'updated';

  if (isInvalidItem) {
    return <div></div>;
  }

  let toUrl = activityFeed?.task?.deleted_at
    ? undefined
    : userRole === 'contractor_contact'
      ? `/workspace/${workspaceId}/my-tasks?task=${activityFeed?.task?.id}`
      : `/workspace/${workspaceId}/projects/${activityFeed?.project_id}/planning-board/gantt?task=${activityFeed?.task?.id}`;

  const dateTime = parseISO(activityFeed?.created_at).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  let title = '';

  switch (activityFeed?.auditable_type) {
    case 'Task':
      title =
        activityFeed?.event === 'sync'
          ? `updated ${Object.hasOwn(activityFeed?.old_values, 'users') ? `assignment on ` : ''}`
          : `${activityFeed?.event} task`;
      break;
    case 'JobWalk':
      title = 'created a jobwalk on';
      break;
    case 'StuckPoint':
      title = <>{activityFeed?.event} a <LabelComponent labelKey="stuck_point" /> on </>;
      break;
    case 'Comment':
      title = <>{activityFeed?.event} a comment on {activityFeed.comment.commentable_type === 'StuckPoint' ? <LabelComponent labelKey="stuck_point" /> : ''}</>;
      break;
    default:
      title = '';
  }

  const subHeading = (
    <ActivityFeedCardSubHeading>
      {title}{' '}
      <Link color={toUrl ? 'primary' : 'inherit'} component={NavLink} to={toUrl}>
        {activityFeed?.task?.name}
      </Link>
    </ActivityFeedCardSubHeading>
  );

  const content = (
    <TranslateAuditContent
      auditType={activityFeed?.auditable_type}
      event={activityFeed?.event}
      meta={{ stuckPoint: activityFeed?.stuck_point }}
      newValues={activityFeed?.new_values}
      oldValues={activityFeed?.old_values}
      task={activityFeed?.task}
    />
  );

  return (
    <Grid
      container
      justifyContent="center"
      onClick={() => {
        setOpenDrawer(activityFeed?.id);
      }}
    >
      <Grid container item justifyContent="center" md={10} xl={8} xs={12}>
        <CustomTimelineItem
          direction={activityFeed?.direction}
          key={activityFeed?.id}
          style={{ width: '100%' }}
        >
          <TimelineSeparator>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}>
            {activityFeed.displayProjectName && (
              <ProjectLabel>{project?.name ? project?.name : 'Deleted Project'}</ProjectLabel>
            )}
            <TaskFeedCard
              content={content}
              dateTime={dateTime}
              readCount={activityFeed?.read_count}
              subHeading={subHeading}
              unreadCount={activityFeed?.notification_count - activityFeed?.read_count}
              userId={activityFeed?.user_id}
            />
          </TimelineContent>
        </CustomTimelineItem>
      </Grid>
    </Grid>
  );
};

export const ActivityFeedSkeleton = ({ direction, id }) => {
  return (
    <Grid container justifyContent="center">
      <Grid container item justifyContent="center" md={10} xl={8} xs={12}>
        <CustomTimelineItem container direction={direction} key={id} style={{ width: '100%' }}>
          <TimelineSeparator>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}>
            <TaskFeedSkeleton />
          </TimelineContent>
        </CustomTimelineItem>
      </Grid>
    </Grid>
  );
};
