import { api, providesList } from 'api';

export const LABELS_CACHE_KEY = 'Label';

export const labelsAPI = api.enhanceEndpoints({ addTagTypes: [LABELS_CACHE_KEY] }).injectEndpoints({
  endpoints: (build) => ({
    getLabels: build.query({
      query: () => `/labels`,
      providesTags: (result, error, args) =>
        result ? providesList(result.data, LABELS_CACHE_KEY, 'key') : [],
      transformResponse: (response) => {
        const labelEntities = response.data.reduce((acc, labelObj) => {
          acc[labelObj.key] = labelObj;
          return acc;
        }, {});
        return { data: response.data, labelEntities }
      },
    }),
    getLabel: build.query({
      query: ({ labelKey }) => `/labels/${labelKey}`,
      providesTags: (result, error, args) =>
        result ? [{ type: LABELS_CACHE_KEY, id: result.key }] : [],
      transformResponse: (response) => response.data,
    }),
    updateLabel: build.mutation({
      query: ({ labelKey, body: { label, plural } }) => ({
        url: `/labels/${labelKey}`,
        method: 'POST',
        body: { label, plural },
      }),
      invalidatesTags: (result, error, args) =>
        result ? [{ type: LABELS_CACHE_KEY, id: result.key }] : [],
      transformResponse: (response) => response.data,
    }),
    deleteLabel: build.mutation({
      query: ({ labelKey }) => `/labels/${labelKey}`,
      invalidatesTags: (result, error, args) =>
        result ? [{ type: LABELS_CACHE_KEY, id: result.key }] : [],
      transformResponse: (response) => response.data,
      method: 'DELETE',
    }),
  }),
});

export const {
  useGetLabelsQuery,
  useGetLabelQuery,
  useUpdateLabelMutation,
  useDeleteLabelMutation,
} = labelsAPI;
