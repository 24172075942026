export const objectToFormData = (obj) => {
  return Object.entries(obj).reduce((formData, [key, value]) => {
    if (Array.isArray(value)) {
      value?.forEach(item => formData.append(`${key}[]`, item || ''));
    } else if (value) {
      formData.append(key, value);
    }
    return formData;
  }, new FormData());
};
