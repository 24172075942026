import { StyledDialog } from '@blackhyve/common';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { format, startOfDay } from 'date-fns';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCreateStuckPointMutation, useGetTaskStuckPointsQuery } from '../store/stuckPoint.api';
import { StuckPointForm } from './StuckPointForm';
import StuckPointTable from './StuckPointTable';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { riskExposureEnum } from '../StuckPointEnums';
import { Label as LabelComponent } from 'features/labels/components/Label';

const initialState = {
  id: undefined,
  title: '',
  description: '',
  companies_to_blame: [],
  responsible_users: [],
  affects_pr: false,
  resolved_on: null,
  is_notify: true,
  opened_on: startOfDay(new Date()),
  resolution_plan: '',
  risk_exposure: riskExposureEnum.low,
};

/**
 * Get stuck point list
 * @param {Boolean} open
 * @param {String} taskId
 * @param {String} projectId
 * @param {Boolean} handleClose
 * @returns
 */
const StuckPointDialog = ({ taskId, projectId, open, handleClose }) => {
  const form = useForm({
    defaultValues: { ...initialState },
  });

  const { data: stuckPoints = [], isLoading, isFetching } = useGetTaskStuckPointsQuery({ taskId });

  const [displayAddStuckPoint, setDisplayAddStuckPoint] = useState(false);

  const [createStuckPoint, { isLoading: isCreatingStuckPoint, isError }] =
    useCreateStuckPointMutation();

  const handleCreate = (data) =>
    createStuckPoint({
      ...data,
      taskId,
      opened_on: format(data.opened_on, 'yyyy-MM-dd'),
    }).then(() => {
      handleReset();
    });

  const handleReset = () => {
    form.reset();
    setDisplayAddStuckPoint(false);
  };

  return (
    <StyledDialog handleClose={handleClose} maxWidth={'lg'} open={open} title={<LabelComponent labelKey="stuck_point" plural={true} />}>
      <Grid container gap={1}>
        {displayAddStuckPoint && (
          <Paper elevation={2}>
            <Grid container item gap={1} p={2} xs={12}>
              <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                Add <LabelComponent labelKey="stuck_point" />
              </Typography>
              {displayAddStuckPoint && (
                <>
                  <StuckPointForm
                    error={isError && 'An error occured. Please try again.'}
                    form={form}
                    handleSave={handleCreate}
                    projectId={projectId}
                  />
                  <Stack direction={'row'} spacing={1}>
                    <Button onClick={handleReset}>Cancel</Button>
                    <LoadingButton
                      color="primary"
                      loading={isCreatingStuckPoint}
                      variant="contained"
                      onClick={form.handleSubmit(handleCreate)}
                    >
                      Create
                    </LoadingButton>
                  </Stack>
                </>
              )}
            </Grid>
          </Paper>
        )}
        {!displayAddStuckPoint && (
          <Button variant="text" onClick={() => setDisplayAddStuckPoint((prev) => !prev)}>
            + ADD <LabelComponent labelKey="stuck_point" />
          </Button>
        )}
        <Grid item xs={12}>
          <Paper elevation={3}>
            <StuckPointTable
              isFetching={isFetching}
              isLoading={isLoading}
              stuckPoints={stuckPoints}
            />
          </Paper>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};

export default StuckPointDialog;
