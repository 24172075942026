import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import DisplayWithEditAccess from 'components/common/v3/DisplayWithEditAccess';
import { WeekPagination } from 'components/WeekPagination';
import { format, startOfWeek } from 'date-fns';
import { useGetTaskQuery } from 'features/tasks/store/task.api';
import { usePrintState } from 'hooks/usePrintEvents';
import { groupBy, times } from 'lodash';
import { useMemo, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useDebounceValue, useIntersectionObserver } from 'usehooks-ts';
import { useGetWeeklyWorkPlanQuery } from '../api/target.api';
import { PublishWeeklyWorkPlanDialog } from '../components/PublishWeeklyWorkPlanDialog';
import { TargetDetails, TargetDetailsSkeleton } from '../components/TargetDetails';
import { TargetMoreOptionsMenu } from '../components/TargetMoreOptionsMenu';

const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

const initialTargetToShow = 25;

export const Targets = () => {
  const { taskId, projectId, workspaceId } = useParams();
  const { isPrinting } = usePrintState();

  const [startOfWeek, setStartOfWeek] = useState(startOfThisWeek);
  const [debouncedStartOfWeek, setDebouncedStartOfWeek] = useDebounceValue(startOfWeek, 200);
  const [isPublishOpen, setIsPublishOpen] = useState(false);
  const [targetsToShow, setTargetsToShow] = useState(initialTargetToShow);

  const { ref: loadMoreTargetsRef } = useIntersectionObserver({
    onChange: (isIntersecting) => {
      if (isIntersecting) {
        setTargetsToShow((prev) => prev + initialTargetToShow);
      }
    },
  });

  const { data: task, isLoading: isLoadingTask } = useGetTaskQuery({ taskId });
  const {
    data: { targets = [], message = '', published_on = null } = {},
    isLoading: isLoadingTargets,
    isFetching: isFetchingTargets,
  } = useGetWeeklyWorkPlanQuery({
    projectId: projectId,
    parentId: taskId,
    date: format(debouncedStartOfWeek, 'yyyy-MM-dd'),
  });

  const grouped = useMemo(
    () =>
      groupBy(targets.slice(0, targetsToShow), (value) =>
        value.task.parents.map(({ name }) => name).join(' > ')
      ),
    [targets, targetsToShow]
  );

  return (
    <Container component={Stack} spacing={2}>
      {isLoadingTask ? (
        <Skeleton sx={{ margin: 'auto' }} variant={'text'}>
          <Typography textAlign={'center'} variant={'h4'}>
            Targets:
          </Typography>
        </Skeleton>
      ) : (
        <Typography textAlign={'center'} variant={'h4'}>
          {task?.name} Targets:
        </Typography>
      )}
      <WeekPagination
        date={startOfWeek}
        onWeekChange={(newStartOfWeek) => {
          setDebouncedStartOfWeek(newStartOfWeek);
          setStartOfWeek(newStartOfWeek);
          setTargetsToShow(initialTargetToShow);
        }}
      />
      {!isLoadingTargets && isFetchingTargets && (
        <Box position={'relative'} width={1}>
          <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, width: 1, zIndex: '999' }} />
        </Box>
      )}
      <div>
        <Typography variant="h4">Message</Typography>
        <Paper>
          <Typography p={1}>{message}</Typography>
        </Paper>
      </div>
      <div>
        <Stack alignItems={'center'} direction={'row'} spacing={2}>
          <Typography variant="h4">Targets</Typography>
          <DisplayWithEditAccess>
            {published_on && (
              <Button size={'small'} variant={'contained'} onClick={() => setIsPublishOpen(true)}>
                Re-Publish
              </Button>
            )}
          </DisplayWithEditAccess>
        </Stack>
        <Paper>
          {/* <Stack spacing={1}> */}
          {/* Header */}
          <List disablePadding>
            {isLoadingTargets ? (
              times(10, (num) => (
                <ListItem divider key={num}>
                  <TargetDetailsSkeleton />
                </ListItem>
              ))
            ) : targets.length === 0 ? (
              <Alert severity={'info'}>
                {/* <Typography>Weekly Work Plan Has Not Been Published</Typography> */}
                {published_on === null ? (
                  <AlertTitle>Targets for this week have not been published</AlertTitle>
                ) : (
                  <AlertTitle>No targets published for this task this week</AlertTitle>
                )}
                <DisplayWithEditAccess>
                  <Button
                    size={'small'}
                    variant={'contained'}
                    onClick={() => setIsPublishOpen(true)}
                  >
                    Publish
                  </Button>
                </DisplayWithEditAccess>
              </Alert>
            ) : (
              Object.entries(grouped).map(([groupKey, targets]) => (
                <ListItem disablePadding divider key={groupKey}>
                  <List dense disablePadding sx={{ width: 1 }}>
                    <ListSubheader sx={{ fontWeight: 600, color: 'unset', fontSize: '1rem' }}>
                      {groupKey}
                    </ListSubheader>
                    <Divider />
                    {targets.map((target) => (
                      <ListItem
                        disablePadding
                        divider
                        key={target.id}
                        secondaryAction={
                          <DisplayWithEditAccess>
                            <TargetMoreOptionsMenu target={target} />
                          </DisplayWithEditAccess>
                        }
                      >
                        <ListItemButton
                          component={RouterLink}
                          sx={{ pl: 4 }}
                          to={`/workspace/${workspaceId}/projects/${target.task.project_id}/production-board/tasks/${target.task.id}/details`}
                        >
                          <TargetDetails target={target} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </ListItem>
              ))
            )}
          </List>
          {targetsToShow < targets.length && !isPrinting && (
            <Stack alignItems={'center'} p={2} ref={loadMoreTargetsRef}>
              <CircularProgress />
            </Stack>
          )}
        </Paper>
      </div>
      <PublishWeeklyWorkPlanDialog
        disableChangeDate
        date={startOfWeek}
        handleClose={() => setIsPublishOpen(false)}
        open={isPublishOpen}
        projectId={projectId}
        taskId={taskId}
      />
    </Container>
  );
};
