import { api, providesList } from 'api';
import isArray from 'lodash/isArray';

const jobwalkAPI = api.enhanceEndpoints({ addTagTypes: ['Jobwalk'] }).injectEndpoints({
  endpoints: (build) => ({
    createJobwalk: build.mutation({
      query: ({ taskId, ...body }) => ({
        url: `/tasks/${taskId}/job_walks`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, params) =>
        result ? [{ type: 'Jobwalk' }, { type: 'Task', id: params.taskId }] : [],
    }),
    getJobwalks: build.query({
      query: ({ taskId }) => `/tasks/${taskId}/job_walks`,
      providesTags: (result, error, args) => (result ? providesList(result, 'Jobwalk') : []),
      transformResponse: (response) => response.data,
    }),
    deleteJobwalk: build.mutation({
      query: ({ taskId, jobwalkId }) => ({
        url: `tasks/${taskId}/job_walks/${jobwalkId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, params) =>
        result ? [{ type: 'Jobwalk', id: params.jobwalkId }] : [],
    }),
    getProjectJobwalkList: build.query({
      query: (queryParam) => {
        const queryString = Object.entries(queryParam)
          .filter(
            ([key, value]) =>
              (value !== undefined && !isArray(value)) || (isArray(value) && value.length > 0)
          )
          .map(([key, value]) =>
            ['location', 'zone', 'area'].includes(key) ? `lbs[${key}]=${value}` : `${key}=${value}`
          )
          .join('&');
        return `projects/${queryParam.projectId}/jobwalk_tasks?${queryString}`;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const newQueryArg = Object.fromEntries(
          Object.entries(queryArgs).filter(([key, value]) => {
            return (
              value !== false && (Array.isArray(value) ? value.length > 0 : value !== undefined)
            );
          })
        );
        return newQueryArg;
      },
      providesTags: (result, error, args) =>
        result?.data?.length ? providesList(result.data, 'Jobwalk') : [],
      // forceRefetch({ currentArg, previousArg }) {
      //   return currentArg !== previousArg;
      // },
      transformResponse: (response) => {
        return {
          data: response.data,
          date_range_start: response.date_range_start,
          date_range_end: response.date_range_end,
          project_week: response.project_week,
          current_page: response?.meta?.current_page,
          last_page: response?.meta?.last_page,
          completed_jobwalks: response.completed_jobwalks,
          total_jobwalks: response?.meta?.total,
          LBS: response?.LBS,
        };
      },
    }),
    getProjectJobwalkLBS: build.query({
      query: (queryParam) => {
        const queryString = Object.entries(queryParam)
          .filter(
            ([key, value]) =>
              (value !== undefined && !isArray(value)) || (isArray(value) && value.length > 0)
          )
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
        return `projects/${queryParam.projectId}/jobwalk_lbs?${queryString}`;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const newQueryArg = Object.fromEntries(
          Object.entries(queryArgs).filter(([key, value]) => {
            return (
              value !== false && (Array.isArray(value) ? value.length > 0 : value !== undefined)
            );
          })
        );
        return newQueryArg;
      },
      providesTags: (response, error, args) => [
        { type: 'Jobwalk' },
        { type: 'Location' },
        { type: 'Zone' },
        { type: 'Area' },
      ],
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useCreateJobwalkMutation,
  useGetJobwalksQuery,
  useDeleteJobwalkMutation,
  useGetProjectJobwalkLBSQuery,
  useGetProjectJobwalkListQuery,
} = jobwalkAPI;
