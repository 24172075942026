import { Grid, Paper, Skeleton } from '@mui/material';

export const StepSkeleton = () => {
  return (
    <Grid
      item
      square
      component={Paper}
      sx={{ px: 2, py: 1, display: 'flex', flexDirection: 'column', gap: 1 }}
      variant="outlined"
      xs={12}
    >
      <Grid container item alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <Skeleton height={30} variant="text" width="100%" />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Skeleton height={20} variant="text" width="100%" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton height={20} variant="text" width="100%" />
        </Grid>
      </Grid>
    </Grid>
  );
};
