import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Button, ButtonGroup, IconButton, Stack, Typography } from '@mui/material';
import {
  addWeeks,
  endOfWeek,
  getWeek,
  getYear,
  isAfter,
  isBefore,
  isSameWeek,
  startOfWeek,
} from 'date-fns';

const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

export const WeekPagination = ({ date, onWeekChange, min, max, disableChangeDate }) => {
  return (
    <Stack alignItems={'center'} spacing={1}>
      <Stack alignItems={'center'} direction={'row'} justifyContent={'center'} spacing={1}>
        {!disableChangeDate && (
          <IconButton
            disabled={min && (isBefore(date, min) || isSameWeek(date, min))}
            onClick={() => onWeekChange(addWeeks(date, -1))}
          >
            <ArrowBack />
          </IconButton>
        )}
        <Stack alignItems={'center'}>
          <Typography component={'span'}>
            Week {getWeek(date, { weekStartsOn: 1 })}, {getYear(date)}
          </Typography>
          <Typography component={'span'} variant={'subtitle2'}>
            {startOfWeek(date, { weekStartsOn: 1 }).toLocaleDateString(undefined, {
              weekday: 'short',
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })}
            {' - '}
            {endOfWeek(date, { weekStartsOn: 1 }).toLocaleDateString(undefined, {
              weekday: 'short',
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })}
          </Typography>
        </Stack>
        {!disableChangeDate && (
          <IconButton
            disabled={max && (isAfter(date, max) || isSameWeek(date, max))}
            onClick={() => onWeekChange(addWeeks(date, 1))}
          >
            <ArrowForward />
          </IconButton>
        )}
      </Stack>
      {!disableChangeDate && (
        <ButtonGroup size={'small'} variant={'contained'}>
          <Button
            disabled={isSameWeek(date, startOfThisWeek, { weekStartsOn: 1 })}
            onClick={() => onWeekChange(startOfThisWeek)}
          >
            This Week
          </Button>
          {/* <Button>
            <ExpandMore />
          </Button> */}
        </ButtonGroup>
      )}
    </Stack>
  );
};
