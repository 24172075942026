import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import { useState, useMemo } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import SearchBox from 'components/search/SearchBox';
import StuckPointTable, { StuckPointTableColumns } from './StuckPointTable';
import { ContentLayout } from 'components/layouts/ContentLayout';
import StuckPointStatusMenu from './StuckPointStatusMenu';
import { useGetGlobalStuckPointsQuery, } from '../store/stuckPoint.api';
import { Label as LabelComponent } from 'features/labels/components/Label';
import { StuckPointRiskExposureMenu } from './StuckPointRiskExposureMenu';

import CompanyIcon from '@blackhyve/common/src/icons/CompanyIcon';
import { selectCurrentUserRole } from 'features/auth';
import { useGetCompaniesQuery } from 'features/companies/api/companies.api';

import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import InputAdornment from '@mui/material/InputAdornment';
import { StyledMenu } from 'features/activityFeeds/assets/activityFeedStyle';
import { useGetUsersByCompanyIdQuery } from 'features/contacts/api/users.api';

const initialState = {
  page: 1,
  user: [],
  company: [],
  resolved: undefined,
  projectId: [],
  search: undefined,
  risk_exposure: undefined,
}

const tableColumns = [
  StuckPointTableColumns.title,
  StuckPointTableColumns.project,
  StuckPointTableColumns.task_name,
  StuckPointTableColumns.resolved_on,
  StuckPointTableColumns.days_stuck,
  StuckPointTableColumns.responsible_users,
  StuckPointTableColumns.stuck_by,
  StuckPointTableColumns.risk_exposure,
  StuckPointTableColumns.comments,
  StuckPointTableColumns.status,
  StuckPointTableColumns.moreOptions
]


/**
 * Global Stuck Points
 * @returns 
 */
const GlobalStuckPoints = () => {

  const userRole = useSelector((state) => selectCurrentUserRole(state));
  const isContact = userRole === "contractor_contact"
  const [filters, setFilters] = useState(initialState);

  const {
    data: { data: stuckPoints = [], lastPage, total = 0 } = {},
    isLoading,
    isFetching,
  } = useGetGlobalStuckPointsQuery({ ...filters });

  const { data: companies = [], isLoading: isLoadingCompanies } = useGetCompaniesQuery();
  const workspaceOwnerCompany = companies.find((company) => company.workspace_owner);
  // const { data: companyDetails, isLoading: isLoadingUserCompany } = useGetCompanyByUserIdQuery({ userId });
  const { data: contacts = [], isLoading: isLoadingUsers } = useGetUsersByCompanyIdQuery({ companyId: workspaceOwnerCompany?.id, }, { skip: !workspaceOwnerCompany });


  const handleSearch = debounce((event) => {
    const searchQuery = event.target.value.trim();
    setFilters((filters) => ({ ...filters, search: searchQuery || undefined, page: 1 }));
  }, 500);

  const handleSelectCompanies = (companyId) => {
    setFilters((filters) => ({
      ...filters,
      company: filters.company.includes(companyId)
        ? filters.company.filter((id) => id != companyId)
        : [...filters.company, companyId],
      page: 1,
    }));
  }

  const handleSelectUser = (userId) => {
    setFilters((filters) => ({
      ...filters,
      user: filters.user.includes(userId)
        ? filters.user.filter((id) => id != userId)
        : [...filters.user, userId],
      page: 1,
    }));
  }

  const handleChangePage = (event, newPage) => {
    setFilters((prevFilters) => ({ ...prevFilters, page: newPage }));
  };

  const companyOptions = useMemo(() => {
    return [{ id: -1, name: "Unassigned" }, ...companies];
  }, [companies]);

  const userOptions = useMemo(() => {
    return [{ id: -1, first_name: "Unassigned", last_name: "" }, ...contacts];
  }, [contacts])


  return <ContentLayout>
    <Container maxWidth={'xl'}>
      <Box pt={2}>
        <Typography variant={'h4'}>
          Workspace <LabelComponent labelKey="stuck_point" plural={true} />
        </Typography>
      </Box>
      <Grid item>
        <Alert severity={'info'} sx={{ py: '1px' }}>
          {total} Match Selected Filters
        </Alert>
      </Grid>
      <Paper elevation={2}>
        <Toolbar style={{ position: 'sticky', left: '0px' }}>
          <Grid container item>
            <SearchBox
              bgcolor={'grey.200'}
              borderRadius={1}
              fullWidth={false}
              placeholder="Search By Task Name"
              onChange={handleSearch}
            />
            <Grid container item xs justifyContent={'flex-end'} spacing={1}>
              <Grid item>
                <StuckPointStatusMenu
                  selectedStatus={filters.resolved}
                  setFilters={setFilters}
                />
              </Grid>
              {!isContact && <Grid item>
                <DropdownFilterMenu
                  handleSelect={handleSelectCompanies}
                  icon={<CompanyIcon style={{ height: "18px" }} />}
                  label={{ plural: "Companies", singular: "Company" }}
                  options={companyOptions}
                  selectedIds={filters.company}
                />
              </Grid>}
              {!isContact && <Grid item>
                <DropdownFilterMenu
                  handleSelect={handleSelectUser}
                  icon={<PersonIcon sizes="small" />}
                  label={{ plural: "Responsible Users", singular: "Responsible Use" }}
                  options={userOptions}
                  selectedIds={filters.user}
                />
              </Grid>}
              <Grid item>
                <StuckPointRiskExposureMenu
                  selectedRiskExposure={filters.risk_exposure}
                  setFilters={setFilters}
                />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        <StuckPointTable
          columns={tableColumns}
          isFetching={isFetching}
          isLoading={isLoading}
          stuckPoints={stuckPoints}
        />
        <Pagination
          color="primary"
          count={lastPage}
          disabled={isFetching}
          page={filters?.page}
          shape="rounded"
          sx={{ p: 1 }}
          onChange={handleChangePage}
        />
      </Paper>
    </Container>
  </ContentLayout>
}

/**
  Dropdown Filter Menu
* @param {Array} options
* @param {String} label
* @param {Array} selectedIds
* @param {Object} handleSelect
* @returns 
*/
const DropdownFilterMenu = ({ options, handleSelect, selectedIds, label, icon }) => {

  const [openMenu, setOpenMenu] = useState(false);
  const [searchText, setSearchText] = useState('');

  const handleCloseMenu = () => {
    setOpenMenu(null);
    setSearchText('');
  };

  const filteredOptions = useMemo(() => {
    const lowerCaseSearchText = searchText?.toLowerCase();
    return options?.filter(({ name, first_name, last_name }) =>
      (name || `${first_name} ${last_name}`).toLowerCase().includes(lowerCaseSearchText)
    );
  }, [searchText, options]);

  let displayLabel = <>All {label.plural}</>;
  if (selectedIds?.length) {
    displayLabel = <>{selectedIds?.length} Selected {selectedIds?.length > 1 ? label.plural : label.singular}</>
  }

  return <>
    <Chip
      color={selectedIds?.length ? 'secondary' : 'default'}
      icon={icon}
      label={displayLabel}
      variant={'filled'}
      onClick={(event) => setOpenMenu(event.currentTarget)}
    />
    <StyledMenu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={Boolean(openMenu)}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={handleCloseMenu}
    >
      <ListSubheader>
        <TextField
          autoFocus
          fullWidth
          placeholder="Type to search..."
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              e.stopPropagation();
            }
          }}
        />
      </ListSubheader>
      {filteredOptions?.map((option) => (
        <ListItem
          disablePadding
          key={option.id}
        >
          <ListItemButton dense onClick={(event) => handleSelect(option.id)}>
            <ListItemIcon>
              <Checkbox checked={selectedIds?.includes(option.id)} />
            </ListItemIcon>
            <ListItemText primary={option.name ? option.name : `${option.first_name} ${option.last_name}`} />
          </ListItemButton>
        </ListItem>
      ))}
    </StyledMenu>
  </>

}

export default GlobalStuckPoints