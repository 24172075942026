import { useContext, useState } from 'react';
import List from '@mui/material/List';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import MoreVert from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import StuckPointResolutionForm from './StuckPointResolutionForm';
import { ConfirmDeleteDialog, OptionsMenu } from '@blackhyve/common';
import { EditContext } from 'components/common/v3/DisplayWithEditAccess';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Edit, AssignmentTurnedInOutlined } from '@mui/icons-material';
import {
  useDeleteStuckPointMutation,
  useReopenStuckPointMutation,
  useResendStuckPointNotificationMutation,
  useUpdateStuckPointMutation,
} from '../store/stuckPoint.api';
import { selectCurrentUser } from 'features/auth';
import isObject from 'lodash/isObject';
import { AddUpdateStuckPointDialog } from './AddUpdateStuckPointDialog';
import { format } from 'date-fns';
import { Label as LabelComponent } from 'features/labels/components/Label';

/**
 * Stuck Point More Options
 * @param {Object} stuckPoint
 * @returns
 */
const StuckPointMoreOptions = ({ stuckPoint }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const canEdit = useContext(EditContext);
  const currentUser = useSelector((state) => selectCurrentUser(state));

  const openedById = isObject(stuckPoint.opened_by)
    ? stuckPoint.opened_by.id
    : stuckPoint.opened_by;
  const isOwnerOfStuckPoint =
    currentUser.id === openedById ||
    currentUser.role === 'admin' ||
    currentUser.role === 'super_admin';

  const [isResolveDialogOpen, setIsResolveDialogOpen] = useState(false);
  const [isReopenDialogOpen, setIsReopenDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);

  const [deleteStuckPoint] = useDeleteStuckPointMutation();
  const [resendNotification] = useResendStuckPointNotificationMutation();

  const handleDelete = () => {
    deleteStuckPoint({
      id: stuckPoint?.id,
    }).then(() => {
      setIsDeleteDialogOpen(false);
    });
  };

  const handleCloseStuckPointDialog = () => setIsResolveDialogOpen(false);

  const handleCloseReopenStuckPoint = () => setIsReopenDialogOpen(false);

  const handleResendNotification = () => {
    setLoading(true);
    resendNotification({ id: stuckPoint.id })
      .then(() => {
        enqueueSnackbar('Notification Sent successfully', { variant: 'success' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [reopenStuckPoint] = useReopenStuckPointMutation();
  const [updateStuckPoint] = useUpdateStuckPointMutation();

  const handleReopen = (data) => {
    return reopenStuckPoint({
      id: data.id,
      title: data.title,
      description: data.description,
      companies_to_blame: data.companies_to_blame,
      responsible_users: data.responsible_users,
      affects_pr: data.affects_pr,
      is_notify: data.is_notify,
      opened_on: format(data.opened_on, 'yyyy-MM-dd'),
    });
  };

  const handleUpdate = (data) => {
    return updateStuckPoint({
      id: data.id,
      title: data.title,
      description: data.description,
      companies_to_blame: data.companies_to_blame,
      responsible_users: data.responsible_users,
      affects_pr: data.affects_pr,
      is_notify: data.is_notify,
      opened_on: format(data.opened_on, 'yyyy-MM-dd'),
      risk_exposure: data.risk_exposure,
      resolution_plan: data.resolution_plan,
    });
  };

  return (
    <>
      {loading && (
        <Backdrop open={true} style={{ zIndex: 13 }} sx={{ color: '#fff' }}>
          <CircularProgress className="loading" />
        </Backdrop>
      )}
      <OptionsMenu
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        triggerButton={
          <IconButton size={'small'} sx={{ pointerEvents: 'auto' }}>
            <MoreVert />
          </IconButton>
        }
      >
        {(handleClose) => (
          <List dense>
            {isOwnerOfStuckPoint && !stuckPoint?.is_resolved ? (
              <>
                {isOwnerOfStuckPoint && !stuckPoint?.is_resolved && (
                  <ListItemButton
                    onClick={() => {
                      handleClose();
                      setIsUpdateDialogOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </ListItemButton>
                )}
                <ListItemButton
                  onClick={() => {
                    handleClose();
                    setIsResolveDialogOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <AssignmentTurnedInOutlined />
                  </ListItemIcon>
                  <ListItemText>Resolve</ListItemText>
                </ListItemButton>
                {isOwnerOfStuckPoint && (
                  <ListItemButton
                    onClick={() => {
                      handleClose();
                      handleResendNotification();
                    }}
                  >
                    <ListItemIcon>
                      <EmailOutlined />
                    </ListItemIcon>
                    <ListItemText>Re-notify</ListItemText>
                  </ListItemButton>
                )}
              </>
            ) : null}
            {stuckPoint?.is_resolved ? (
              <ListItemButton
                onClick={() => {
                  handleClose();
                  setIsReopenDialogOpen(true);
                }}
              >
                <ListItemIcon>
                  <SettingsBackupRestoreIcon />
                </ListItemIcon>
                <ListItemText>Reopen</ListItemText>
              </ListItemButton>
            ) : null}
            {isOwnerOfStuckPoint && (
              <ListItemButton
                onClick={() => {
                  handleClose();
                  setIsDeleteDialogOpen(true);
                }}
              >
                <ListItemIcon>
                  <Delete color={'error'} />
                </ListItemIcon>
                <ListItemText sx={{ color: 'error.main' }}>Delete</ListItemText>
              </ListItemButton>
            )}
          </List>
        )}
      </OptionsMenu>
      {isReopenDialogOpen && (
        <AddUpdateStuckPointDialog
          handleClose={handleCloseReopenStuckPoint}
          handleSave={handleReopen}
          label="Reopen"
          open={isReopenDialogOpen}
          projectId={stuckPoint.project_id}
          stuckPoint={stuckPoint}
        />
      )}
      {isUpdateDialogOpen && (
        <AddUpdateStuckPointDialog
          handleClose={() => setIsUpdateDialogOpen(false)}
          handleSave={handleUpdate}
          label="Update"
          open={isUpdateDialogOpen}
          projectId={stuckPoint.project_id}
          stuckPoint={stuckPoint}
        />
      )}
      {isResolveDialogOpen && (
        <StuckPointResolutionForm
          handleClose={handleCloseStuckPointDialog}
          open={isResolveDialogOpen}
          stuckPoint={stuckPoint}
        />
      )}
      {isDeleteDialogOpen ? (
        <ConfirmDeleteDialog
          handleDelete={handleDelete}
          item={<LabelComponent labelKey="stuck_point" />}
          open={isDeleteDialogOpen}
          handleClose={() => {
            setIsDeleteDialogOpen(false);
          }}
        />
      ) : null}
    </>
  );
};

export default StuckPointMoreOptions;
