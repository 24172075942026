

import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import LabelIcon from '@mui/icons-material/Label';
import TagDropdown from 'features/tags/components/TagDropdown';
import { ActionButtons } from 'features/gantt/components/TaskDetailDrawer';

/**
 * Task bulk edit tag menu item
 * @param {*} param0 
 * @returns 
 */
const TaskBulkEditTagMenuItem = ({ handleUpdate }) => {


  const [anchorEl, setAnchorEl] = useState(undefined);
  const [selectedTags, setSelectedTags] = useState([])

  const handleSave = () => {
    handleUpdate(selectedTags);
    handleClose();
    setSelectedTags([]);
  }

  const handleClose = () => setAnchorEl(false);


  return <>
    <Tooltip arrow title="Tags">
      <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
        <LabelIcon />
      </Button>
    </Tooltip>
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      slotProps={{
        paper: {
          sx: {
            width: 400, overflowY: "auto", borderRadius: 1, padding: "10px 10px"
          }
        }
      }}
      transformOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      onClose={handleClose}
    >
      <TagDropdown
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
      <ActionButtons handleClose={handleClose} handleUpdate={handleSave} />
    </Popover>
  </>


}

export default TaskBulkEditTagMenuItem