import { Box, Container, Typography } from '@mui/material';
import { ContentLayout } from 'components/layouts/ContentLayout';
import { useParams } from 'react-router-dom';
import { ContactsTable } from '../components/ContactsTable';

export const Contacts = () => {
  const { companyId } = useParams();

  return (
    <ContentLayout>
      <Container maxWidth={'xl'}>
        <Box pt={2}>
          <Typography variant={'h4'}>Contacts</Typography>
          <ContactsTable companyId={companyId} />
        </Box>
      </Container>
    </ContentLayout>
  );
};
