import { addWorkdays, differenceInWorkdays, parseDate } from '@blackhyve/utilities/dates';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  FormLabel,
  Grid,
  InputAdornment,
  Link,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { ValueWithLabel } from 'components/ValueChange';
import {
  formatDate,
  addYears,
  format,
  isAfter,
  isBefore,
  isEqual,
  isValid,
  startOfDay,
} from 'date-fns';
import {
  getProductionRatingFromEndDate,
  getProductionRatingFromPercentComplete,
} from 'features/tasks/utils/getProductionRating';
import { round } from 'lodash';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useCreateJobwalkMutation } from '../store/jobwalk.api';

const getFormValues = (task, holidays) => {
  const today = startOfDay(new Date());
  if (Object.keys(task).length > 0) {
    const actualStart = task.actual_start
      ? parseDate(task.actual_start)
      : parseDate(task.scheduled_start);
    const forecastedEndDate = parseDate(task.forecasted_end);
    const endDate = +today < +forecastedEndDate ? forecastedEndDate : today;
    const { percentComplete, productionRating, earnedDays, earnedPlannedDays } =
      getProductionRatingFromEndDate({
        endDate: endDate,
        startDate: actualStart,
        effectiveDate: today,
        scheduledDuration: task.duration_days,
        workdayCalendar: task.workday_calendar,
        holidays,
        // daysBlocked: task?.days_blocked,
      });
    const daysRemaining = differenceInWorkdays(
      +today < +actualStart ? actualStart : today,
      endDate,
      {
        workdayCalendar: task.workday_calendar,
        holidays,
      }
    );

    return {
      taskId: task.id,
      percent_complete: percentComplete,
      earned_days: earnedDays || 0,
      earned_planned_days: earnedPlannedDays || 0,
      pr: productionRating || 0,
      pace: task.pace || 0,
      days_remaining: daysRemaining,
      forecasted_end: endDate,
      actual_start: actualStart,
      effective_date: today,
    };
  } else {
    return {};
  }
};

const defaultValues = {
  percent_complete: 0,
  earned_days: 0,
  earned_planned_days: 0,
  pr: 0,
  pace: 0,
  days_remaining: 0,
  forecasted_end: new Date(),
  actual_start: new Date(),
  effective_date: new Date(),
  notes: '',
};

export const JobwalkInput = ({ task, onSuccess, shouldAutoschedule = false, holidays = [] }) => {
  const today = startOfDay(new Date());
  const memoFormValues = useMemo(() => getFormValues(task, holidays), [task, holidays]);
  const { control, setValue, watch, handleSubmit, formState, trigger, reset, getValues } = useForm({
    values: memoFormValues,
    defaultValues,
    mode: 'onChange',
  });

  const { isValid: isFormValid, isValidating: isFormValidating } = formState;

  const [createJobwalk, { isLoading, isSuccess, isError }] = useCreateJobwalkMutation();

  const [lastTouched, setLastTouched] = useState('forecasted_end');

  const scheduledStartDate = task.scheduled_start ? parseDate(task.scheduled_start) : today;
  const scheduledEndDate = task.scheduled_end ? parseDate(task.scheduled_end) : today;
  const originalForecastedEndDate = task.forecasted_end ? parseDate(task.forecasted_end) : today;
  const scheduledDuration = task.duration_days || 0;
  const workdayCalendar = task.workday_calendar;

  const [prefillOption, setPrefillOption] = useState('Custom');
  const [isNoteEnabled, setIsNoteEnabled] = useState(false);

  const hasActualStart = Boolean(task?.actual_start);
  const forecastedEndDate = watch('forecasted_end');
  const pr = watch('pr');
  const pace = watch('pace');
  const percentComplete = watch('percent_complete');
  const actualStartDate = watch('actual_start');
  const effectiveDate = watch('effective_date');

  const daysAdded =
    differenceInWorkdays(actualStartDate, forecastedEndDate, { workdayCalendar, holidays }) +
    1 -
    task.duration_days;

  const disabled = prefillOption === 'Skip';

  const onSubmit = async (formData) => {
    await createJobwalk({
      ...formData,
      forecasted_end: formData.forecasted_end && format(formData.forecasted_end, 'yyyy/MM/dd'),
      effective_date: formData.effective_date && format(formData.effective_date, 'yyyy/MM/dd'),
      actual_start: formData.actual_start && format(formData.actual_start, 'yyyy/MM/dd'),
      should_autoschedule: shouldAutoschedule,
    })
      .unwrap()
      .then(({ data }) => {
        onSuccess(data.updated_tasks[0]);
      })
      .catch((error) => console.error(error));
  };

  const updateKPIs = (forecastedEndDate, actualStart, effectiveDate) => {
    const { earnedDays, earnedPlannedDays, productionRating, percentComplete } =
      getProductionRatingFromEndDate({
        endDate: forecastedEndDate,
        startDate: actualStart,
        effectiveDate,
        scheduledDuration,
        workdayCalendar,
        holidays,
        // daysBlocked: task?.days_blocked,
      });

    setValue('actual_start', actualStart);
    setValue('effective_date', effectiveDate);
    setValue('forecasted_end', forecastedEndDate);
    setValue(
      'days_remaining',
      differenceInWorkdays(effectiveDate, forecastedEndDate, { workdayCalendar, holidays })
    );
    setValue('earned_days', earnedDays);
    setValue('earned_planned_days', earnedPlannedDays);
    setValue('pr', round(productionRating, 2));
    setValue(
      'pace',
      differenceInWorkdays(forecastedEndDate, scheduledEndDate, {
        workdayCalendar,
        holidays,
      })
    );
    trigger();
    return { earnedDays, earnedPlannedDays, productionRating, percentComplete };
  };

  const updateKPIsPercentComplete = (percentComplete, startDate, effectiveDate) => {
    const { forecastedEndDate: newForecastedEndDate } = getProductionRatingFromPercentComplete({
      percentComplete,
      startDate,
      effectiveDate,
      scheduledDuration,
      // daysBlocked: task?.days_blocked,
      workdayCalendar,
      holidays,
    });

    return updateKPIs(
      newForecastedEndDate,
      hasActualStart ? actualStartDate : scheduledStartDate,
      effectiveDate
    );
  };

  const handleSelectPrefillOption = (event, prefillOption) => {
    if (prefillOption !== null) {
      switch (prefillOption) {
        case 'On Target':
          // const onTargetPercentComplete = String(
          //   Math.min(
          //     Math.max(
          //       (differenceInWorkdays(actualStartDate, today, {
          //         workdayCalendar,
          //       }) +
          //         1) /
          //         scheduledDuration,
          //       0
          //     ),
          //     1
          //   )
          // );
          // setLastTouched('percent_complete');
          // setValue('percent_complete', onTargetPercentComplete);
          // updateKPIsPercentComplete(
          //   onTargetPercentComplete,
          //   hasActualStart ? actualStartDate : scheduledStartDate,
          //   today
          // );
          setLastTouched('forecasted_end');
          const { percentComplete: newPercentComplete } = updateKPIs(
            scheduledEndDate,
            hasActualStart ? actualStartDate : scheduledStartDate,
            today
          );
          setValue('percent_complete', newPercentComplete);
          break;
        case 'Finished On Time':
          setLastTouched('percent_complete');
          setValue('percent_complete', 1);
          updateKPIsPercentComplete(
            1,
            hasActualStart ? actualStartDate : scheduledStartDate,
            scheduledEndDate
          );
          break;
        case 'No Progress':
          setLastTouched('percent_complete');
          setValue('percent_complete', task.percent_complete);
          updateKPIsPercentComplete(
            task.percent_complete,
            hasActualStart ? actualStartDate : scheduledStartDate,
            today
          );
          break;
        case 'Skip':
          setLastTouched('percent_complete');
          setValue('actual_start', null);
          setValue('percent_complete', 0);
          setValue('forecasted_end', null);
          setValue('days_remaining', '');
          setValue('pr', '');
          setValue('pace', '');
          setValue('effective_date', today);
          break;
        case 'Custom':
          //When custom is selected it maintains the current pace this is also the default value
          setLastTouched('forecasted_end');
          const { percentComplete } = updateKPIs(
            +today < +originalForecastedEndDate ? originalForecastedEndDate : today,
            hasActualStart ? actualStartDate : scheduledStartDate,
            today
          );
          setValue('percent_complete', percentComplete);
          break;
        default:
          break;
      }
      reset(undefined, { keepValues: true });
      setPrefillOption(prefillOption);
    }
  };

  const onUpdatePercentComplete = (percentComplete) => {
    const { forecastedEndDate: newForecastedEndDate } = getProductionRatingFromPercentComplete({
      percentComplete,
      startDate: actualStartDate,
      effectiveDate,
      scheduledDuration,
      // daysBlocked: task?.days_blocked,
      workdayCalendar,
      holidays,
    });
    updateKPIs(newForecastedEndDate, actualStartDate, effectiveDate);
  };

  const onUpdateDaysRemaining = (daysRemaining) => {
    const newForecastedEndDate = addWorkdays(effectiveDate, daysRemaining, {
      workdayCalendar,
      holidays,
    });
    const { percentComplete } = updateKPIs(newForecastedEndDate, actualStartDate, effectiveDate);
    setValue('percent_complete', percentComplete);
  };

  const onUpdateForecastedEnd = (forecastedEndDate) => {
    const { percentComplete: newPercentComplete } = updateKPIs(
      forecastedEndDate,
      actualStartDate,
      effectiveDate
    );
    setValue('percent_complete', newPercentComplete);
  };

  const onUpdateEffectiveDate = (effectiveDate) => {
    if (lastTouched === 'percent_complete') {
      updateKPIsPercentComplete(percentComplete, actualStartDate, effectiveDate);
    } else {
      const { percentComplete } = updateKPIs(forecastedEndDate, actualStartDate, effectiveDate);
      setValue('percent_complete', percentComplete);
    }
  };

  const onUpdateActualStartDate = (actualStartDate) => {
    if (lastTouched === 'percent_complete') {
      updateKPIsPercentComplete(percentComplete, actualStartDate, effectiveDate);
    } else {
      const { percentComplete } = updateKPIs(forecastedEndDate, actualStartDate, effectiveDate);
      setValue('percent_complete', percentComplete);
    }
  };

  return (
    <Box p={1}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ToggleButtonGroup
            exclusive
            fullWidth
            color={'primary'}
            size={'small'}
            value={prefillOption}
            onChange={handleSelectPrefillOption}
          >
            {(isBefore(scheduledStartDate, today) || isEqual(scheduledStartDate, today)) &&
              (isAfter(scheduledEndDate, today) || isEqual(scheduledEndDate, today)) && (
                <ToggleButton value={'On Target'}>On Target</ToggleButton>
              )}
            {isBefore(scheduledEndDate, today) &&
              (isAfter(scheduledEndDate, actualStartDate) ||
                isEqual(scheduledEndDate, actualStartDate)) && (
                <ToggleButton value={'Finished On Time'}>Finished On Time</ToggleButton>
              )}
            {task.status === 'active' && (
              <ToggleButton value={'No Progress'}>{'No Progress'}</ToggleButton>
            )}
            {/* {!hasActualStart && isBefore(scheduledStartDate, today) && (
              <ToggleButton value={'Late Start'}>{'Late Start'}</ToggleButton>
            )} */}
            {task.status === 'todo' && (
              <ToggleButton value={'Skip'}>
                {isAfter(scheduledStartDate, today) ? 'Starting On Time' : 'Not Started'}
              </ToggleButton>
            )}
            <ToggleButton value={'Custom'}>Custom</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {!disabled && (
          <>
            <Grid container item spacing={1}>
              <Grid item xs>
                <FormLabel htmlFor="days-remaining">Days Remaining</FormLabel>
                <Controller
                  control={control}
                  name={'days_remaining'}
                  render={({ field, fieldState: { error, invalid } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={invalid}
                      helperText={error?.message}
                      id={'days-remaining'}
                      size={'small'}
                      type={'number'}
                      value={parseFloat(field.value).toFixed(0)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">Days</InputAdornment>,
                      }}
                      onChange={(e) => field.onChange(Math.max(0, e.target.value))}
                    />
                  )}
                  rules={{
                    required: 'Field is required',
                    min: { value: 0, message: 'Must be a positive number' },
                    onChange: (e) => {
                      setPrefillOption('Custom');
                      setLastTouched(e.target.name);
                      onUpdateDaysRemaining(e.target.value);
                    },
                  }}
                />
              </Grid>
              <Grid item xs>
                <FormLabel htmlFor="forecasted-end">Finish On</FormLabel>
                <Controller
                  control={control}
                  name={'forecasted_end'}
                  render={({
                    field: { onChange, value, ref, ...field },
                    fieldState: { error, invalid },
                  }) => (
                    <DatePicker
                      minDate={effectiveDate}
                      ref={ref}
                      value={value}
                      shouldDisableDate={(date) =>
                        workdayCalendar[
                          date.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase()
                        ] === '0' || holidays.includes(formatDate(date, 'yyyy-MM-dd'))
                      }
                      slotProps={{
                        textField: {
                          id: 'forecasted-end',
                          helperText: error?.message,
                          size: 'small',
                          error: invalid,
                          fullWidth: true,
                          ...field,
                        },
                      }}
                      onChange={onChange}
                    />
                  )}
                  rules={{
                    required: 'Field is required',
                    validate: {
                      isDate: (v) => isValid(v) || 'Invalid Date',
                      isAfterEffective: (value, values) =>
                        +value >= +values.effective_date || 'Cannot be before effective date',
                      isAfterStart: (value, values) =>
                        +value >= +values.actual_start || 'Cannot be before actual start date',
                      isNotWeekend: (value) =>
                        workdayCalendar[
                          value.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase()
                        ] === '1' || 'Cannot be on a non working day',
                      isLessThanTwoYears: (value) =>
                        +value <= +addYears(actualStartDate, 2) ||
                        'Forecasted End Must Be With In 2 Years Of Actual Start',
                    },
                    onChange: (e) => {
                      setPrefillOption('Custom');
                      setLastTouched(e.target.name);
                      onUpdateForecastedEnd(e.target.value);
                    },
                  }}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FormLabel htmlFor="percent-complete">Percent Complete</FormLabel>
                <Controller
                  control={control}
                  name={'percent_complete'}
                  render={({ field, fieldState: { error, invalid } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={invalid}
                      helperText={error?.message}
                      id="percent-complete"
                      size={'small'}
                      type={'number'}
                      value={field.value === '' ? '' : parseFloat(field.value * 100).toFixed(0)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      onBlur={field.onBlur}
                      onChange={(e) =>
                        field.onChange(Math.min(100, Math.max(0, e.target.value)) / 100)
                      }
                    />
                  )}
                  rules={{
                    required: 'Percent Complete Is Required',
                    min: { value: 0, message: 'Must be between 0 and 100' },
                    max: { value: 100, message: 'Must be between 0 and 100' },
                    onChange: (e) => {
                      setPrefillOption('Custom');
                      setLastTouched(e.target.name);
                      onUpdatePercentComplete(e.target.value);
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormLabel htmlFor="actual-start">Actual Start</FormLabel>
              <Controller
                control={control}
                name={'actual_start'}
                render={({
                  field: { onChange, value, ref, ...field },
                  fieldState: { error, invalid },
                }) => (
                  <DatePicker
                    disableFuture
                    disabled={hasActualStart}
                    maxDate={forecastedEndDate}
                    value={value}
                    shouldDisableDate={(date) =>
                      workdayCalendar[
                        date.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase()
                      ] === '0' || holidays.includes(formatDate(date, 'yyyy-MM-dd'))
                    }
                    slotProps={{
                      textField: {
                        id: 'actual-start',
                        fullWidth: true,
                        size: 'small',
                        helperText: error?.message,
                        error: invalid,
                        ...field,
                      },
                      actionBar: {
                        actions: ['today'],
                      },
                    }}
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: 'Field is required',
                  validate: {
                    isDate: (v) => isValid(v) || 'Invalid Date',
                    isBeforeForecasted: (value, values) =>
                      +value <= +values.forecasted_end || 'Cannot be later than forecasted end',
                    isNotWeekend: (value) =>
                      workdayCalendar[
                        value.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase()
                      ] === '1' || 'Cannot be on a non working day',
                    isBeforeToday: (value) => +value <= +today || 'Cannot be in the future',
                  },
                  onChange: (e) => {
                    setPrefillOption('Custom');
                    onUpdateActualStartDate(e.target.value);
                  },
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <FormLabel htmlFor="effective-date">Effective Date</FormLabel>
          <Controller
            control={control}
            name={'effective_date'}
            render={({
              field: { onChange, value, ref, ...field },
              fieldState: { error, invalid },
            }) => (
              <DatePicker
                disableFuture
                // disabled={disabled}
                maxDate={forecastedEndDate}
                minDate={actualStartDate}
                ref={ref}
                value={value}
                slotProps={{
                  textField: {
                    id: 'effective-date',
                    fullWidth: true,
                    size: 'small',
                    helperText: error?.message,
                    error: invalid,
                    ...field,
                  },
                  actionBar: {
                    actions: ['today'],
                  },
                }}
                onChange={onChange}
              />
            )}
            rules={{
              required: 'Field is required',
              onChange: (e) => {
                setPrefillOption('Custom');
                onUpdateEffectiveDate(e.target.value);
              },
              validate: {
                isDate: (v) => isValid(v) || 'Invalid Date',
                isBeforeForecasted: (value, values) =>
                  !values.forecasted_end ||
                  +value <= +values.forecasted_end ||
                  'Cannot be later than forecasted end',
                isAfterStart: (value, values) =>
                  !values.actual_start ||
                  +value >= +values.actual_start ||
                  'Cannot be earlier than actual start',
                isBeforeToday: (value) => +value <= +today || 'Cannot be in the future',
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Link
            component={'button'}
            htmlFor={'notes'}
            onClick={() => setIsNoteEnabled(!isNoteEnabled)}
          >
            {isNoteEnabled ? 'Remove Note' : 'Add Note'}
          </Link>
          {isNoteEnabled && (
            <Controller
              control={control}
              name={'notes'}
              render={({ field, fieldState: { error, invalid } }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  error={invalid}
                  helperText={error?.message}
                  id={'notes'}
                  minRows={3}
                  sx={{ mt: 1 }}
                />
              )}
            />
          )}
        </Grid>
        {!disabled && (
          <Grid container item spacing={3}>
            <Grid item>
              <ValueWithLabel
                label={'Forecasted End'}
                value={isValid(forecastedEndDate) && forecastedEndDate.toLocaleDateString()}
                initialValue={
                  isValid(originalForecastedEndDate) &&
                  originalForecastedEndDate.toLocaleDateString()
                }
                ValueProps={{
                  color: +forecastedEndDate <= +scheduledEndDate ? 'success.main' : 'error.main',
                }}
              />
            </Grid>
            <Grid item>
              <ValueWithLabel
                label={'Duration Added'}
                value={`${daysAdded} ${daysAdded === 1 ? 'day' : 'days'}`}
                ValueProps={{ color: daysAdded <= 0 ? 'success.main' : 'error.main' }}
              />
            </Grid>
            <Grid item>
              <ValueWithLabel
                initialValue={task.pace}
                label={'Pace'}
                value={pace}
                ValueProps={{ color: pace >= 0 ? 'success.main' : 'error.main' }}
              />
            </Grid>
            <Grid item>
              <ValueWithLabel
                initialValue={parseFloat(task.pr * 100).toFixed(0) + '%'}
                label={'PR'}
                value={parseFloat(pr * 100).toFixed(0) + '%'}
                ValueProps={{ color: pr >= 1 ? 'success.main' : 'error.main' }}
              />
            </Grid>
          </Grid>
        )}
        <Grid item ml={'auto'}>
          <LoadingButton
            disabled={!isFormValid}
            loading={isLoading}
            variant={'contained'}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
