import { PriorityHigh } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { StyledMenu } from 'features/activityFeeds/assets/activityFeedStyle';
import { useState } from 'react';
import { riskExposureColorEnum, riskExposureEnum } from '../StuckPointEnums';
import { ListItem } from '@mui/material';

/**
 * StuckPoint Risk Exposure Menu
 * @param {Number} selectedRiskExposure
 * @param {Object} setFilters
 * @returns
 */
export const StuckPointRiskExposureMenu = ({ selectedRiskExposure, setFilters }) => {
  const [openMenu, setOpenMenu] = useState(false);

  const options = [
    { name: 'All', value: undefined },
    { name: 'Low', value: 'low' },
    { name: 'Medium', value: 'medium' },
    { name: 'High', value: 'high' },
  ];

  const handleClose = () => {
    setOpenMenu(null);
  };

  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  return (
    <>
      <Chip
        color={riskExposureColorEnum[selectedRiskExposure]}
        icon={<PriorityHigh fontSize="small" />}
        label={`${riskExposureEnum[selectedRiskExposure] ?? riskExposureEnum.all} Risk Exposures`}
        sx={{ textTransform: 'capitalize' }}
        variant={'filled'}
        onClick={handleClick}
      />

      <StyledMenu
        anchorEl={openMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={Boolean(openMenu)}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
      >
        <List dense disablePadding>
          {options.map((option) => {
            return (
              <ListItem>
                <Chip
                  clickable
                  color={riskExposureColorEnum[option.value]}
                  key={option.name}
                  label={option.name}
                  sx={{ width: 1 }}
                  onClick={() =>
                    setFilters((prevFilters) => ({ ...prevFilters, risk_exposure: option.value }))
                  }
                >
                  <ListItemText primary={option.name} />
                </Chip>
              </ListItem>
            );
          })}
        </List>
      </StyledMenu>
    </>
  );
};
