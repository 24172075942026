import { useState } from 'react';
import List from '@mui/material/List';
import Edit from '@mui/icons-material/Edit';
import CreateTagDialog from './CreateTagDialog';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import MoreVert from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { ConfirmDeleteDialog, OptionsMenu } from '@blackhyve/common';
import { useDeleteTagMutation } from '../store/tag.api';



/**
 * Tag Menu
 * @param {Object} tag 
   * @param {Object} tag.id 
   * @param {Object} tag.name
   * @param {Object} tag.type
   * @param {Object} tag.color
 * @returns 
 */
const TagMenu = ({ tag }) => {

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteTag] = useDeleteTagMutation();

  const handleDelete = () => {
    deleteTag(tag.id)
  }

  const handleCloseEditDialog = () => setIsEditDialogOpen(false)

  const handleCloseDeleteDialog = () => setIsDeleteDialogOpen(false)

  return <>
    <OptionsMenu
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      triggerButton={
        <IconButton size={'small'} sx={{ pointerEvents: 'auto' }}>
          <MoreVert />
        </IconButton>
      }
    >
      {(handleClose) => (
        <List dense>
          <ListItemButton
            onClick={() => {
              handleClose();
              setIsEditDialogOpen(true);
            }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              handleClose();
              setIsDeleteDialogOpen(true);
            }}
          >
            <ListItemIcon>
              <Delete color={'error'} />
            </ListItemIcon>
            <ListItemText sx={{ color: 'error.main' }}>Delete</ListItemText>
          </ListItemButton>

        </List>
      )}
    </OptionsMenu>
    {isEditDialogOpen &&
      <CreateTagDialog
        handleClose={handleCloseEditDialog}
        open={true}
        tag={tag}
      />}
    {isDeleteDialogOpen &&
      <ConfirmDeleteDialog
        handleClose={handleCloseDeleteDialog}
        handleDelete={handleDelete}
        item={'Tag'}
        open={isDeleteDialogOpen}
      />}
  </>

}

export default TagMenu;