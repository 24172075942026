import { useCallback, useState } from 'react';
import { useEventListener } from 'usehooks-ts';

export const usePrintState = ({ onBeforePrint, onAfterPrint } = {}) => {
  const [isPrinting, setIsPrinting] = useState(false);

  const handleBeforePrint = useCallback(() => {
    setIsPrinting(true);
    if (onBeforePrint) onBeforePrint();
  }, [onBeforePrint]);

  const handleAfterPrint = useCallback(() => {
    setIsPrinting(false);
    if (onAfterPrint) onAfterPrint();
  }, [onAfterPrint]);

  useEventListener('beforeprint', handleBeforePrint);
  useEventListener('afterprint', handleAfterPrint);

  return { isPrinting };
};
