import { Alert, Box, Grid, LinearProgress } from '@mui/material';
import { times } from 'lodash';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useGetStepsQuery, useReorderStepsMutation } from '../store/step.api';
import { Step } from './Step';
import { StepSkeleton } from './StepSkeleton';

export const Steps = ({ entityId, entityType, projectId }) => {
  const [reorderSteps, { isLoading: isLoadingReorderSteps, isFetching: isFetchingReorderSteps }] =
    useReorderStepsMutation();

  const { data: steps = [], isLoading, isFetching } = useGetStepsQuery({ entityId, entityType });

  const handleUpdateStepPriority = (result) => {
    if (!result.destination) return;

    const newStepPriority = [...steps];
    const step = newStepPriority.splice(result.source.index, 1);
    newStepPriority.splice(result.destination.index, 0, ...step);

    reorderSteps({
      entityId,
      entityType,
      stepIds: newStepPriority.map((step) => step.id),
    });
  };

  return (
    <DragDropContext onDragEnd={handleUpdateStepPriority}>
      <Droppable droppableId={'step'} type={entityType}>
        {(provided) => (
          <Grid container item xs={12} {...provided.droppableProps} ref={provided.innerRef}>
            {!isLoading && isFetching && (
              <Box position={'relative'} width={1}>
                <LinearProgress sx={{ width: '100%', position: 'absolute', top: 0, left: 0 }} />
              </Box>
            )}
            {isLoading ? (
              times(5, (row) => <StepSkeleton key={row} />)
            ) : steps.length ? (
              steps?.map((step, index) => (
                <Step
                  entityId={entityId}
                  entityType={entityType}
                  index={index}
                  key={step?.id}
                  step={step}
                />
              ))
            ) : (
              <Alert severity={'info'} sx={{ width: 1 }}>
                No Steps
              </Alert>
            )}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
};
