import isEqual from 'lodash/isEqual';
import ReactQuill from 'react-quill';
import differenceWith from 'lodash/differenceWith';
import { TaskFeedStyleLabel } from './taskFeedStyles';
import { parseDate } from '@blackhyve/utilities/dates';

export const fieldsToIgnore = [
  'duration_string',
  'is_lookahead',
  'is_parent',
  'parent_id',
  'rpr',
  'work_from',
  'work_time',
  'effort_tag',
  'project_id',
  'lookahead',
  'constraint_date',
  'latest_job_walk',
  'workday_calendar',
  'remaining_duration',
  'cal_days',
  'completed_date',
  '$display_order',
  'pace',
  'critical_path',
  'status',
  'percent_complete',
  'effort_hours',
  'color',
  'is_scope',
  'total_float',
  'free_float',
  'constraint_type',
  'constraint_date',
  'baseline_start',
  'baseline_end',
  'baseline_duration',
  'optimal_crew', // no longer actively used, but present on some older tasks
  'shift',
  'qty_type',
  'qty',
  'qty_per_day',
  'percent_complete',
];

export const fieldLabels = {
  cal_days: 'Calendar Days',
  completed_date: 'Completed Date',
  $display_order: 'Display order',
  duration_days: 'Duration',
  scheduled_start: 'Start date',
  scheduled_end: 'End date',
  location_id: 'Location',
  area_id: 'Area',
  zone_id: 'Zone',
  crew_size: 'Crew',
  pace: 'Pace',
  pr: 'Production Rating',
  forecasted_start: 'Forecasted Start date',
  forecasted_end: 'Forecasted end date',
  companies: 'Company',
  name: 'Name',
  users: 'Users',
  trades: 'Trade',
  percent_complete: 'Percent complete',
  autoschedule_date: 'AutoSchedule Date',
};

/**
 * TranslateAuditContent
 */
export const TranslateAuditContent = ({ event, oldValues, newValues, auditType, task, meta }) => {
  if (auditType === 'Comment') {
    return (
      <ReactQuill
        bounds="quill"
        className={'comment'}
        readOnly={true}
        theme={'bubble'}
        value={newValues?.comment}
      />
    );
  }

  if (event === 'updated' || event === 'sync' || event === 'detach') {
    return (
      oldValues &&
      Object.keys(oldValues)?.map((fieldName) => (
        <TaskFeedStyleLabel>
          <AuditChangeList
            fieldName={fieldName}
            key={fieldName}
            newValue={newValues[fieldName]}
            oldValue={oldValues[fieldName]}
            task={task}
          />
        </TaskFeedStyleLabel>
      ))
    );
  }

  if (event === 'created' && auditType === 'Task') {
    return (
      <TaskFeedStyleLabel>
        Created task <b>{newValues?.name}</b>
      </TaskFeedStyleLabel>
    );
  }

  if (auditType === 'JobWalk') {
    return (
      <TaskFeedStyleLabel>
        Recorded Jobwalk for date {parseDate(newValues?.effective_date).toLocaleDateString()},
        percent complete updated to {parseFloat(newValues?.percent_complete) * 100}
      </TaskFeedStyleLabel>
    );
  }

  if (event === 'deleted' && auditType === 'Task') {
    return <TaskFeedStyleLabel>Deleted the {oldValues?.name}</TaskFeedStyleLabel>;
  }

  if (auditType === 'StuckPoint') {
    const title = event === 'created' ? `${newValues?.title}` : meta?.stuckPoint?.title;
    return <TaskFeedStyleLabel>{title}</TaskFeedStyleLabel>;
  }

  return null;
};

const capitalize = (string) => string[0].toUpperCase() + string.slice(1);

/**
 * Translate audit logs
 * @param {String} fieldName
 * @param {JSON} oldValue
 * @param {JSON} newValue
 * @returns
 */
export const AuditChangeList = ({ fieldName, oldValue, newValue, task }) => {
  const label = fieldLabels[fieldName] || capitalize(fieldName.toString().replaceAll('_', ' '));
  let content;

  if (fieldsToIgnore.includes(fieldName)) {
    return;
  }

  if (label?.includes('date')) {
    oldValue = parseDate(oldValue).toLocaleDateString();
    newValue = parseDate(newValue).toLocaleDateString();
  }

  switch (fieldName) {
    case 'companies':
      const newCompanies = differenceWith(newValue, oldValue, isEqual);
      if (newCompanies.length) {
        content = `Added ${newCompanies.map((company) => company.name).join(', ')}`;
      } else if (!newCompanies.length && oldValue.length) {
        const removedCompanies = differenceWith(oldValue, newValue, isEqual);
        content = `Removed ${removedCompanies.map((company) => company.name).join(', ')}`;
      }
      break;
    case 'contacts':
      const newContacts = differenceWith(newValue, oldValue, isEqual);
      if (newContacts.length) {
        content = `Assigned to ${newContacts
          .map((user) => `${user?.first_name} ${user?.last_name}`)
          .join(', ')}`;
      } else if (!newContacts.length && oldValue.length) {
        const removedContacts = differenceWith(oldValue, newValue, isEqual);
        content = `Removed ${removedContacts
          .map((user) => `${user?.first_name} ${user?.last_name}`)
          .join(', ')}`;
      }
      break;
    case 'responsibleUsers':
      const newResponsible = differenceWith(newValue, oldValue, isEqual);
      if (newResponsible.length) {
        content = `Made ${newResponsible
          .map((user) => `${user?.first_name} ${user?.last_name}`)
          .join(', ')} responsible`;
      } else if (!newResponsible.length && oldValue.length) {
        const removedResponsible = differenceWith(oldValue, newValue, isEqual);
        content = `Made ${removedResponsible
          .map((user) => `${user?.first_name} ${user?.last_name}`)
          .join(', ')} no longer responsible`;
      }
      break;
    case 'trades':
      content = (
        <>
          {!oldValue?.length && newValue?.length
            ? `Added trades ${newValue?.map((trade) => trade?.name)?.join(', ')}`
            : !newValue?.length && oldValue?.length
              ? `Removed all trades`
              : `Updated trades to ${newValue?.map((trade) => trade?.name)?.join(', ')}`}
        </>
      );
      break;
    case 'autoschedule_date': {
      content = (
        <>
          Autoscheduling is pointing to
          {newValue === 'schedule' ? ' Schedule Dates' : ' Forecasted Dates'}
        </>
      );
      break;
    }
    default:
      content = `${label} changed from ${oldValue ? oldValue : 'default'} to ${newValue}`;
  }

  return content;
};
