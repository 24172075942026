import { StyledDialog } from '@blackhyve/common';
import { LoadingButton } from '@mui/lab';
import { Button, ListItem, ListItemText, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

const ConfirmDeleteDialog = ({
  open,
  handleClose,
  handleDelete,
  id,
  item,
  itemNames,
  plural,
  isLoading = false,
}) => {
  const [listHeight, setListHeight] = useState(1);

  const onClose = (event, reason) => {
    if (reason !== 'escapeKeyDown' && reason !== 'backdropClick') {
      handleClose();
    }
  };

  return (
    <StyledDialog handleClose={onClose} open={open} title="Confirm Delete">
      <Stack alignItems={'center'} justifyContent={'center'} spacing={1}>
        <Typography variant={'h6'}>
          Are you sure you want to delete {plural ? 'these' : 'this'}
          {item ? ` ${item}${plural ? 's' : ''}` : null}?
        </Typography>
        {itemNames?.length > 0 && (
          <Paper sx={{ width: 0.9, height: `min(${listHeight}px, 25vh)` }}>
            <Virtuoso
              data={itemNames}
              totalListHeightChanged={(newHeight) => setListHeight(newHeight)}
              itemContent={(index, task) => (
                <ListItem dense divider as={'div'} key={index}>
                  <ListItemText primary={task} />
                </ListItem>
              )}
              style={{
                minHeight: '100%',
                overflowY: 'auto',
              }}
            />
          </Paper>
        )}
        <Typography>This action cannot be undone.</Typography>
        <Stack direction={'row'} spacing={1}>
          <Button disabled={isLoading} onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            color={'error'}
            loading={isLoading}
            variant={'contained'}
            onClick={() => {
              handleDelete(id);
              handleClose();
            }}
          >
            Delete {item}
            {plural ? 's' : ''}
          </LoadingButton>
        </Stack>
      </Stack>
    </StyledDialog>
  );
};

export default ConfirmDeleteDialog;
