import { Button, DialogActions } from '@mui/material';
import Popover from '@mui/material/Popover';
import makeStyles from '@mui/styles/makeStyles';
import { SelectContactList } from 'features/contacts/components/SelectContactList';
import {
  useGetProjectCompaniesQuery,
  useGetWorkspaceCompanyQuery,
} from 'features/projectCompanies/api/projectCompanies.api';
import { createRef, forwardRef, useImperativeHandle, useState } from 'react';
import ganttStore from '../../ganttStore';
import { uniqBy } from 'lodash';

export const loadContactMenuType = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  let contactEditorRef;
  gantt.config.editor_types.contact = {
    show: function (id, column, config, placeholder) {
      const task = gantt.getTask(id);
      contactEditorRef = createRef();
      gantt.config.external_render.renderElement(
        <ContactMenuPopover
          anchorEl={placeholder}
          initialSelected={task.contacts}
          key={`${task.id}-contact-menu`}
          projectId={task.project_id}
          ref={contactEditorRef}
          onClose={() => gantt.ext.inlineEditors.save()}
        ></ContactMenuPopover>,
        placeholder
      );
    },
    hide: function () {
      // called when input is hidden
      // destroy any complex editors or detach event listeners from here
    },

    set_value: function (value, id, column, node) {
      // set input value
      if (contactEditorRef.current) {
        contactEditorRef?.current?.setValue(new Set(value || []));
      }
    },

    get_value: function (id, column, node) {
      return contactEditorRef?.current?.getValue();
      // return input value
    },

    is_changed: function (value, id, column, node) {
      //called before save/close. Return true if new value differs from the original one
      //returning true will trigger saving changes, returning false will skip saving
      const { contacts: currentValue } = this.get_value() || [];
      const oldValue = value.contacts || [];
      if (currentValue.length !== oldValue.length) {
        return true;
      }
      currentValue.sort();
      oldValue.sort();
      return !currentValue.every((element, index) => element.id === oldValue[index].id);
    },

    is_valid: function (value, id, column, node) {
      // validate, changes will be discarded if the method returns false
      return Array.isArray(this.get_value().contacts);
    },
    focus: function (node) { },
    save: function (id, column, node) {
      const { contacts, companies } = this.get_value(id, column, node);
      const task = gantt.getTask(id);
      task.contacts = contacts;
      task.companies = uniqBy([...task.companies, ...companies], 'id');
      gantt.updateTask(id);
    },
  };
};

const useStyles = makeStyles(() => ({
  paper: {
    width: 400,
    overflowY: 'auto',
    borderRadius: '8px',
    padding: '10px 10px',
  },
}));

const ContactMenuPopover = forwardRef(
  ({ initialSelected = [], anchorEl, onClose, projectId }, ref) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [selected, setSelected] = useState(initialSelected || []);

    const { data: companies = [], isLoading } = useGetProjectCompaniesQuery({ projectId });

    const { data: company = { users: [] }, isLoading: isLoadingUserCompany } =
      useGetWorkspaceCompanyQuery({ projectId });

    const companyEntities = Object.fromEntries(
      companies?.map((company) => [company.id, company]) || []
    );

    const filteredCompanies = companies.filter((companyObj) => companyObj.id !== company.id);

    const handleClose = () => {
      setOpen(false);
      onClose(selected);
    };

    const handleSubmit = () => {
      handleClose();
    };

    const handleCancel = () => {
      setOpen(false);
      setSelected(initialSelected);
      onClose(initialSelected);
    };

    useImperativeHandle(ref, () => ({
      getValue: () => ({
        contacts: [...selected],
        companies: [...new Set(selected.map(({ company_id }) => companyEntities[company_id]))],
      }),
      setValue: setSelected,
    }));

    return (
      <>
        {anchorEl && (
          <Popover
            anchorEl={anchorEl}
            classes={{ paper: classes.paper }}
            open={open}
            onClose={handleSubmit}
          >
            <SelectContactList
              companies={filteredCompanies}
              isLoading={isLoading || isLoadingUserCompany}
              selected={selected}
              setSelected={setSelected}
            />
            <DialogActions>
              <Button size="small" onClick={handleCancel}>
                Cancel
              </Button>
              <Button size="small" variant="contained" onClick={handleSubmit}>
                Update
              </Button>
            </DialogActions>
          </Popover>
        )}
      </>
    );
  }
);

const companyIconString = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style=" height: 18px; width: 25px;"><path d="M15 9C15 6.49219 13.46 4.34625 11.2753 3.44937L10 6V2.5C10 2.36739 9.94732 2.24021 9.85355 2.14645C9.75979 2.05268 9.63261 2 9.5 2H6.5C6.36739 2 6.24021 2.05268 6.14645 2.14645C6.05268 2.24021 6 2.36739 6 2.5V6L4.72469 3.44937C2.54 4.34625 1 6.49219 1 9V11H15V9ZM15.5 12H0.5C0.367392 12 0.240215 12.0527 0.146447 12.1464C0.0526784 12.2402 0 12.3674 0 12.5L0 13.5C0 13.6326 0.0526784 13.7598 0.146447 13.8536C0.240215 13.9473 0.367392 14 0.5 14H15.5C15.6326 14 15.7598 13.9473 15.8536 13.8536C15.9473 13.7598 16 13.6326 16 13.5V12.5C16 12.3674 15.9473 12.2402 15.8536 12.1464C15.7598 12.0527 15.6326 12 15.5 12Z" fill="currentColor"></path></svg>`;

/**
 * Contact column cell HTML
 * @param {object} task 
 * @returns 
 */
export const contactColumnHTML = ({ task }) => {

  if (task.id === -1) {
    return (
      `<span class="cell_disabled" style="align-items: center; display: flex;">
        ${companyIconString}
      </span>`
    );
  }

  const taskContacts = task?.contacts?.sort((a, b) =>
    a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
  );

  if (taskContacts?.length === 1) {
    return `<span>${taskContacts[0]?.name}</span>`
  }

  let contactInnerHtml = '';
  if (taskContacts?.length > 1) {
    contactInnerHtml = taskContacts?.reduce((html, contact, index) => (
      html += `<div
              class="company-avatar company-grid-icon column-label"
              key=${contact?.id}
              style="z-index: ${taskContacts?.length - index}; margin-left: ${index > 0 ? '8px' : '0px'}"
              data-label="${contact.name}">
              ${contact?.first_name?.slice(0, 1)}${contact?.last_name?.slice(0, 1)}</div>`
    ), '')
  } else {
    contactInnerHtml = `<span style="align-items: center; display: flex;">${companyIconString}</span>`
  }

  return contactInnerHtml;

}