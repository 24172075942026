import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';

function getFileNameFromContentDisposition(header) {
  try {
    if (!header) throw new Error();
    const match = header.match(/filename="?(.+)"?/i);
    return match ? match[1].replace(/['"]/g, '') : null;
  } catch (error) {
    console.error({
      message: 'Error parsing Content-Disposition: filename parameter is required',
      error,
    });
  }
}

export const useDownloadFile = () => {
  const xTenant = useSelector((state) => state.auth.workspaceId);
  const authToken = useSelector((state) => state.auth.authToken);
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState();

  const downloadFile = async (url, method, params = {}) => {
    try {
      setError(undefined);
      setIsDownloading(true);
      const result = await axios({
        url: `${process.env.REACT_APP_SERVER_URL}/${url}`,
        headers: {
          'X-Tenant': xTenant,
          Authorization: `Bearer ${authToken}`,
        },
        method,
        responseType: 'blob',
        params,
      });

      const contentDisposition = result.headers.get('content-disposition');
      const fileName = getFileNameFromContentDisposition(contentDisposition);

      const hiddenElement = document.createElement('a');
      const href = window.URL.createObjectURL(result.data);
      hiddenElement.href = href;
      hiddenElement.download = fileName;
      hiddenElement.target = '_blank';
      hiddenElement.click();
      hiddenElement.remove();
      setIsDownloading(false);
      window.URL.revokeObjectURL(href);
    } catch (error) {
      console.error({
        message: 'Error parsing Content-Disposition: filename parameter is required',
        error,
      })
      setError(error);
      setIsDownloading(false);
    }
  };

  return { downloadFile, isDownloading, error };
};
