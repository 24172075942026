import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import isValid from 'date-fns/isValid';
import Grid from '@mui/material/Grid';
import random from 'lodash/random';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import { TASK_COLORS } from 'helpers/constants';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import { DatePicker } from '@mui/x-date-pickers';
import { StyledDialog } from '@blackhyve/common';
import { selectCurrentUser } from 'features/auth';
import LoadingButton from '@mui/lab/LoadingButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { addWorkdays, differenceInWorkdays, parseDate } from '@blackhyve/utilities/dates';
import { useCreateTaskMutation, useUpdateTasksMutation } from 'features/tasks/store/task.api';
import { TradesAutocomplete } from 'features/trades/components/TradesAutocomplete';
import { useGetProjectCompaniesQuery, useGetWorkspaceCompanyQuery } from 'features/projectCompanies/api/projectCompanies.api';
import { useGetProjectHolidaysQuery, useGetProjectQuery } from 'features/projects/store/project.api';
import { getCalendarDays } from 'features/tasksGantt/utils/gantt/taskGanttEvents';
import { TagAutocompleteDropdown } from 'features/tags/components/TagDropdown';
import { Stack } from '@mui/material';
import Tag from 'features/tags/components/Tag';

const initialState = {
  name: '',
  trades: [],
  crew_size: null,
  scheduled_start: new Date(),
  scheduled_end: addWorkdays(new Date(), 7),
  companies: [],
  responsible_users: [],
  tags: []
}

/**
 * Create Production Board Task Dialog
 * @param {Boolean} open 
 * @param {String} parentId 
 * @param {String} projectId
 * @param {Object} handleClose 
 * @param {Object} task
 * @param {Number} task.id 
 * @param {String} task.name 
 * @param {String} task.scheduled_start 
 * @param {String} task.scheduled_end
 * @param {[Object]} task.trades  
 * @param {[Object]} task.companies
 * @param {[Object]} task.responsible_users  
 * @returns 
 */
const ProductionBoardCreateTaskDialog = ({ open, parentId, projectId, handleClose, task }) => {

  const formMethods = useForm({
    defaultValues: { ...initialState },
  });
  const { control, handleSubmit, watch, reset, setFocus } = formMethods;

  const { data: project, isLoading: isLoadingProject } = useGetProjectQuery(projectId);
  const { data: companies = [] } = useGetProjectCompaniesQuery({ projectId });
  const { data: userCompany = { users: [] } } = useGetWorkspaceCompanyQuery({ projectId });
  const { data: { listOfHolidays = [] } = {}, isLoading: isLoadingProjectHolidays } = useGetProjectHolidaysQuery(projectId);
  const currentUser = useSelector((state) => selectCurrentUser(state));
  const users = useMemo(() => {
    const users =
      currentUser.role === 'super_admin'
        ? [{ ...currentUser, isCurrentUser: true }, ...userCompany.users]
        : [...userCompany.users];
    return users;
  }, [userCompany, currentUser]);
  const filteredCompanies = companies?.filter((obj) => obj.id !== userCompany.id)
  const startDate = watch('scheduled_start');

  const [createTask, { isLoading: isCreateTaskLoading }] = useCreateTaskMutation();
  const [updateTasks, { isLoading: isTaskUpdating }] = useUpdateTasksMutation();

  const isLoading = isTaskUpdating || isCreateTaskLoading || isLoadingProject || isLoadingProjectHolidays;


  const handleSaveAndAddAnother = (data, e) => {
    handleSave(data, e, true)
  }

  const handleSave = (data, e, preventClose) => {
    const scheduledStart = data.scheduled_start ? format(data.scheduled_start, "yyyy-MM-dd") : null;
    const scheduledEnd = data.scheduled_end ? format(data.scheduled_end, "yyyy-MM-dd") : null;
    const calendarObj = { holidays: listOfHolidays, days: getCalendarDays(project.workday_calendar) };

    const taskPayload = {
      ...data,
      parent_id: parentId,
      project_id: projectId,
      is_production: 1,
      scheduled_start: scheduledStart,
      scheduled_end: scheduledEnd,
      forecasted_start: scheduledStart,
      forecasted_end: scheduledEnd,
      should_autoschedule: 1,
      tags: data?.tags?.length ? data?.tags?.map((tag) => tag.id) : [],
      duration_days: differenceInWorkdays(data.scheduled_start, data.scheduled_end, calendarObj)
    }
    const taskAction = data.id ? updateTasks : createTask;
    taskAction(data.id ? { tasks: [taskPayload] } : { task: taskPayload })
      .then(() => {
        handleReset();
        !preventClose && handleClose();
      });

  }

  const handleReset = useCallback(() => {
    setFocus('name');
    reset({
      id: task?.id ?? initialState.id,
      name: task?.name ?? initialState.name,
      color: task?.color ?? TASK_COLORS[random(0, 44)].value,
      crew_size: task?.crew_size ?? initialState.crew_size,
      scheduled_start: task?.scheduled_start ? parseDate(task?.scheduled_start) : initialState.scheduled_start,
      scheduled_end: task?.scheduled_end ? parseDate(task?.scheduled_end) : initialState.scheduled_end,
      trades: task?.trades ?? initialState.trades,
      companies: task?.companies?.map((company) => company?.id) ?? initialState.companies,
      responsible_users: task?.responsible_users?.map((user) => user?.id) ?? initialState.responsible_users,
      tags: task?.tags?.filter((tag) => tag.type !== "generated" && tag.tagged_by !== 0) || initialState.tags
    });
  }, [
    task?.id,
    task?.name,
    task?.color,
    task?.scheduled_start,
    task?.scheduled_end,
    task?.trades,
    task?.companies,
    task?.crew_size,
    task?.tags,
    task?.responsible_users,
    reset,
    setFocus,
  ]);

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  return (
    <StyledDialog
      DialogContentProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      handleClose={handleClose}
      open={open}
      title={'Create Task'}
      actions={
        <>
          <Button
            disabled={isLoading}
            size="small"
            onClick={handleClose}
          >
            Close
          </Button>
          {!task?.id &&
            <LoadingButton
              loading={isLoading}
              size='small'
              variant={'contained'}
              onClick={handleSubmit(handleSaveAndAddAnother)}
            >
              Save And Add Another
            </LoadingButton>}
          <LoadingButton
            loading={isLoading}
            size='small'
            variant={'contained'}
            onClick={handleSubmit(handleSave)}
          >
            Save
          </LoadingButton>
        </>
      }
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(handleSave)(e);
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormLabel>Name*</FormLabel>
            <Controller
              control={control}
              name="name"
              rules={{ required: 'Name field is required' }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoFocus
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  inputRef={ref}
                  placeholder={'Name'}
                  size="small"
                  type={'text'}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormLabel>Start Date*</FormLabel>
            <Controller
              control={control}
              name={'scheduled_start'}
              render={({
                field: { onChange, value, ref, ...field },
                fieldState: { error, invalid },
              }) => (
                <DatePicker
                  ref={ref}
                  value={value}
                  slotProps={{
                    textField: {
                      id: 'scheduled_start',
                      fullWidth: true,
                      size: 'small',
                      helperText: error?.message,
                      error: invalid,
                      ...field,
                    },
                    actionBar: {
                      actions: ['today'],
                    },
                  }}
                  onChange={onChange}
                />
              )}
              rules={{
                required: 'Field is required',
                validate: {
                  isDate: (v) => isValid(v) || 'Invalid Date',
                },
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormLabel>End Date*</FormLabel>
            <Controller
              control={control}
              name={'scheduled_end'}
              render={({
                field: { onChange, value, ref, ...field },
                fieldState: { error, invalid },
              }) => (
                <DatePicker
                  minDate={startDate}
                  ref={ref}
                  value={value}
                  slotProps={{
                    textField: {
                      id: 'scheduled_end',
                      fullWidth: true,
                      size: 'small',
                      helperText: error?.message,
                      error: invalid,
                      ...field,
                    },
                    actionBar: {
                      actions: ['today'],
                    },
                  }}
                  onChange={onChange}
                />
              )}
              rules={{
                required: 'Field is required',
                validate: {
                  isDate: (v) => isValid(v) || 'Invalid Date',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Select Tag</FormLabel>
            <Controller
              control={control}
              name="tags"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TagAutocompleteDropdown
                  selectedTags={field.value}
                  setSelectedTags={field.onChange}
                  renderTags={(tags, ...props) => {
                    const limitTags = 5
                    return <Stack alignItems={'center'} direction={'row'} gap={.5}>
                      {tags?.slice(0, limitTags)?.map((tag) => <Tag color={tag.color} name={tag.name} type={tag.type} />)}
                      {tags?.length > limitTags && `+${tags.length - limitTags}`}
                    </Stack>
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <FormLabel>Crew Size</FormLabel>
            <Controller
              control={control}
              name="crew_size"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoFocus
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  inputRef={ref}
                  placeholder={'Crew Size'}
                  size="small"
                  type={'number'}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item sm={9} xs={12}>
            <FormLabel>Trade</FormLabel>
            <Controller
              control={control}
              name="trades"
              render={({ field }) => (
                <TradesAutocomplete
                  multiple
                  {...field}
                  onChange={(_event, newValue) => {
                    field.onChange(newValue ?? null);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Responsible</FormLabel>
            <Controller
              control={control}
              name="responsible_users"
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  disableCloseOnSelect
                  fullWidth
                  multiple
                  isOptionEqualToValue={(option, value) => option.id === value}
                  options={users}
                  getOptionLabel={(option) =>
                    option?.first_name ??
                    (users?.length && users.find((user) => user.id === option)?.first_name)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={error}
                      helperText={error?.message}
                      placeholder="Select User"
                      size="small"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <ListItem
                      {...props}
                      disablePadding
                      key={option.id}
                      secondaryAction={
                        option.isCurrentUser ? (
                          <Chip color="secondary" label="Logged in" size="small" />
                        ) : null
                      }
                    >
                      <ListItemIcon>
                        <Checkbox checked={selected} />
                      </ListItemIcon>
                      <ListItemText primary={`${option.first_name} ${option.last_name}`} />
                    </ListItem>
                  )}
                  onChange={(event, newValue) => {
                    field.onChange(newValue.map((user) => user?.id ?? user));
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Company</FormLabel>
            <Controller
              control={control}
              name="companies"
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  disableCloseOnSelect
                  fullWidth
                  multiple
                  isOptionEqualToValue={(option, value) => option.id === value}
                  options={filteredCompanies}
                  getOptionLabel={(option) =>
                    option?.name ??
                    (filteredCompanies && filteredCompanies.find((company) => company.id === option)?.name)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={error}
                      helperText={error?.message}
                      placeholder="Select Company"
                      size="small"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option?.name}
                    </li>
                  )}
                  onChange={(event, newValue) => {
                    field.onChange(newValue.map((company) => company?.id ?? company));
                  }}
                />
              )}
            />
          </Grid>

        </Grid>
      </form>
    </StyledDialog>

  );
}




export default ProductionBoardCreateTaskDialog;