import { Button, Grid, Popover, Switch, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  ArrowIcon: {
    fontSize: 18,
  },
  crewPaper: {
    overflowY: 'hidden',
    borderRadius: '8px',
    padding: '10px 10px',
  },
  pCrew: {
    fontWeight: '600',
    fontSize: '14px',
  },
  pCrewSize: {
    marginBottom: '-5px',
    marginLeft: '23px',
    fontSize: '14px',
    display: 'flex',
  },
}));

const TaskBulkEditCrewMenuItem = (props) => {
  const classes = useStyles();

  const { selected, handleUpdate } = props;

  const [anchorEl, setAnchorEl] = useState(undefined);
  const [crewSizeValue, setCrewSizeValue] = useState('');

  const handleOnChange = (event) => {
    setCrewSizeValue(event.target.value);
  };

  const handleClose = () => {
    if (crewSizeValue !== '') {
      handleUpdate({ crew_size: crewSizeValue });
    }
    setAnchorEl(undefined);
    setCrewSizeValue('');
  };

  return (
    <>
      <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
        Crew
        {anchorEl ? (
          <ArrowDropDown className={classes.ArrowIcon} />
        ) : (
          <ArrowDropUp className={classes.ArrowIcon} />
        )}
      </Button>
      {anchorEl ? (
        <Popover
          anchorEl={anchorEl}
          classes={{ paper: classes.crewPaper }}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
          onClose={handleClose}
        >
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={3}>
              <p className={classes.pCrew} style={{ paddingLeft: '12px' }}>
                Crew
              </p>
            </Grid>
            <Grid item xs={9}>
              <TextField
                autoFocus
                fullWidth
                className={classes.textField}
                placeholder="Type in number"
                size="small"
                type="number"
                value={crewSizeValue}
                variant="outlined"
                onChange={handleOnChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleClose();
                  }
                }}
              />
            </Grid>
          </Grid>
        </Popover>
      ) : null}
    </>
  );
};

export default TaskBulkEditCrewMenuItem;
