import { Collapse, Paper, Stack } from '@mui/material';
import { useEditContext } from 'components/common/v3/DisplayWithEditAccess';
import { StepForm } from 'features/steps/components/StepForm';
import { Steps } from 'features/steps/components/Steps';
import { StepsHeader } from 'features/steps/components/StepsHeader';
import { useCreateStepMutation } from 'features/steps/store/step.api';
import { useState } from 'react';

/**
 * Steps
 * @returns
 */
const StepTab = ({ projectId, taskId }) => {
  const [isCreateStepOpen, setIsCreateStepOpen] = useState(false);
  const [createStep, { isLoading: isCreatingStep }] = useCreateStepMutation();

  const canEdit = useEditContext();

  return (
    <>
      <Stack spacing={1}>
        <StepsHeader
          disableAdd={!canEdit}
          entityId={taskId}
          entityType={'tasks'}
          onAddStep={() => setIsCreateStepOpen(true)}
        />
        <Paper>
          <Collapse unmountOnExit in={isCreateStepOpen}>
            <Paper square sx={{ p: 2 }} variant={'outlined'}>
              <StepForm
                handleClose={() => setIsCreateStepOpen(false)}
                isLoading={isCreatingStep}
                projectId={projectId}
                handleSave={(step) => {
                  createStep({ ...step, entityId: taskId, entityType: 'tasks' });
                  setIsCreateStepOpen(false);
                }}
              />
            </Paper>
          </Collapse>
          <Steps entityId={taskId} entityType={'tasks'} projectId={projectId} />
        </Paper>
      </Stack>
    </>
  );
};

export default StepTab;
