
import { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Tag from '../components/Tag';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import TagMenu from '../components/TagMenu';
import { Table } from 'components/table/Table';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useGetTagsQuery } from '../store/tag.api';
import SearchBox from 'components/search/SearchBox';
import { selectCurrentUserId } from 'features/auth';
import CreateTagDialog from '../components/CreateTagDialog';
import AvatarComponent from 'components/common/v3/AvatarComponent';
import SvgBlackhyveIcon from '@blackhyve/common/src/icons/BlackhyveIcon';

/**
 * Manage Tags
 * @returns 
 */
const ManageTags = () => {

  const [searchQuery, setSearchQuery] = useState('')
  const { data: tags = [], isLoading, isFetching } = useGetTagsQuery()
  const [openCreateTagDialog, setOpenCreateTagDialog] = useState(false);
  const currentUserId = useSelector((state) => selectCurrentUserId(state));

  const handleSearch = (event) => setSearchQuery(event.target.value);

  const handleOpenAddTagDialog = () => setOpenCreateTagDialog(true);

  const handleCloseAddTagDialog = () => setOpenCreateTagDialog(false);

  const filteredTags = useMemo(() => {
    return tags
      .filter((tag) => tag.type !== "generated" && tag.name?.toLowerCase()
        .includes(searchQuery?.toLowerCase()));
  }, [tags, searchQuery])



  return <Container component={Card} maxWidth="md" sx={{ pb: 2 }}>
    <h1>Manage Tags</h1>
    <Grid container>
      <Grid container item spacing={2} xs={12}>
        <Grid item xs>
          <SearchBox
            bgcolor={'grey.200'}
            borderRadius={1}
            fullWidth={false}
            placeholder="Search By Tag Name"
            onChange={handleSearch}
          />
        </Grid>
        <Grid container item justifyContent={"flex-end"} xs="auto">
          <Button variant='contained' onClick={handleOpenAddTagDialog}>Add Tag</Button>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Table
          columns={columns}
          defaultOrderBy={false}
          isFetching={isFetching}
          isLoading={isLoading}
          meta={{ currentUserId }}
          rows={filteredTags}
          stickyHeader={true}
        />
      </Grid>
    </Grid>
    {openCreateTagDialog &&
      <CreateTagDialog handleClose={handleCloseAddTagDialog} open={openCreateTagDialog} />}
  </Container >
}

const columns = [
  {
    field: 'name',
    label: 'Name',
    render: (tag) => (
      <Box alignItems={'center'} display={"flex"} gap={0.5}>
        <Tag color={tag?.color} name={tag?.name} tagProps={{ maxWidth: "350px" }} type={tag?.type} />
      </Box>
    ),
  },
  {
    field: 'description',
    label: 'Description',
    render: (tag) => (
      <Typography variant='body2'>{tag?.description}</Typography>
    ),
  },
  {
    field: 'task_count',
    label: 'Task Count',
    render: (tag) => (
      <Typography variant='body2'>{tag?.task_count}</Typography>

    ),
  },
  {
    field: 'created_by',
    label: 'Created By',
    render: (tag) => (
      <Box alignItems={'center'} display={"flex"} gap={1}>
        {tag.created_by?.id === 0 ?
          <SvgBlackhyveIcon color="black" height="32" width="40" />
          : <AvatarComponent
            name={tag?.created_by?.name}
            src={tag?.created_by?.profile_image?.thumb_url}
          />}
        <Typography variant='subtitle2'>{tag.created_by.name}</Typography>
      </Box>
    ),
  },
  {
    field: 'moreOptions',
    cellProps: { align: 'right' },
    render: (tag, meta) => {
      const isTagOwner = tag?.created_by?.id === meta.currentUserId
      return isTagOwner ? <TagMenu tag={tag} /> : null
    },
  },
];

export const tagRoutes = [
  { index: true, element: <ManageTags /> }
]

