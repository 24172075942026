
import random from 'lodash/random';
import Box from '@mui/material/Box'
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { TASK_COLORS } from "helpers/constants";
import TextField from "@mui/material/TextField";
import { StyledDialog } from "@blackhyve/common";
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { Controller, useForm } from 'react-hook-form';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import LabelIcon from '@mui/icons-material/LabelOutlined';
import { ColorSelect } from 'components/form/ColorSelect';
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from '@mui/material/FormControlLabel';
import SmartIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import ArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import { HighlighterLinkLabel, FormLabel } from 'assets/style-components/typography';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import { useCreateTagMutation, useUpdateTagMutation } from "../store/tag.api";


const TagTypes = {
  smart: {
    label: "Smart",
    value: "smart",
    icon: <SmartIcon />,
    description: "When assigned to a task, it automatically takes the task name, creates a tag from it, and applies it to all its child tasks."

  },
  cascading: {
    label: "Cascade",
    value: "cascading",
    icon: <ArrowDownIcon />,
    description: "When assigned to a parent task, the tags are automatically applied to its child tasks."
  },
  standard: {
    label: "Standard",
    value: "standard",
    icon: <LabelIcon />,
    description: "These are normal tags that can be used to categorize and organize items for easy identification and retrieval."
  }
}


const initialState = {
  name: "",
  color: TASK_COLORS[random(0, 44)].value,
  type: "smart"
}

/**
 * Create Tag Dialog
 * @param {Object} handleClose 
 * @param {Boolean} open 
 * @param {Object} tag 
 * @returns 
 */
const CreateTagDialog = ({ handleClose, open, tag }) => {

  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const { control, handleSubmit, reset, setError } = useForm({ defaultValues: initialState });
  const [createTag, { isLoading: isCreateTagLoading }] = useCreateTagMutation();
  const [updateTag, { isLoading: isUpdateTagLoading }] = useUpdateTagMutation();

  const handleToggleDescription = () => setIsDescriptionVisible(!isDescriptionVisible);

  const handleSave = (data) => {
    const apiToCall = tag?.id ? updateTag : createTag;
    apiToCall(data).then((response) => {
      response.error ? setError("name", { message: response.error.data.message }) : handleClose();
    })

  }

  useEffect(() => {
    reset(tag ? { ...tag } : {
      ...initialState,
      color: TASK_COLORS[random(0, 44)].value
    })
  }, [tag, reset]);

  return <StyledDialog
    handleClose={handleClose}
    open={open}
    title={'Create Tag'}
    actions={
      <>
        <Button size="small" onClick={handleClose}>
          Close
        </Button>
        <Button disabled={isCreateTagLoading || isUpdateTagLoading} size="small" variant="contained" onClick={handleSubmit(handleSave)}>
          Save
          {isCreateTagLoading || isUpdateTagLoading && <CircularProgress size={20} sx={{ ml: 1, zIndex: 20, color: "white" }} />}
        </Button>
      </>
    }
  >

    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(handleSave)(e);
      }}
    >
      <Grid container spacing={1}>
        <Grid container item xs={10}>
          <FormLabel>Name*</FormLabel>
          <Controller
            control={control}
            defaultValue={''}
            name={'name'}
            rules={{ required: 'Field is required' }}
            render={({ field, fieldState: { error, invalid } }) => (
              <TextField
                {...field}
                autoFocus
                fullWidth
                error={error}
                helperText={error?.message}
                size={'small'}
              />
            )}
          />
          <HighlighterLinkLabel style={{ paddingTop: '5px' }}>
            <Link component={'button'} onClick={handleToggleDescription}>
              {isDescriptionVisible ? 'Hide' : 'Add'} Description
            </Link>
          </HighlighterLinkLabel>
        </Grid>
        <Grid item alignItems={'center'} xs={2}>
          <FormLabel>Color</FormLabel>
          <Controller
            control={control}
            name="color"
            render={({ field }) => <ColorSelect {...field} fullWidth size={'small'} />}
          />
        </Grid>
        {isDescriptionVisible && (
          <Grid item xs={12}>
            <FormLabel>DESCRIPTION</FormLabel>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  placeholder="Description"
                  rows={5}
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        )}
        <Grid container item alignItems={'center'} xs={12}>
          <FormControl sx={{ justifyContent: 'center' }}>
            <Stack alignItems="center" direction="row" spacing={0.5}>
              <FormLabel id="tag-group-label">Select Tag Type</FormLabel>
            </Stack>
            <Controller
              control={control}
              name="type"
              render={({ field }) =>
                <RadioGroup
                  {...field}
                  row
                  aria-labelledby="tag-group-label"
                  p={1}
                >
                  <Stack spacing={1}>
                    {Object.values(TagTypes).map((tagTypeObj) => {
                      return <RadioCard
                        description={tagTypeObj.description}
                        isSelected={tagTypeObj.value === field.value}
                        key={tagTypeObj.value}
                        value={tagTypeObj.value}
                        label={
                          <Box display={'flex'} gap={0.5}>
                            <Typography>{tagTypeObj.label}</Typography>
                            {tagTypeObj.icon}
                          </Box>}
                        onChange={field.onChange}
                      />
                    })}
                  </Stack>
                </RadioGroup>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  </StyledDialog>
}


/**
 * Radio Card
 * @param {Object || String} label
 * @param {String} value
 * @param {String} description 
 * @param {Object} onChange
 * @param {Boolean} isSelected
 * @returns 
 */
const RadioCard = ({ label, value, description, onChange, isSelected }) => {

  return <Card elevation={3}>
    <CardActionArea onClick={() => onChange({ target: { value } })} >
      <CardContent sx={{ px: 1, py: 1.5 }}>
        <Grid container alignItems={'center'} justifyContent={'center'}>
          <Grid item xs="auto">
            <FormControlLabel control={<Radio checked={isSelected} />} sx={{ m: 0 }} value={value} />
          </Grid>
          <Grid item xs>
            {label}
            <Typography sx={{ color: 'text.secondary' }} variant="body2">
              {description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </CardActionArea>
  </Card>
}




export default CreateTagDialog;