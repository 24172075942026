import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { StyledMenu } from 'features/activityFeeds/assets/activityFeedStyle';
import { useState } from 'react';
import { Label as LabelComponent } from 'features/labels/components/Label';

/**
 * Jobwalk Sort Menu
 * @param {Object} setFilters
 * @param {Object} filters
 * @returns
 */
const StuckPointStatusMenu = ({ selectedStatus, setFilters }) => {
  const [openMenu, setOpenMenu] = useState(false);

  const options = [
    { name: 'All', value: undefined },
    { name: 'Pending', value: 0 },
    { name: 'Resolved', value: 1 },
  ];

  const handleClose = () => {
    setOpenMenu(null);
  };

  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const defaultStatus =
    selectedStatus === undefined ? 'All' : selectedStatus === 0 ? 'Pending' : 'Resolved';

  return (
    <>
      <Chip
        color={defaultStatus === 'All' ? 'default' : 'secondary'}
        label={<>{defaultStatus} <LabelComponent labelKey="stuck_point" plural={true} /></>}
        variant={'filled'}
        icon={
          selectedStatus === undefined ? (
            <AllInclusiveIcon fontSize="small" />
          ) : selectedStatus === 0 ? (
            <PendingActionsIcon fontSize="small" />
          ) : (
            <AssignmentTurnedInIcon fontSize="small" />
          )
        }
        onClick={handleClick}
      />

      <StyledMenu
        anchorEl={openMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={Boolean(openMenu)}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
      >
        <List dense>
          {options.map((option) => {
            return (
              <ListItemButton
                key={option.name}
                onClick={() =>
                  setFilters((prevFilters) => ({ ...prevFilters, resolved: option.value }))
                }
              >
                <ListItemText primary={option.name} />
              </ListItemButton>
            );
          })}
        </List>
      </StyledMenu>
    </>
  );
};

export default StuckPointStatusMenu;
