export const riskExposureEnum = {
  low: 'low',
  medium: 'medium',
  high: 'high',
  all: 'all',
};

export const riskExposureColorEnum = {
  low: 'info',
  medium: 'warning',
  high: 'error',
  all: 'default',
};
