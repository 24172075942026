import { formatGanttTaskToTask } from '@blackhyve/utilities/gantt';
import { hasChild, getTaskTags } from '@blackhyve/utilities/tasks';
import { useCallback, useEffect, useRef } from 'react';

/**
 * Custom hook for processing Gantt data updates.
 * @param {Object} handlers - Handlers for task and link operations.
 * @param {Function} handlers.createTask - Function to create a task.
 * @param {Function} handlers.updateTasks - Function to update tasks.
 * @param {Function} handlers.deleteTasks - Function to delete tasks.
 * @param {Function} handlers.createLink - Function to create a link.
 * @param {Function} handlers.updateLinks - Function to update links.
 * @param {Function} handlers.deleteLinks - Function to delete links.
 * @returns {Function} initDataProcessor - Initializes the Gantt data processor.
 */
const useGanttDataProcessor = ({
  createTasks,
  updateTasks,
  deleteTasks,
  createLinks,
  updateLinks,
  deleteLinks,
}) => {
  const isLoading = useRef(false);
  const autoUpdateTimer = useRef(null);
  const ganttRef = useRef(null);
  const pendingUpdates = useRef({
    createTasks: {},
    updateTasks: {},
    deleteTasks: [],
    createLinks: {},
    updateLinks: {},
    deleteLinks: [],
  });

  /**
   * Retries the update if it fails.
   * @param {Array} items - Items to retry.
   * @param {String} type - Type of items (e.g., 'updateTasks').
   * @param {String} key - Key to identify items (e.g., 'id').
   */
  const retryUpdate = (items, type, key) => {
    items.forEach((item) => {
      if (!pendingUpdates.current[type].hasOwnProperty(item[key])) {
        pendingUpdates.current[type][item[key]] = item;
      }
    });
  };

  /**
   * Processes all pending updates.
   */
  const processPendingUpdates = useCallback(async () => {
    let isError = false;
    if (!isLoading.current) {
      isLoading.current = true;
      const {
        createTasks: tasksToCreate,
        updateTasks: tasksToUpdate,
        deleteTasks: tasksToDelete,
        createLinks: linksToCreate,
        updateLinks: linksToUpdate,
        deleteLinks: linksToDelete,
      } = pendingUpdates.current;

      const promises = [];

      if (Object.keys(tasksToCreate).length > 0) {
        const createdTasks = Object.values(tasksToCreate);
        pendingUpdates.current.createTasks = {};
        promises.push(
          createTasks({ tasks: createdTasks })
            .unwrap()
            .then((response) => {
              response.forEach((task) => {
                ganttRef.current.changeTaskId(task.import_id, task.id);
                ganttRef.current.refreshTask(task.id);
              });
            })
            .catch(() => {
              retryUpdate(createdTasks, 'createTasks', 'id');
              isError = true;
            })
        );
      } else if (Object.keys(linksToCreate).length > 0) {
        const createdLinks = Object.values(linksToCreate);
        pendingUpdates.current.createLinks = {};
        promises.push(
          createLinks({ dependencies: createdLinks })
            .unwrap()
            .then((response) => {
              response.forEach((link) => {
                ganttRef.current.changeLinkId(link.import_id, link.id);
                ganttRef.current.refreshLink(link.id);
              });
            })
            .catch(() => {
              retryUpdate(createdLinks, 'createLinks', 'id');
              isError = true;
            })
        );
      }

      if (Object.keys(tasksToUpdate).length > 0) {
        const updatedTasks = Object.values(tasksToUpdate);
        pendingUpdates.current.updateTasks = {};
        promises.push(
          updateTasks({ tasks: updatedTasks })
            .unwrap()
            .catch(() => {
              retryUpdate(updatedTasks, 'updateTasks', 'id');
              isError = true;
            })
        );
      }

      if (tasksToDelete.length > 0) {
        const deletedTasks = tasksToDelete.slice();
        pendingUpdates.current.deleteTasks = [];
        promises.push(
          deleteTasks({ tasks: deletedTasks })
            .unwrap()
            .catch(() => {
              pendingUpdates.current.deleteTasks.push(...deletedTasks);
              isError = true;
            })
        );
      }

      if (Object.keys(linksToUpdate).length > 0) {
        const updatedLinks = Object.values(linksToUpdate);
        pendingUpdates.current.updateLinks = {};
        promises.push(
          updateLinks({ dependencies: updatedLinks })
            .unwrap()
            .catch(() => {
              retryUpdate(updatedLinks, 'updateLinks', 'id');
              isError = true;
            })
        );
      }

      if (linksToDelete.length > 0) {
        const deletedLinks = linksToDelete.slice();
        pendingUpdates.current.deleteLinks = [];
        promises.push(
          deleteLinks({ dependencies: deletedLinks })
            .unwrap()
            .catch(() => {
              pendingUpdates.current.deleteLinks.push(...deletedLinks);
              isError = true;
            })
        );
      }

      // Wait for all promises to settle, either resolve or catch.
      await Promise.all(promises);
      isLoading.current = false;
      if (
        !isError &&
        (Object.keys(tasksToCreate).length ||
          Object.keys(tasksToUpdate).length ||
          tasksToDelete.length ||
          Object.keys(linksToCreate).length ||
          Object.keys(linksToUpdate).length ||
          linksToDelete.length)
      ) {
        clearTimeout(autoUpdateTimer.current);
        autoUpdateTimer.current = setTimeout(processPendingUpdates, 500);
      }
    }
  }, [createLinks, createTasks, deleteLinks, deleteTasks, updateLinks, updateTasks]);

  /**
   * Cleanup effect to process pending updates on unmount.
   */
  useEffect(() => {
    return () => {
      clearTimeout(autoUpdateTimer.current);
      processPendingUpdates();
    };
  }, [processPendingUpdates]);

  /**
   * Schedules an automatic update after a delay.
   */
  const autoUpdate = useCallback(() => {
    clearTimeout(autoUpdateTimer.current);
    autoUpdateTimer.current = setTimeout(processPendingUpdates, 500);
  }, [processPendingUpdates]);

  /**
   * Initializes the Gantt data processor.
   * @param {Object} gantt - Gantt instance.
   */
  const initDataProcessor = useCallback(
    (gantt) => {
      ganttRef.current = gantt;
      const queueUpdate = (type, item) => {
        if (type.includes('Task') && Object.hasOwn(pendingUpdates.current.createTasks, item.id)) {
          Object.assign(pendingUpdates.current.createTasks[item.id], item);
        } else if (
          type.includes('Link') &&
          Object.hasOwn(pendingUpdates.current.createLinks, item.id)
        ) {
          pendingUpdates.current.createLinks[item.id] = item;
        } else {
          if (Object.hasOwn(pendingUpdates.current[type], item.id)) {
            Object.assign(pendingUpdates.current[type][item.id], item);
          } else {
            pendingUpdates.current[type][item.id] = item;
          }
        }
        autoUpdate();
      };

      const queueDelete = (type, id) => {
        pendingUpdates.current[type].push(id);
        autoUpdate();
      };

      gantt.attachEvent('onAfterTaskMove', (id) => {
        const task = gantt.getTask(id);
        if (task.$skipDataProcessor) {
          delete task.$skipDataProcessor;
          return true;
        }
        if (task.isSocketUpdate) {
          delete task.isSocketUpdate;
          return true;
        }

        if (gantt.getNextSibling(id) !== null) {
          task.target = gantt.getNextSibling(id);
        } else if (gantt.getPrevSibling(id) !== null) {
          task.target = `next:${gantt.getPrevSibling(id)}`;
        } else {
          task.target = 'next:null';
        }
        queueUpdate('updateTasks', formatGanttTaskToTask(task));
        delete task.target;
        task.tags = getTaskTags(task.id, gantt);
        const childrenTaskIds = gantt.getChildren(task.id);
        if (childrenTaskIds?.length) {
          gantt.batchUpdate(() => {
            childrenTaskIds.forEach((id) => {
              const tags = getTaskTags(id, gantt);
              const task = gantt.getTask(id)
              task.tags = tags;
              gantt.refreshTask(task.id);
            })
          })
        }
      });

      gantt.attachEvent('onAfterTaskAdd', (id, task) => {
        if (task.$skipDataProcessor) {
          delete task.$skipDataProcessor;
          return true;
        }
        if (task.type !== 'placeholder' && !task.empty_row) {
          if (task.parent === 0 && gantt.config.project_bar) {
            task.parent = -1;
          }

          // Check if the task ID is in the deleteTasks array
          const deleteIndex = pendingUpdates.current.deleteTasks.indexOf(id);
          if (deleteIndex !== -1) {
            // Remove the task ID from the deleteTasks array
            pendingUpdates.current.deleteTasks.splice(deleteIndex, 1);
          } else {
            // Otherwise, queue the task for creation
            queueUpdate('createTasks', formatGanttTaskToTask(task));
          }
          task.tags = getTaskTags(task.id, gantt);
        }
      });

      gantt.attachEvent('onAfterTaskUpdate', (id, task) => {
        if (task.$skipDataProcessor) {
          delete task.$skipDataProcessor;
          return true;
        }
        if (task.type !== 'placeholder' && task.type !== 'project_bar' && !task.$virtual) {
          queueUpdate('updateTasks', formatGanttTaskToTask(task));
        }
      });

      gantt.attachEvent('onAfterTaskDelete', (id, task) => {
        if (task.$skipDataProcessor) {
          delete task.$skipDataProcessor;
          return true;
        }
        if (hasChild(id, gantt)) {
          gantt.eachTask((task) => {
            // Check if task is being created still
            if (Object.hasOwn(pendingUpdates.current.createTasks, task.id)) {
              delete pendingUpdates.current.createTasks[id];
            } else {
              queueDelete('deleteTasks', task.id);
            }
          }, id);
        }

        // Check if task is being created still
        if (Object.hasOwn(pendingUpdates.current.createTasks, id)) {
          delete pendingUpdates.current.createTasks[id];
        } else {
          queueDelete('deleteTasks', id);
        }
      });

      gantt.attachEvent('onAfterLinkAdd', (id, link) => {
        if (link.$skipDataProcessor) {
          delete link.$skipDataProcessor;
          return true;
        }
        if (link.isCreated) {
          return true;
        }

        // Check if the task ID is in the deleteTasks array
        const deleteIndex = pendingUpdates.current.deleteLinks.indexOf(id);
        if (deleteIndex !== -1) {
          // Remove the task ID from the deleteTasks array
          pendingUpdates.current.deleteLinks.splice(deleteIndex, 1);
        } else {
          // Otherwise, queue the task for creation
          queueUpdate('createLinks', link);
        }
      });

      gantt.attachEvent('onAfterLinkUpdate', (id, link) => {
        if (link.$skipDataProcessor) {
          delete link.$skipDataProcessor;
          return true;
        }
        if (!link.isUpdated) {
          queueUpdate('updateLinks', link);
        }
      });

      gantt.attachEvent('onAfterLinkDelete', (id, link) => {
        if (link.$skipDataProcessor) {
          delete link.$skipDataProcessor;
          return true;
        }
        if (Object.hasOwn(pendingUpdates.current.createLinks, id)) {
          delete pendingUpdates.current.createLinks[id];
        } else {
          queueDelete('deleteLinks', id);
        }
      });
    },
    [autoUpdate]
  );

  return { initDataProcessor, processChanges: processPendingUpdates };
};

export default useGanttDataProcessor;
