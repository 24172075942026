import { Percent } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { forwardRef } from 'react';

export const Progress = forwardRef(({ value, ...props }, ref) => {
  return (
    <Box
      alignItems={'center'}
      bgcolor={'primary.main'}
      borderRadius={1}
      color={'white'}
      component={'span'}
      display={'inline-flex'}
      gap={0.5}
      p={0.5}
      ref={ref}
      {...props}
    >
      <Percent fontSize={'inherit'} sx={{ border: 1, borderRadius: 1 }} />
      <Typography variant="subtitle2">{parseFloat(value * 100).toFixed(0)}%</Typography>
    </Box>
  );
});
