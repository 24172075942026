import { Alert } from '@mui/material';
import { useGetStepsQuery } from '../store/step.api';

export const StepsCounter = ({ entityId, entityType }) => {
  const {
    data: steps = [],
    isLoading: isLoadingSteps,
    isFetching: isFetchingSteps,
  } = useGetStepsQuery({ entityId, entityType });

  const totalSteps = steps.length;
  const completedSteps = steps.filter((step) => step.is_complete)?.length;

  const alertType =
    totalSteps === completedSteps
      ? 'success'
      : completedSteps === 0 && totalSteps > 0
        ? 'error'
        : completedSteps !== totalSteps
          ? 'warning'
          : 'info';

  return (
    <>
      {steps.length ? (
        <Alert severity={alertType} sx={{ px: 0.5, py: 0, ml: 'auto' }}>
          {completedSteps} / {totalSteps} completed
        </Alert>
      ) : null}
    </>
  );
};
