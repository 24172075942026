import { Box, Grid, Popover, Switch, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import ganttStore from '../../ganttStore';

export const loadCrewMenuType = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  gantt.config.editor_types.crew = {
    show: function (id, column, config, placeholder) {
      // called when input is displayed, put html markup of the editor into placeholder
      // and initialize your editor if needed:
      const task = gantt.getTask(id);
      const crewSize = task.crew_size;
      const element = (
        <CrewMenuPopover
          anchorEl={placeholder}
          handleCloseCallback={() => gantt.ext.inlineEditors.save()}
          initialCrewSize={crewSize}
          key={'crew-menu'}
        >
          <div style={{ height: '100%', width: '100%' }} />
        </CrewMenuPopover>
      );
      const externalRender = gantt.config.external_render;
      if (externalRender.isElement(element)) {
        externalRender.renderElement(element, placeholder);
      }
    },
    hide: function (node) {
      // unmountComponentAtNode(node);
      // node.unmount();
      // called when input is hidden
      // destroy any complex editors or detach event listeners from here
    },

    set_value: function (value, id, column, node) {
      let crewSize = value?.crew_size;
      const crewInput = document.getElementById('crew-size-input');
      if (crewSize && crewInput) {
        crewInput.value = crewSize;
      }
    },

    get_value: function (id, column, node) {
      return {
        crewSize: parseInt(node.firstChild.dataset.crewSize),
      };
    },

    is_changed: function (value, id, column, node) {
      const { crewSize } = this.get_value(id, column, node);
      //called before save/close. Return true if new value differs from the original one
      //returning true will trigger saving changes, returning false will skip saving
      return value.crew_size !== crewSize;
    },

    is_valid: function (value, id, column, node) {
      // validate, changes will be discarded if the method returns false
      return true;
    },

    save: function (id, column, node) {
      const { crewSize } = this.get_value(id, column, node);
      if (this.is_valid({ crewSize })) {
        const task = gantt.getTask(id);
        task.crew_size = crewSize;
        if (task.companies && task.companies.length) {
          const companyCrewSize = Math.round(task.crew_size / task.companies.length);
          const companies = task.companies.map((company) => {
            return { ...company, crew_size: companyCrewSize };
          });
          task.companies = companies;
        }

        gantt.updateTask(id);
      }
    },
    focus: function (node) {},
  };
};

const CrewMenuPopover = ({ anchorEl, initialCrewSize, handleCloseCallback, children }) => {
  const [open, setOpen] = useState(true);
  const [crewSize, setCrewSize] = useState('');

  const handleClose = useCallback(() => {
    setOpen(false);
    if (handleCloseCallback) {
      handleCloseCallback();
    }
  }, [handleCloseCallback]);

  const handleChangeCrewSize = (event) => {
    setCrewSize(event.target.value);
  };

  useEffect(() => {
    setCrewSize(initialCrewSize);
  }, [initialCrewSize]);

  return (
    <>
      {children
        ? React.cloneElement(children, {
            onClick: () => setOpen(true),
            id: 'crew-menu-placeholder',
            'data-crew-size': crewSize,
          })
        : undefined}
      <Popover
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        <Box p={2}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={3}>
              <Typography style={{ paddingLeft: '12px' }}>Crew</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                autoFocus
                fullWidth
                id={'crew-size-input'}
                placeholder="Type in number"
                size="small"
                type="number"
                value={crewSize}
                variant="outlined"
                onChange={handleChangeCrewSize}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleClose();
                  }
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
};
