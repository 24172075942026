import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useGetProjectQuery } from 'features/projects/store/project.api';
import { useGetTaskParentsQuery, useGetTaskQuery } from 'features/tasks/store/task.api';
import { NavLink, useLocation, useParams } from 'react-router-dom';

const ProductionBoardListBreadcrumb = (props) => {
  const workspaceId = Number(useParams().workspaceId) || undefined;
  const projectId = Number(useParams().projectId) || undefined;
  const { taskId } = useParams();
  const pathname = useLocation().pathname;

  const { data: project, isLoading: isProjectLoading } = useGetProjectQuery(projectId);
  const {
    data: parents = [],
    isLoading: isLoadingParents,
    isFetching: isFetchingParents,
  } = useGetTaskParentsQuery({ taskId }, { skip: !taskId });
  const {
    data: task = {},
    isLoading: isLoadingTask,
    isFetching: isFetchingTask,
  } = useGetTaskQuery({ taskId }, { skip: !taskId });

  const projectLink = `/workspace/${workspaceId}/projects/${projectId}/production-board`;

  return (
    <>
      {isProjectLoading ? (
        <Skeleton height={40} width={'50%'} />
      ) : (
        <Breadcrumbs
          maxItems={4}
          separator={<NavigateNextIcon fontSize="small" />}
          sx={{ '& .MuiBreadcrumbs-ol': { justifyContent: 'center' } }}
        >
          <Link component={NavLink} to={projectLink} underline="hover">
            <Typography>{project?.name}</Typography>
          </Link>
          {taskId ? (
            isLoadingParents || isFetchingParents ? (
              <Skeleton width={50} />
            ) : (
              parents.map(({ id, name }, index) => (
                <TaskNameBreadcrumb
                  key={id}
                  name={name}
                  to={`/workspace/${workspaceId}/projects/${task.project_id}/production-board/tasks/${id}`}
                />
              ))
            )
          ) : null}
          {taskId ? (
            isLoadingTask || isFetchingTask ? (
              <Skeleton width={50} />
            ) : (
              <TaskNameBreadcrumb
                last={pathname.endsWith(task.is_parent ? taskId : '/details')}
                name={task.name}
                to={`/workspace/${workspaceId}/projects/${task.project_id}/production-board/tasks/${task.id}/details`}
              />
            )
          ) : null}
        </Breadcrumbs>
      )}
    </>
  );
};

/**
 * TaskNameBreadcrumb
 * @param {string} id
 * @param {string} pageLink
 * @returns
 */
const TaskNameBreadcrumb = ({ to, name, last = false }) => {
  if (last) {
    return <Typography>{name}</Typography>;
  } else
    return (
      <Link component={NavLink} to={to} underline={'hover'}>
        <Typography>{name}</Typography>
      </Link>
    );
};

export default ProductionBoardListBreadcrumb;
