import { Add, PlaylistAdd } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { StepsCounter } from './StepsCounter';

export const StepsHeader = ({ onAddStep, entityId, entityType, disableAdd = false }) => {
  return (
    <Stack alignItems={'center'} direction={'row'} flexWrap={'wrap'} width={1}>
      <PlaylistAdd color={'action'} sx={{ p: 1.5, fontSize: '1.75rem' }} />
      <Typography sx={{ fontWeight: '600' }} variant="h6">
        Steps
      </Typography>

      {!disableAdd && (
        <Button startIcon={<Add />} variant="text" onClick={() => onAddStep()}>
          Add Step
        </Button>
      )}

      <Box ml={'auto'}>
        <StepsCounter entityId={entityId} entityType={entityType} />
      </Box>
    </Stack>
  );
};
