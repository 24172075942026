import { SvgIcon } from '@mui/material';

export const Company = (props) => {
  return (
    <SvgIcon {...props}>
      <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          clip-rule="evenodd"
          d="M19.7784 9.60602C21 12 21 14.4 21 14.4H3C3 14.4 3 12 4.2216 9.60602C5.4432 7.21202 7.5 6.60602 7.5 6.60602H8.7L10.2 9.60002V5.40002H13.8V9.60002L15.3 6.60602H16.5C16.5 6.60602 18.5568 7.21202 19.7784 9.60602ZM1.8 15.3H12H22.2V16.2C22.2 17.0286 17.6334 18.3 12 18.3C6.3666 18.3 1.8 17.0286 1.8 16.2V15.3Z"
          fill="currentColor"
          fill-rule="evenodd"
        />
      </svg>
    </SvgIcon>
  );
};
