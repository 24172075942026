import { ConfirmDeleteDialog } from '@blackhyve/common';
import LoadingButton from '@mui/lab/LoadingButton';
import { Popover, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Pace } from 'features/tasks/components/Pace';
import { ProductionRating } from 'features/tasks/components/ProductionRating';
import { Status } from 'features/tasks/components/Status';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MenuOptions } from './ProductionBoardMenuOptions';
import {
  useDeleteTasksMutation,
  useGetTaskQuery,
  useUpdateTasksMutation,
} from 'features/tasks/store/task.api';

import { TrackChanges } from '@mui/icons-material';
import CommentIcon from '@mui/icons-material/Comment';
import DirectionsWalk from '@mui/icons-material/DirectionsWalk';
import PendingActions from '@mui/icons-material/PendingActions';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Settings from '@mui/icons-material/Settings';
import Share from '@mui/icons-material/Share';
import ListItemIcon from '@mui/material/ListItemIcon';
import DisplayWithEditAccess from 'components/common/v3/DisplayWithEditAccess';
import { Label as LabelComponent } from 'features/labels/components/Label';
import { Progress } from 'features/tasks/components/Progress';
import { useIntersectionObserver } from 'usehooks-ts';
import CommentTab from './ProductionBoardCard/CommentTab';
import DependencyTab from './ProductionBoardCard/DependencyTab';
import JobwalkTab from './ProductionBoardCard/JobwalkTab';
import StepTab from './ProductionBoardCard/StepTab';
import StuckPointTab from './ProductionBoardCard/StuckPointTab';
import { TargetsTab } from './ProductionBoardCard/TargetsTab';
import DetailTab from './ProductionBoardCard/TaskDetailTab';
import TagsTab from './ProductionBoardCard/TagsTab';


/**
 * Production Board Task Details
 * @returns
 */
const ProductionBoardTaskDetails = () => {
  const theme = useTheme();
  const sectionRefs = useRef([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [editTask, setEditTask] = useState(undefined);
  const [openAssignTagDialog, setOpenAssignTagDialog] = useState(false);
  const [activeSections, setActiveSections] = useState({ 'details-tab': true });
  const workspaceId = Number(useParams().workspaceId) || undefined;
  const taskId = Number(useParams().taskId) || undefined;
  const projectId = Number(useParams().projectId) || undefined;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [updateTasks, { isLoading: isUpdatingTask }] = useUpdateTasksMutation();
  const [deleteTasks, { isLoading: isDeletingTask }] = useDeleteTasksMutation();
  const {
    data: task,
    isLoading: isLoadingTask,
    isFetching: isFetchingTask,
    isError: isErrorGettingTask,
  } = useGetTaskQuery({ taskId, include: 'tags' });

  const sections = useMemo(
    () => [
      {
        name: 'Details',
        id: 'details-tab',
        icon: <Settings />,
        component: <DetailTab task={task} />,
      },
      {
        name: 'Steps',
        id: 'step-tab',
        icon: <PlaylistAddIcon />,
        component: <StepTab projectId={projectId} task={task} taskId={task?.id} />,
      },
      {
        name: <LabelComponent labelKey="stuck_point" plural={true} />,
        id: 'stuckPoint-tab',
        icon: <PendingActions />,
        component: <StuckPointTab projectId={projectId} taskId={taskId} />,
      },
      {
        name: 'Comments',
        id: 'comment-tab',
        icon: <CommentIcon />,
        component: <CommentTab taskId={taskId} />,
      },
      {
        name: 'Dependencies',
        id: 'dependency-tab',
        icon: <Share />,
        component: <DependencyTab taskId={taskId} />,
      },
      {
        name: 'Jobwalk',
        id: 'jobwalk-tab',
        icon: <DirectionsWalk />,
        component: <JobwalkTab taskId={taskId} />,
      },
      {
        name: 'Target',
        id: 'target-tab',
        icon: <TrackChanges />,
        component: <TargetsTab projectId={projectId} taskId={taskId} />,
      },
    ],
    [projectId, task, taskId]
  );

  const handleScrollToTab = (tabId) => {
    const element = document.getElementById(tabId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleOnEdit = () => setEditTask(task?.name);

  const handleOpenConfirmDelete = () => setOpenConfirmDelete(true);

  const handleOnCancel = () => setEditTask(undefined);

  const handleOnKeyDown = (event) => (event.keyCode === 13 ? handleOnSave() : null);

  const handleOnSave = () => {
    if (task.name === editTask) {
      handleOnCancel();
      return;
    }
    updateTasks({
      tasks: [
        {
          id: taskId,
          name: editTask,
          project_id: projectId,
        },
      ],
    }).then(() => {
      handleOnCancel();
    });
  };

  const handleOnDelete = () => {
    deleteTasks({ tasks: [taskId] }).then(() => {
      navigate(`/workspace/${workspaceId}/projects/${projectId}`);
      enqueueSnackbar('Task deleted successfully', { variant: 'success' });
    });
  };


  useEffect(() => {
    if (isErrorGettingTask) {
      enqueueSnackbar('Task not found', { variant: 'error' });
      navigate(`/workspace/${workspaceId}/projects/${projectId}`);
    }
  }, [isErrorGettingTask, projectId, workspaceId, enqueueSnackbar, navigate]);

  const activeSectionId = sections.find(({ id }) => activeSections[id])?.id;

  return (
    <Container maxWidth="xl" sx={{ mt: 2 }}>
      {isLoadingTask ? (
        <CircularProgress className={'loading'} />
      ) : (
        <Grid container paddingBottom={4}>
          <Grid container item mt={1} spacing={1} xs={12}>
            {!isSmallScreen && (
              <Grid item md={2} xs={12}>
                <Box position={'sticky'} top={0}>
                  <List>
                    {sections.map((section, index) => {
                      const isSelected = activeSectionId === section.id;
                      return (
                        <ListItem
                          disablePadding
                          key={section.id}
                          ref={(el) => (sectionRefs.current[index] = el)}
                          sx={{ borderRightColor: isSelected ? 'primary' : 'none' }}
                        >
                          <ListItemButton
                            selected={isSelected}
                            onClick={() => handleScrollToTab(section.id)}
                          >
                            <ListItemIcon>{section.icon}</ListItemIcon>
                            <ListItemText
                              primary={section.name}
                              sx={{
                                '& .MuiTypography-root': {
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                },
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              </Grid>
            )}
            <Grid container item gap={2} md={10} xs={12}>
              {isFetchingTask && <LinearProgress sx={{ mx: 1, width: '100%' }} />}
              <Grid container item alignItems="center" px={isSmallScreen ? 0 : 1} xs={12}>
                <Grid container item xs gap={1}>
                  {editTask ? (
                    <Grid container item alignItems={'center'} gap={1}>
                      <Grid item xs>
                        <TextField
                          autoFocus
                          fullWidth
                          size="small"
                          value={editTask}
                          variant="outlined"
                          onChange={(event) => setEditTask(event.target.value)}
                          onKeyDown={handleOnKeyDown}
                        />
                      </Grid>
                      <Grid container item gap={1} xs="auto">
                        <LoadingButton
                          loading={isUpdatingTask}
                          size="small"
                          variant="contained"
                          onClick={handleOnSave}
                        >
                          Save
                        </LoadingButton>
                        <Button size="small" variant="outlined" onClick={handleOnCancel}>
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <Grid container item gap={1} xs={12}>
                        <Typography sx={{ fontWeight: 'bold' }} variant={isSmallScreen ? 'h5' : 'h4'}>
                          {task?.name}
                        </Typography>
                        <Box alignItems={'center'} display="flex" gap={1}>
                          <Status value={task?.status} />
                          <Progress value={task?.percent_complete} />
                          <ProductionRating value={task?.production_rate} />
                          <Pace value={task?.pace} />
                        </Box>
                      </Grid>
                      <Grid container item gap={0.5} xs={12}>
                        <TagsTab key="tags-tab" projectId={projectId} tags={task.tags} taskId={task.id} />
                      </Grid>
                    </>
                  )}
                </Grid>
                <DisplayWithEditAccess>
                  {!editTask && (
                    <Grid container item justifyContent={'flex-start'} sx={{ mb: 'auto' }} xs="auto">
                      <MenuOptions
                        entityObj={task}
                        onDelete={handleOpenConfirmDelete}
                        onEdit={handleOnEdit}
                      />
                    </Grid>
                  )}
                </DisplayWithEditAccess>
              </Grid>
              {sections.map((section, index) => {
                return (
                  <Grid item id={section.id} key={section.id} xs={12}>
                    <ActiveSectionObserver id={section.id} setActiveSections={setActiveSections}>
                      {section.component}
                    </ActiveSectionObserver>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      )}

      {openConfirmDelete && (
        <ConfirmDeleteDialog
          handleClose={() => setOpenConfirmDelete(false)}
          handleDelete={handleOnDelete}
          isDeleting={isDeletingTask}
          item={'task'}
          open={openConfirmDelete}
        />
      )}


    </Container>
  );
};

const ActiveSectionObserver = ({ id, setActiveSections, children }) => {
  const { ref } = useIntersectionObserver({
    threshold: 0.75,
    onChange: (isIntersecting) => {
      setActiveSections((prev) => ({ ...prev, [id]: isIntersecting }));
    },
  });

  return (
    <div ref={ref}>
      {/* <div ref={ref} /> */}
      {children}
    </div>
  );
};
export default ProductionBoardTaskDetails;
