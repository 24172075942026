import Tag from "./Tag";

/**
 * Tag List 
 * @param {[Object]} tags 
 * @returns 
 */
const TagList = ({ tags, onDelete, ...tagStyleProps }) => {


  const [generatedTags, normalTags] = (tags || [])?.reduce(
    ([generated, normal], tag) => {
      (tag.type === "generated" ? generated : normal).push(tag);
      return [generated, normal];
    },
    [[], []]
  );

  const concatenatedTag = generatedTags.length ?
    generatedTags.map((tag) => tag.name)?.join(' - ') : ''

  return <>
    {concatenatedTag && <Tag name={concatenatedTag} type="generated" variant="outlined" {...tagStyleProps} />}
    {normalTags?.map((tag) => {
      return <Tag
        color={tag.color}
        key={tag.id}
        name={tag.name}
        type={tag.type}
        onDelete={tag.tagged_by !== 0 && onDelete ? () => onDelete(tag.id) : undefined}
        {...tagStyleProps}
      />
    })}
  </>

}

export default TagList;