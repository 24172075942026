const { unionBy } = require('lodash')

/**
 * Get task tags
 * @param {Number} taskId
 * @param {Gantt} gantt
 * @returns {[Tags]}
 */
function getTaskTags(taskId, gantt) {

  const task = gantt.getTask(taskId);
  const parentTags = getParentTags(taskId, gantt)
  const existingTags = task?.tags?.length ? task?.tags?.filter((tag) => tag.tagged_by !== 0 && tag.type !== "generated") : [];
  return unionBy(parentTags, existingTags, 'id');
}


const getParentTags = (id, gantt) => {

  const smartTags = [];
  const cascadingTags = {};

  gantt.eachParent((parent) => {
    parent?.tags?.forEach(({ type, id, ...rest }) => {
      if (type === "smart") {
        smartTags.push({ id, type, ...rest, taskId: parent.id });
      } else if (type === "cascading") {
        cascadingTags[id] = { id, type, ...rest };
      }
    });
  }, id);

  const typePriority = ['location', 'phase', 'zone', 'area'];
  const customSort = (a, b) => {
    const priorityA = typePriority.indexOf(a.smart_key);
    const priorityB = typePriority.indexOf(b.smart_key);
    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    return 0;
  };

  // Finding generated tags of those smart tags
  const newGeneratedTags = smartTags?.reverse()?.map(({ id, taskId }) =>
    gantt?.constants?.tags?.find(
      (tag) =>
        tag.type === "generated" &&
        tag.smart_tag_id === id &&
        tag.smart_task_id === taskId
    )
  )
    .filter(Boolean);

  newGeneratedTags?.sort(customSort);
  return [...newGeneratedTags, ...Object.values(cascadingTags)]


}

module.exports = { getTaskTags };
